import React, { useState } from "react"
import "./CommunityPost.less"
import { Typography } from "antd"
import { linkify } from "../../../utils/data"

const { Text, Title } = Typography

export const ContentTitleModel = titleModel => (
  <Title className="contentTitle">{titleModel.title}</Title>
)

export const ContentTextModel = textModel => {
  const READ_MORE_LENGTH = 310
  const [isReadMoreClicked, setIsReadMoreClicked] = useState(
    textModel?.isReadMoreClicked
  )
  const handleReadMoreClick = () => setIsReadMoreClicked(!isReadMoreClicked)
  return (
    <>
      <Text
        className={`contentText ${
          textModel?.text?.length > READ_MORE_LENGTH &&
          // !textModel.isReadMoreClicked &&
          !isReadMoreClicked &&
          "hideText"
        }`}
      >
        {linkify(textModel.text)}
      </Text>
      {textModel?.text?.length > READ_MORE_LENGTH &&
        // !textModel.isReadMoreClicked &&
        !isReadMoreClicked && (
          <Text
            onClick={(e: any) => {
              if (
                e.target ===
                document.getElementById(
                  `learnMoreCommunityPostText${textModel?.communityPostId}`
                )
              ) {
                e.stopPropagation()
                handleReadMoreClick()
              }
            }}
            className="learnMore"
            id={`learnMoreCommunityPostText${textModel?.communityPostId}`}
          >
            ... Learn more
          </Text>
        )}
    </>
  )
  // return <Text className={`contentText`}>{linkify(textModel.text)}</Text>
}

export const ContentImageModel = imageModel => {
  if (imageModel.caption) {
    return (
      <div className="contentImageModel">
        <img src={imageModel.image} className="contentImage" alt="imageModel" />
        <Text className="caption">{imageModel.caption}</Text>
      </div>
    )
  } else {
    return (
      <div className="contentImageModel">
        <img src={imageModel.image} className="contentImage" alt="imageModel" />
      </div>
    )
  }
}

export const ContentVideoModel = videoModel =>
  videoModel?.isYouTube ? (
    <div className="videoMedia">
      <iframe src={videoModel?.video} />
    </div>
  ) : (
    <></>
  )
