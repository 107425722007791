import React, { useState } from "react"
import { Button, message, Modal, Typography, Input } from "antd"
import "./AuthPopup.less"
import { navigate } from "gatsby"
import { app } from "../../../../../firebaseConfig"
import Signin from "../../Auth/Signin"
import SignupPopup from "./SignupPopup"

const { Title } = Typography

const AuthPopup = ({
  isAuthPopupVisible,
  setIsAuthPopupVisible,
  callOnLogin = () => {},
}) => {
  const [isSignin, setIsSignin] = useState<boolean>(false)
  const [forgotPass, setForgotPass] = useState<boolean>(false)
  const [forgotPassLoading, setForgotPassLoading] = useState<boolean>(false)
  const [forgotEmail, setForgotEmail] = useState<string>("")

  const forgotPassword = async () => {
    const emailRE =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!emailRE.test(forgotEmail)) {
      return message.error("Enter a valid Email")
    }
    setForgotPassLoading(true)
    try {
      await app.auth().sendPasswordResetEmail(forgotEmail)
      message.success("Reset Password Link sent to your mail.")
      setForgotEmail("")
      setForgotPassLoading(false)
    } catch (error) {
      message.error("Something went wrong!!! Please try again")
      setForgotPassLoading(false)
    }
  }

  const forgotPassDiv = () => {
    return (
      <div className="forgotPassDiv">
        <Title className="forgotTitle">Reset Your Password</Title>
        <Input
          value={forgotEmail}
          onChange={e => setForgotEmail(e.target.value)}
          className="forgotInp"
          placeholder="Enter your email"
        />
        <span className="backToSignin" onClick={() => setForgotPass(false)}>
          back to signin?
        </span>
        <Button
          className="forgotBtn"
          type="text"
          onClick={forgotPassword}
          loading={forgotPassLoading}
        >
          Send Email
        </Button>
      </div>
    )
  }

  return (
    <Modal
      className="authPopupModal"
      visible={isAuthPopupVisible}
      onOk={() => {}}
      onCancel={() => setIsAuthPopupVisible(false)}
      footer={null}
    >
      <div className="authPopupDiv">
        <div className="switchDiv">
          <ul>
            <li
              className={!isSignin ? "activeList" : "unActiveList"}
              onClick={() => setIsSignin(false)}
            >
              Signup
            </li>
            <li
              className={isSignin ? "activeList" : "unActiveList"}
              onClick={() => setIsSignin(true)}
            >
              {!forgotPass ? "Signin" : "Reset"}
            </li>
          </ul>
        </div>
        {isSignin ? (
          !forgotPass ? (
            <Signin
              setForgotPass={() => setForgotPass(true)}
              popUp={true}
              onAuthComplete={() => {
                callOnLogin()
                setIsAuthPopupVisible(false)
              }}
            />
          ) : (
            forgotPassDiv()
          )
        ) : (
          <SignupPopup
            popUp={true}
            onAuthComplete={() => {
              callOnLogin()
              setIsAuthPopupVisible(false)
            }}
          />
        )}
      </div>
    </Modal>
  )
}

export default AuthPopup
