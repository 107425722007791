import React from "react"
import "./ShareButtons.less"
import {
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import WhatsAppShare from "../../assets/mentroWebApp/whatsappShare.svg"
import LinkedinShare from "../../assets/mentroWebApp/linkedinShare.svg"
import TwitterShare from "../../assets/mentroWebApp/twitterShare.svg"
import CopyShare from "../../assets/mentroWebApp/copyShare.svg"
import { message } from "antd"

const ShareButtons = ({ url }) => {
  return (
    <div className="shareBtns">
      <img
        src={CopyShare}
        alt="copy share"
        onClick={() => {
          navigator.clipboard.writeText(url)
          message.success("Ready to Share! Copied to the clipboard !!!")
        }}
        style={{ cursor: "pointer" }}
      />
      <WhatsappShareButton url={url}>
        <img src={WhatsAppShare} alt="WhatsApp share" />
      </WhatsappShareButton>
      <LinkedinShareButton url={url}>
        <img src={LinkedinShare} alt="Linkedin share" />
      </LinkedinShareButton>
      <TwitterShareButton url={url}>
        <img src={TwitterShare} alt="Twitter share" />
      </TwitterShareButton>
    </div>
  )
}

export default ShareButtons
