import { create } from "zustand"
// import Cookies from "js-cookie"
// import { fbAuth } from "../../../../../firebaseConfig"
import { persist } from "zustand/middleware"


interface communityDataState {
  currentPost: any
  setCurrentPost: (post: any) => void
  currentPostId: any
  setCurrentPostId: (post: any) => void
  currentCommunity: any
  setCurrentCommunity: (community: any) => void
  communities: any
  setCommunities: (communities: any) => void
  latestPosts: any
  setLatestPosts: (latestPosts: any) => void
  lastCommentId: any
  setLastCommentId: (lastCommentId: any) => void
  topCommentId: any
  setTopCommentId: (lastCommentId: any) => void
  hasMoreComments: any
  setHasMoreComments: (hasMoreComments: any) => void
  comments: any
  setComments: (comments: any) => void
  filters: any
  setFilters: (filters: any) => void
  getCommunityPostComments: (postId, lastCommentId, topCommentId) => void
  reactToPost: (postId, status) => void
  reactToComment: (postId, commentId, status) => void
  reactToReply: (postId, commentId, replyId, status) => void
  joinCommunity: (communityId, status, redirect) => void
  getFollowedCommunities: () => void
}

export const useCommunityData = create<communityDataState>()(
    (set, get) => ({
      currentPost: null,
      setCurrentPost: (post: any) => {
        set(state => ({ ...state, currentPost: post }))
      },
      currentPostId: null,
      setCurrentPostId: (currentPostId: any) => {
        set(state => ({ ...state, currentPostId: currentPostId }))
      },
      currentCommunity: null,
      setCurrentCommunity: (community: any) => {
        set(state => ({ ...state, community: community }))
      },
      communities: [],
      setCommunities: (communities: any) => {
        set(state => ({ ...state, communities: communities }))
      },
      latestPosts: [],
      setLatestPosts: (latestPosts: any) => {
        set(state => ({ ...state, latestPosts: latestPosts }))
      },
      lastCommentId: "",
      setLastCommentId: (lastCommentId: any) => {
        set(state => ({ lastCommentId: lastCommentId }))
      },
      topCommentId: "",
      setTopCommentId: (topCommentId: any) => {},
      hasMoreComments: "",
      setHasMoreComments: (hasMoreComments: any) => {
        set(state => ({ ...state, hasMoreComments: hasMoreComments }))
      },
      comments: [],
      setComments: (comments: []) => {
        set(state => ({ ...state, comments: comments }))
      },
      filters: {},
      setFilters: (filters: any) => {
        set(state => ({ ...state, filters: filters }))

      },
      getCommunityPostComments: (
        postId: string,
        lastCommentId: string = "",
        topCommentId: string = ""
      ) => {
        // console.log(postId, lastCommentId, topCommentId, "{}{}")
        set(state => ({
          ...state,
          postId: postId,
          topCommentId: topCommentId,
          lastCommentId: lastCommentId,
        }))
      },
      reactToPost: (postId: string, status: string) => {
        set(state => ({
          ...state,
          postId: postId,
          status: status,
        }))
      },
      reactToComment: (postId: string, commentId: string, status: string) => {
        set(state => ({
          ...state,
          postId: postId,
          commentId: commentId,
          status: status,
        }))
      },
      reactToReply: (
        postId: string,
        commentId: string,
        replyId: string,
        status: string
      ) => {
        set(state => ({
          ...state,
          postId: postId,
          commentId: commentId,
          replyId: replyId,
          status: status,
        }))
      },
      joinCommunity: (
        communityId: string,
        status: string,
        redirect: boolean = false
      ) => {
        set(state => ({
          ...state,
          communityId: communityId,
          redirect: redirect,
          status: status,
        }))
      },
      getFollowedCommunities: () => {},
    })
)
export default useCommunityData
