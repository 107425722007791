import React, { useCallback, useEffect, useRef, useState } from "react"
import "./Mentors.less"
import { AutoComplete, Col, Input, Row, Select, Typography } from "antd"
import MentorCard from "./MentorCard"
import { postHttpHandler } from "../../../utils/httpHandler"
//import TakeMentorshipPopup from "../../Popups/TakeMentorshipPopup"
import MentorCardSkeleton from "./MentorCardSkeleton"
import MentorCardMobile from "./MentorCardMobile"
import MentorCardSkeletonMobile from "./MentorCardSkeletonMobile"
import NotFound from "../../../../assets/pageNotFound/notFound.svg"
//import Cookies from "js-cookie"
import {
  logGa,
  mixpanelEvent,
  mixpanelTimeEvent,
} from "../../../../services/analytics"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"

const { Title } = Typography
const { Option } = Select

const limitMentors = 20

const filterTypes = [
  {
    name: "name",
    label: "Name",
  },
  {
    name: "company",
    label: "Companies",
  },
  {
    name: "expertise",
    label: "Expertise",
  },
]

const filterEnum = {
  name: "name",
  company: "companies",
  expertise: "expertise",
}

const Mentors = () => {
  const { token, isLoggedIn, mentor } = useLoggedInUserStore()
  const [recomMentorList, setRecomMentorList] = useState<any>([])
  const [loadingRecom, setLoadingRecom] = useState<boolean>(false)
  // const [isTakeMentorshipVisible, setIsTakeMentorshipVisible] =
  //   useState<boolean>(false)
  // const takeMentorshipStatus: any = JSON.parse(
  //   Cookies.get("takeMentorship") || `{ "shouldOpen": true, "opened": false }`
  // )
  // const [takeMentorship, setTakeMentorship] = useState<{
  //   shouldOpen: boolean
  //   opened: boolean
  // }>(takeMentorshipStatus)

  const [mentorList, setMentorList] = useState<any>([])
  const [docId, setDocId] = useState("")
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [hasMoreMentors, setHasMoreMentors] = useState<boolean>(false)
  const [dataReceived, setDataReceived] = useState<boolean>(false)
  const [allFilters, setAllFilters] = useState<any>(null)
  const [filterName, setFilterName] = useState<string>(filterTypes[0].name)
  const [filterValue, setFilterValue] = useState<string>("")
  const [filterOptions, setFilterOptions] = useState<{ value: string }[]>([])

  const onSearch = (searchText: string) => {
    const fName = filterEnum[filterName]
    if (!allFilters?.[fName]) {
      searchText !== "" &&
        fName !== "name" &&
        setFilterOptions([{ value: searchText }])
      return
    }

    const data = allFilters?.[fName]?.map(el => {
      return {
        value: el,
      }
    })

    const newOps: any[] = data?.filter(
      optionVal =>
        optionVal.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    )

    if (newOps.length === 0) {
      setFilterOptions([{ value: searchText }])
      return
    }
    if (searchText === "") {
      setFilterOptions([...data])
      return
    }
    if (
      newOps.find(el => el.value.toLowerCase() !== searchText.toLowerCase())
    ) {
      newOps.push({ value: searchText })
    }
    setFilterOptions(newOps)
  }

  const onSelect = (data: string) => {
    setMentorList([])
    setHasMoreMentors(false)
    setFilterValue(data)
    getMentors(data)
  }

  const onClear = () => {
    setFilterValue("")
    getMentors("")
  }

  const handleFilterChange = (value: string) => {
    // const elem: any = document.getElementById("search-data")

    // if (elem) {
    //   elem.value = ""
    // }

    setFilterName(value)
    const fName = filterEnum[value]
    if (allFilters?.[fName]) {
      setFilterOptions(
        allFilters?.[fName].map(el => {
          return {
            value: el,
          }
        })
      )
    } else {
      setFilterOptions([])
    }
  }

  const getNextMentors = async (value = filterValue) => {
    if (!hasMoreMentors || !docId) {
      return
    }
    setLoading(true)
    setError(false)

    try {
      const nextMentorListData = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/v3/users/getMentorList`,
        body: {
          limit: limitMentors,
          docId: docId,
          filter: {
            name: filterName,
            value: value,
          },
        },
        idToken: token,
      })
      setMentorList(prevData => {
        return [...prevData, ...nextMentorListData.mentorList]
      })
      setHasMoreMentors(nextMentorListData.mentorList.length > 0)
      setError(false)
      setLoading(false)
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  const getMentors = async (value = filterValue) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    setLoading(true)
    setError(false)
    setDataReceived(false)
    try {
      const mentorListData = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/v3/users/getMentorList`,
        body: {
          limit: limitMentors,
          docId: "",
          filter: {
            name: filterName,
            value: value,
          },
        },
        idToken: token,
      })
      setDataReceived(true)
      setMentorList(mentorListData.mentorList)
      setHasMoreMentors(mentorListData.mentorList.length > 0)
      const fName = filterEnum[filterName]
      mentorListData[fName] &&
        setFilterOptions(
          mentorListData[fName].map(el => {
            return {
              value: el,
            }
          })
        )
      const filterObj = {}
      Object.keys(filterEnum).map(
        key => (filterObj[filterEnum[key]] = mentorListData[filterEnum[key]])
      )
      setAllFilters(filterObj)

      setError(false)
      setLoading(false)
    } catch (error) {
      setError(true)
      setLoading(false)
      setDataReceived(false)
    }
  }

  useEffect(() => {
    getMentors()
  }, [])

  useEffect(() => {
    getNextMentors()
  }, [docId])

  const getRecomMentorList = async () => {
    let date = new Date()
    setLoadingRecom(true)
    try {
      const recomMentorListData = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/getRecommendedMentors`,
        body: {
          date: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          limit: 4,
        },
        idToken: null,
      })

      setLoadingRecom(false)
      if (recomMentorListData.mentors.length > 0) {
        setRecomMentorList(recomMentorListData.mentors)
      }
    } catch (error) {
      setLoadingRecom(false)
    }
  }

  // const checkForPopup = takeMentorship => {
  //   if (!takeMentorship.shouldOpen || !takeMentorship.opened) {
  //     Cookies.set(
  //       "takeMentorship",
  //       JSON.stringify({
  //         shouldOpen: takeMentorship.shouldOpen,
  //         opened: false,
  //       }),
  //       {
  //         expires: !takeMentorship.shouldOpen ? 7 : 0.25,
  //       }
  //     )
  //     setTakeMentorship({
  //       shouldOpen: takeMentorship.shouldOpen,
  //       opened: false,
  //     })
  //   }
  // }

  useEffect(() => {
    // checkForPopup(takeMentorship)
    getRecomMentorList()
    logGa("PAGE_LANDING", { screen_name: "mentor_list" })

    mixpanelTimeEvent("page_view_mentor_discovery_screen")

    return () => {
      mixpanelEvent("page_view_mentor_discovery_screen", {
        screen_name: "mentor_discovery_screen",
        user_type: isLoggedIn ? (mentor ? "mentor" : "student") : "guest",
      })
    }
  }, [])

  // useEffect(() => {
  //   if (takeMentorship.shouldOpen && !takeMentorship.opened) {
  //     setIsTakeMentorshipVisible(true)
  //     Cookies.set(
  //       "takeMentorship",
  //       JSON.stringify({
  //         shouldOpen: true,
  //         opened: true,
  //       }),
  //       { expires: 0.25 }
  //     )
  //     setTakeMentorship({
  //       shouldOpen: true,
  //       opened: true,
  //     })
  //   }
  // }, [takeMentorship])

  // -----------------------------------------------------------------
  // INFINITE SCROLLING-----------------------------------------------------------------
  // -----------------------------------------------------------------
  const observer = useRef<any>()
  const lastMentorCard = useCallback(
    ele => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasMoreMentors) {
            setDocId(mentorList[mentorList.length - 1].mentorData.uid)
          }
        },
        {
          root: null,
          rootMargin: "40px",
          threshold: 0,
        }
      )
      if (ele) observer.current.observe(ele)
    },
    [loading, hasMoreMentors]
  )
  //-----------------------------------

  return (
    <div className="mentorsTabDiv">
      {/* <TakeMentorshipPopup
        checkForPopup={checkForPopup}
        isTakeMentorshipVisible={isTakeMentorshipVisible}
        setisTakeMentorshipVisible={setIsTakeMentorshipVisible}
      /> */}
      <div className="headersDiv">
        <Title className="headerTitle">Let's find you a Mentor 😉</Title>
      </div>
      <div className="filtersDiv">
        <AutoComplete
          defaultActiveFirstOption
          options={filterOptions}
          onSelect={onSelect}
          onSearch={onSearch}
          onClear={onClear}
          allowClear
          showSearch
          id="search-data"
        >
          <Input.Search
            onSearch={onSelect}
            type="text"
            id="search-data-input"
            placeholder={`Start typing the ${filterName}...`}
          />
        </AutoComplete>
        <Select
          onChange={handleFilterChange}
          value={filterName}
          className="filterType"
        >
          {filterTypes.map(el => (
            <Option value={el.name} key={el.name}>
              {el.label}
            </Option>
          ))}
        </Select>
      </div>
      <Row className="mentorsRow">
        {!filterValue && (
          <div className="mentorDataDiv recommendedListDiv">
            <Title className="mentorDataTitle">Recommended</Title>
            <div className="recList">
              {/* <Button
              type="text"
              onClick={() => slide("left", ".scrollRow")}
              className="scrollBtn leftBtn"
            >
              <img src={LeftScroll} alt="left scroll" />
            </Button> */}
              <Row
                className="mentorDataRow recomList scrollRow"
                gutter={[25, 25]}
              >
                {recomMentorList?.length > 0 &&
                  recomMentorList.slice(0, 4).map((mentorData, ind) => (
                    <Col className="mentorCardCol" key={ind} lg={6} md={12}>
                      <MentorCard
                        mentorData={mentorData.mentorData}
                        key={ind}
                      />
                    </Col>
                  ))}
                {loadingRecom &&
                  Array.from(Array(5)).map((item, ind) => (
                    <Col className="mentorCardCol" lg={6} md={12} key={ind}>
                      <MentorCardSkeleton />
                    </Col>
                  ))}
              </Row>
              <Row className="mobileMentorDataRow scrollRow" gutter={[25, 25]}>
                {recomMentorList.length > 0 &&
                  recomMentorList.map((mentorData, ind) => (
                    <Col xs={22} className="mobCol" key={ind}>
                      <MentorCardMobile mentorData={mentorData.mentorData} />
                    </Col>
                  ))}
                {loadingRecom && <MentorCardSkeletonMobile />}
              </Row>
              {/* <Button
              type="text"
              onClick={() => slide("right", ".scrollRow")}
              className="scrollBtn rightBtn"
            >
              <img src={RightScroll} alt="right scroll" />
            </Button> */}
            </div>
          </div>
        )}
        <div className="mentorDataDiv">
          <Title className="mentorDataTitle">All Mentors</Title>
          <Row className="mentorDataRow" gutter={[25, 25]}>
            {mentorList?.length > 0 &&
              mentorList.map((mentorData, ind) => (
                <Col className="mentorCardCol" key={ind} lg={6} md={12}>
                  <MentorCard mentorData={mentorData.mentorData} />
                </Col>
              ))}
            {loading &&
              !hasMoreMentors &&
              Array.from(Array(4)).map((item, ind) => (
                <Col className="mentorCardCol" lg={6} md={12} key={ind}>
                  <MentorCardSkeleton />
                </Col>
              ))}
            {loading && hasMoreMentors && (
              <Col className="mentorCardCol" lg={6} md={12}>
                <MentorCardSkeleton />
              </Col>
            )}
            {dataReceived && mentorList.length === 0 && (
              <div className="noMentorsDiv">
                <img src={NotFound} alt="not found illustration" />
                <h1>No Mentors Found</h1>
              </div>
            )}
          </Row>
          <Row className="mobileMentorDataRow" gutter={[25, 25]}>
            {mentorList?.length > 0 &&
              mentorList.map((mentorData, ind) => (
                <MentorCardMobile
                  mentorData={mentorData.mentorData}
                  key={ind}
                />
              ))}
            {loading && !hasMoreMentors && <MentorCardSkeletonMobile />}
            {loading && hasMoreMentors && <MentorCardSkeletonMobile />}
            {dataReceived && mentorList.length === 0 && (
              <div className="noMentorsDiv">
                <img src={NotFound} alt="not found illustration" />
                <h1>No Mentors Found</h1>
              </div>
            )}
          </Row>
          <div ref={lastMentorCard} />
        </div>
      </Row>
    </div>
  )
}

export default Mentors
