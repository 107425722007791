import { useEffect, useState } from "react"
import { fbAuth } from "../../../firebaseConfig"

export default function useLoggedStatus() {
  const [authState, setAuthState] = useState<"unknown" | "known">("unknown")
  useEffect(() => {
    fbAuth.onAuthStateChanged(() => setAuthState("known"))
  }, [])
  return authState
}
