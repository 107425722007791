import React, { useContext, useEffect, useState } from "react"
import "./CommunityPostComment.less"
import { Button, message, Typography } from "antd"
import moment from "moment"
import { navigate } from "gatsby"
import {
  CommentImageModel,
  CommentTextModel,
} from "./CommunityPostCommentModels"
import Guest from "../../../assets/mentroWebApp/defaultUser.svg"
import LikeImg from "../../../assets/mentroWebApp/likePost.svg"
import LikedImg from "../../../assets/mentroWebApp/likedPost.svg"
import ReplyImg from "../../../assets/mentroWebApp/commentPost.svg"
import CommunityPostReply from "./CommunityPostReply"
// import { CommunityContext } from "../../../context/CommunityContext"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import CommentBox from "../CommentBox"
import { getHttpHandler, postHttpHandler } from "../../../utils/httpHandler"
import CommunityPostCommentSkeleton from "../CommunityPostCommentSkeleton"
import useCommunityData from "../../ZustandStateManagment/Communities/store"

const { Title, Text } = Typography

const CommunityPostComment = ({
  comment,
  shadowComment = false,
  borderRadius = true,
  noMarginComment = true,
  setIsAuthPopupVisible = pVal => {},
}) => {
  // const {postId} = comment;
  const { reactToComment, setCurrentPostId } = useCommunityData()
  const { isLoggedIn, token } = useLoggedInUserStore()
  const [reactionStatus, setReactionStatus] = useState<boolean>(false)
  const [reactionCount, setReactionCount] = useState<number>(0)
  const [isReply, setIsReply] = useState<boolean>(false)
  const [replyLoading, setReplyLoading] = useState<boolean>(false)
  const [replies, setReplies] = useState<any[]>([])

  useEffect(() => {
    setReactionStatus(comment?.hasLiked)
    setReactionCount(comment?.like)
    setReplies(comment?.replies || [])
  }, [comment])

  const getCommunityPostReplies = async (
    postId,
    commentId = "",
    lastReplyId = ""
  ) => {
    if (replyLoading) return
    setReplyLoading(true)
    try {
      const repliessResp = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/reply/get?postId=${postId}&commentId=${commentId}&lastReplyId=${lastReplyId}`,
        idToken: token,
      })
      setReplyLoading(false)
      setReplies(prevData => [...prevData, ...(repliessResp.replies || [])])
      setCurrentPostId(postId)
    } catch (error) {
      setReplyLoading(false)
      message.error("Something went wrong!")
    }
  }

  return (
    <div
      className={`communityPostCommentDiv ${
        noMarginComment && "noMarginComment"
      } ${shadowComment && "shadowComment"}  ${
        borderRadius && "borderRadiusComment"
      }`}
      key={comment?.commentId}
    >
      <div
        className="topDiv"
        onClick={() => {
          const profileId = comment?.commenter?.profileId
          if (profileId) {
            navigate(`/web/@${profileId}`)
          }
        }}
      >
        <img src={comment?.commenter?.image || Guest} alt="userImg" />
        <div className="content">
          <Title className="name">{comment?.commenter?.name}</Title>
          <Text className="timeSpan">
            {moment(comment?.lastUpdated).fromNow()}
          </Text>
        </div>
      </div>
      <div className="bodyDiv">
        <div className="bodyTop">
          {comment?.text && <CommentTextModel text={comment?.text} />}
          {comment?.image && <CommentImageModel image={comment?.image} />}
        </div>
        <div className="bottomDiv">
          <div
            className="actionDiv"
            onClick={async (e) => {
              e.preventDefault()
              if (!isLoggedIn) return setIsAuthPopupVisible(true)
              if (reactionStatus) {
                setReactionCount(reactionCount - 1)
              } else {
                setReactionCount(reactionCount + 1)
              }
              setReactionStatus(!reactionStatus)
              reactToComment(
                comment?.postId,
                comment?.commentId,
                reactionStatus ? "Unlike" : "Like"
              )
              try {
              const likeCommentResp = await postHttpHandler({
                url: `${process.env.GATSBY_BE_URL}/community/comment/like`,
                idToken: token,
                body: {
                  postId: comment?.postId,
                  commentId: comment?.commentId,
                  status: reactionStatus ? "Unlike" : "Like",
                },
              })
            } catch (error) {
              message.error("Something went wrong!")
            }
            }}
          >
            <Text className="actionText">Likes</Text>
            {reactionStatus ? (
              <img src={LikedImg} alt="like comment" className="actionImg" />
            ) : (
              <img src={LikeImg} alt="like comment" className="actionImg" />
            )}
            <Text className={`actionCount ${reactionStatus && "greenCount"}`}>
              {reactionCount}
            </Text>
          </div>
          <Text className="divider">|</Text>
          <div className="actionDiv" onClick={() => setIsReply(true)}>
            <Text className="actionText">Replies</Text>
            <img src={ReplyImg} alt="reply comment" className="actionImg" />
            <Text className="actionCount">{comment?.reply}</Text>
          </div>
        </div>
        {replies?.length > 0 && (
          <div className="repliesDiv">
            {replies?.map((rep, ind) => (
              <CommunityPostReply
                setIsReply={setIsReply}
                noMarginComment={ind + 1 === replies?.length}
                reply={rep}
                setIsAuthPopupVisible={setIsAuthPopupVisible}
                key={rep.id}
              />
            ))}
            {comment?.reply > replies.length && (
              <Button
                type="text"
                // loading={replyLoading}
                className="viewMoreReplies"
                onClick={() =>
                  getCommunityPostReplies(
                    comment?.postId,
                    comment?.commentId,
                    replies[replies.length - 1]?.id
                  )
                }
              >
                View More Replies
              </Button>
            )}
            {comment?.reply > replies.length && replyLoading && (
              <CommunityPostCommentSkeleton />
            )}
          </div>
        )}
        <div className={`replyCommentBox ${isReply && "showCommentBox"}`}>
          <CommentBox
            commentType="reply"
            // postId={postId}
            commentId={comment?.commentId}
            setIsAuthPopupVisible={setIsAuthPopupVisible}
          />
        </div>
      </div>
    </div>
  )
}

export default CommunityPostComment
