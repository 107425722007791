import React, { useContext, useEffect, useState } from "react"
import "./ViewProfile.less"
import {Typography} from "antd"
// import { Form, Input, Button, Typography, Row, Col, Tag, message } from "antd"
import EditIcon from "../../../assets/mentroWebApp/editIcon.svg"
import DefaultUser from "../../../assets/mentroWebApp/defaultUser.svg"
import LinkedinIcon from "../../../assets/mentroWebApp/linkedin.svg"
import GithubIcon from "../../../assets/mentroWebApp/github.svg"
import { useProfile } from "../../../hooks/useProfile"
import MakeProfile from "../MakeProfile"
import { linkify } from "../../../utils/data"

const { Text, Title } = Typography

const ViewProfile = () => {
  const { userData, getProfile } = useProfile()
  const [editProfile, setEditProfile] = useState(false)

  return (
    <div className="viewProfileParentDiv">
      {!editProfile ? (
        <div className="viewProfileDiv">
          <div className="editDiv" onClick={() => setEditProfile(true)}>
            <img src={EditIcon} alt="edit profile icon" />
            <Text className="text">Edit Profile</Text>
          </div>
          <div className="profilePicture">
            <img
              src={userData?.imageUrl ? userData?.imageUrl : DefaultUser}
              alt="user"
            />
            <Text className="name">{userData?.userName}</Text>
            <Text className="org">{userData?.institution}</Text>
          </div>
          <div className="interestsDiv">
            <Title className="title">Interests</Title>
            {userData?.interests?.map((intr, ind) => (
              <span className="interest" key={ind}>
                {intr}{" "}
              </span>
            ))}
          </div>
          <div className="bioDiv">
            <Title className="title">Bio</Title>
            <Text className="text">{linkify(userData?.bio)}</Text>
          </div>
          {!userData?.linkedIn && !userData?.github ? null : (
            <div className="socialDiv">
              <Title className="title">Social Platforms</Title>
              {userData?.linkedIn && (
                <span onClick={() => window.open(userData?.linkedIn)}>
                  <img src={LinkedinIcon} alt="linkedin" />
                </span>
              )}
              {userData?.github && (
                <span onClick={() => window.open(userData?.github)}>
                  <img src={GithubIcon} alt="linkedin" />
                </span>
              )}
            </div>
          )}
        </div>
      ) : (
        <MakeProfile setEditProfile={setEditProfile} getProfile={getProfile} />
      )}
    </div>
  )
}

export default ViewProfile
