import React, { useContext, useState } from "react"
import "./CommentBox.less"
import { Button, Form, Input, message } from "antd"
// import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import Guest from "../../../assets/mentroWebApp/defaultUser.svg"
import SendComment from "../../../assets/mentroWebApp/sendComment.svg"
import { getHttpHandler, postHttpHandler } from "../../../utils/httpHandler"
//import { CommunityContext } from "../../../context/CommunityContext"
import useCommunityData from "../../ZustandStateManagment/Communities/store"
const CommentBox = ({
  commentType = "comment",
  commentId = "",
  setIsAuthPopupVisible = pVal => {},
}) => {
  const { user, token, isLoggedIn } = useLoggedInUserStore()
  const {
    getCommunityPostComments,
    topCommentId,
    setLastCommentId,
    setHasMoreComments,
    currentPostId,
    // comments,
    setComments
  } = useCommunityData()
  const [commentForm] = Form.useForm()
  const [isCommenting, setIsCommenting] = useState(false)
  const commentOnPost = async (postId, text, image = "") => {
    if (!isLoggedIn) return setIsAuthPopupVisible(true)
    if (!text) return message.error("Please enter a comment!")

    setIsCommenting(true)
    try {const commentResp = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/comment/add/`,
        
        idToken: token,
        body: {
          text,
          image,
          postId: postId
        },
      })
      setIsCommenting(false)
      if (commentResp.status === "success") {
        message.success("Comment posted!")
        commentForm.resetFields()
        setLastCommentId("")
        setHasMoreComments(false)
        try {
          const commentsResp = await getHttpHandler({
            url: `${process.env.GATSBY_BE_URL}/community/comment/get?postId=${postId}&commentId=${topCommentId}&lastCommentId=${""}`,
            idToken: token,
          })
          setComments(commentsResp?.comments || [])
          setHasMoreComments(commentsResp?.comments.length > 0)
        } catch (error) {
          message.error("Something went wrong!")
        }
        // getCommunityPostComments(comments[0].postId, topCommentId, "")
        return
      }
    } catch (error) {
      setIsCommenting(false)
      message.error("Something went wrong! Please try again.")
    }
  }

  const replyOnComment = async (postId, commentId, text, image = "") => {
    if (!isLoggedIn) return setIsAuthPopupVisible(true)
    if (!text) return message.error("Please enter a comment!")

    setIsCommenting(true)
    try {
      const commentResp = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/reply/add`,
        idToken: token,
        body: {
          text,
          image,
          postId: postId,
          commentId,
        },
      })
      setIsCommenting(false)
      if (commentResp.status === "success") {
        message.success("Comment posted!")
        commentForm.resetFields()
        setLastCommentId("")
        setHasMoreComments(false)
        try {
          const commentsResp = await getHttpHandler({
            url: `${process.env.GATSBY_BE_URL}/community/comment/get?postId=${postId}&commentId=${topCommentId}&lastCommentId=${""}`,
            idToken: token,
          })
          setComments(commentsResp?.comments || [])
          setHasMoreComments(commentsResp?.comments.length > 0)
        } catch (error) {
          message.error("Something went wrong!")
        }
        // getCommunityPostComments(comments[0].postId, topCommentId, "")
        return
      }
    } catch (error) {
      setIsCommenting(false)
      message.error("Something went wrong! Please try again.")
    }
  }

  return (
    <div className="commentBox">
      <img
        src={user?.imageUrl || Guest}
        alt="user"
        className="commentUserImg"
      />
      <Form
        form={commentForm}
        onFinish={values => {
          commentType === "comment"
            ? commentOnPost(currentPostId, values?.commentText, "")
            : replyOnComment(currentPostId, commentId, values?.commentText, "")
        }}
        className="commentForm"
      >
        <Form.Item name={"commentText"} className="inputFormItem">
          <Input placeholder="Type here something..." />
        </Form.Item>
        <Button
          type="text"
          htmlType="submit"
          className="sendCommentIconBtn"
          loading={isCommenting}
        >
          <img src={SendComment} alt="send comment" />
        </Button>
      </Form>
    </div>
  )
}

export default CommentBox
