import React from "react"
import { Button, Tooltip, Typography } from "antd"
import "./OpportunityCard.less"
import Briefcase from "../../../../assets/mentroWebApp/briefcase.svg"
import Location from "../../../../assets/mentroWebApp/location.svg"
import { logGa, mixpanelEvent } from "../../../../../services/analytics"
// import { AuthContext } from "../../../../context/AuthContext"
import { useLoggedInUserStore } from "../../../ZustandStateManagment/UserData/store"
const { Text, Title } = Typography

const OpportunityCard = ({ jobOpp, setIsAuthPopupVisible }) => {
  const { isLoggedIn } = useLoggedInUserStore()

  return (
    <div className="opportunityCardDiv">
      <img
        src={jobOpp.logo !== "" ? jobOpp.logo : Briefcase}
        alt="company Logo"
      />
      <div className="headDiv">
        <Tooltip title={jobOpp?.role}>
          <Title className="role">{jobOpp?.role}</Title>
        </Tooltip>
        <Text className="companyName">{jobOpp?.companyName}</Text>
        <Title className="postedOn">{jobOpp?.postedOn}</Title>
      </div>
      <div className="bodyDiv">
        <div className="locationTag">
          <img src={Location} alt="Location" />
          <Tooltip title={jobOpp?.location} placement="bottom">
            <span>{jobOpp?.location}</span>
          </Tooltip>
        </div>
        <div className="tags">
          <span>{jobOpp?.companyType}</span> <span>{jobOpp?.roleType}</span>
        </div>
      </div>
      <Button
        type="text"
        className="applyNowBtn"
        onClick={() => {
          if (!isLoggedIn) return setIsAuthPopupVisible(true)
          logGa("ITEM_CLICK", {
            job_company: jobOpp?.companyName,
            button_name: `job_${jobOpp?.id}`,
            button_type: "job_application",
          })

          mixpanelEvent("apply_btn_click", {
            screen_name: "jobs_list",
            job_type: jobOpp?.roleType,
            company_type: jobOpp?.companyType,
          })
          window.open(jobOpp?.link)
        }}
      >
        Apply Now
      </Button>
    </div>
  )
}

export default OpportunityCard
