import React from "react"
import "./CommunityPostSkeleton.less"
import { Skeleton } from "antd"

const CommunityPostSkeleton = ({ borderRadius = true, marginTop = false }) => {
  return (
    <div
      className={`communityPostSkeleton ${
        borderRadius && "borderRadiusPostSkeleton"
      } ${marginTop && "marginTopPostSkeleton"}`}
      key={"communitySkel"}
    >
      <div className="topDiv">
        <Skeleton.Avatar
          active
          size="small"
          className="mentorAvatar"
          shape="circle"
        />
        <div className="content">
          <Skeleton.Input className="name" active size="small" />
          <Skeleton.Input className="timeSpan" active size="small" />
        </div>
      </div>
      <div className="bodyDiv">
        <div className="bodyTop">
          <Skeleton.Input active size="small" className="text1" />
          <Skeleton.Input active size="small" className="text2" />
          <Skeleton.Input active size="small" className="text3" />
          <div className="mediaDiv">
            <Skeleton.Avatar
              active
              size="small"
              className="media"
              shape="square"
            />
          </div>
        </div>
        <div className="bottomDiv">
          <div className="countsDiv">
            <Skeleton.Button active size="small" className="viewCount" />
            <Skeleton.Button active size="small" className="viewCount" />
            <Skeleton.Button active size="small" className="viewCount" />
          </div>
          <div className="actionsDiv">
            <div className="action">
              <Skeleton.Avatar
                active
                size="small"
                className="actionAvatar"
                shape="circle"
              />
              <Skeleton.Button active size="small" className="actionBtn" />
            </div>
            <div className="action">
              <Skeleton.Avatar
                active
                size="small"
                className="actionAvatar"
                shape="circle"
              />
              <Skeleton.Button active size="small" className="actionBtn" />
            </div>
            <div className="action">
              <Skeleton.Avatar
                active
                size="small"
                className="actionAvatar"
                shape="circle"
              />
              <Skeleton.Button active size="small" className="actionBtn" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunityPostSkeleton
