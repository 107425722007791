import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import "./Community.less"
import { Button, Col, Input, Row, Select, Typography } from "antd"
import { getHttpHandler, postHttpHandler } from "../../utils/httpHandler"
// import { AuthContext } from "../../context/AuthContext"
import { useLoggedInUserStore } from "../ZustandStateManagment/UserData/store"
// import { CommunityContext } from "../../context/CommunityContext"
import AuthPopup from "../Popups/AuthPopup"
import CommunityPost from "./CommunityPost"
import CommunityPostSkeleton from "./CommunityPostSkeleton"
import CreateCommunityPost from "./CreateCommunityPost"
import NotFound from "../../assets/mentroWebApp/loaderIll.svg"
import SendComment from "../../assets/mentroWebApp/sendComment.svg"
import CreatePostArticle from "../../assets/mentroWebApp/createPostArticle.svg"
import CreatePostImage from "../../assets/mentroWebApp/createPostImage.svg"
import CreatePostVideo from "../../assets/mentroWebApp/createPostVideo.svg"
// import CreateCommunity from "./CreateCommunity"
import {
  logGa,
  mixpanelEvent,
  mixpanelTimeEvent,
} from "../../../services/analytics"
import {
  latestPostsApi,
  nextLatestPostsApi,
} from "../ZustandStateManagment/Communities/Api"
import useCommunityData from "../ZustandStateManagment/Communities/store"
const { Title } = Typography
const MAX_NO_OF_TOPICS = 10

const Community = () => {
  const { token, isLoggedIn, user, mentor, mentorData } = useLoggedInUserStore()
  const [isCreatePostVisible, setisCreatePostVisible] = useState<boolean>(false)
  const [isCreateCommunityVisible, setisCreateCommunityVisible] =
    useState<boolean>(false)
  const { latestPosts: communityPosts, setLatestPosts } = useCommunityData()
  const [loading, setLoading] = useState<boolean>(false)
  const [hasMorePosts, setHasMorePosts] = useState<boolean>(false)
  const [dataReceived, setDataReceived] = useState<boolean>(false)
  const [lastPostId, setLastPostId] = useState<string>("")
  const [isAuthPopupVisible, setIsAuthPopupVisible] = useState<boolean>(false)

  const [popularPosts, setPopularPosts] = useState<any[]>([])
  const [loadingPopularPosts, setLoadingPopularPosts] = useState<boolean>(false)

  const getLatestPosts = async () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    setLoading(true)
    setDataReceived(false)
    try {
      let latestPosts = await latestPostsApi(user, token, MAX_NO_OF_TOPICS)
      setDataReceived(true)
      setLatestPosts(latestPosts.posts)
      setHasMorePosts(latestPosts.posts.length > 0)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setDataReceived(false)
    }
  }

  const getNextLatestPosts = async () => {
    if (!hasMorePosts || !lastPostId) {
      return
    }
    setLoading(true)
    try {
      const nextLatestPosts = await nextLatestPostsApi(
        token,
        lastPostId,
        user,
        MAX_NO_OF_TOPICS
      )
      let updatedPosts = [...communityPosts, ...nextLatestPosts.posts]
      setLatestPosts(updatedPosts)
      setHasMorePosts(nextLatestPosts.posts.length > 0)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  // console.log("P",lastPostId, "PP")

  useEffect(() => {
    logGa("PAGE_LANDING", {
      screen_name: "communities_page",
    })
    getLatestPosts()
    getPopularPosts()

    mixpanelTimeEvent("page_view_communities_feed")

    return () => {
      mixpanelEvent("page_view_communities_feed", {
        screen_name: "communities_feed",
        user_type: isLoggedIn ? (mentor ? "mentor" : "student") : "guest",
      })
    }
  }, [])

  useEffect(() => {
    getNextLatestPosts()
  }, [lastPostId])

  // -----------------------------------------------------------------
  // INFINITE SCROLLING-----------------------------------------------------------------
  // -----------------------------------------------------------------
  const observer = useRef<any>()
  const lastPostRef = useCallback(
    ele => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasMorePosts) {
            setLastPostId(
              communityPosts[communityPosts.length - 1]?.postId || ""
            )
          }
        },
        {
          root: null,
          rootMargin: "40px",
          threshold: 0,
        }
      )
      if (ele) observer.current.observe(ele)
    },
    [loading, hasMorePosts]
  )
  //-----------------------------------

  const getPopularPosts = async () => {
    setLoadingPopularPosts(true)
    try {
      const popularPosts = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/post/getPopularPosts`,
        idToken: token,
        body: {
          topics: user?.interests?.slice(0, MAX_NO_OF_TOPICS) || [],
        },
      })
      setPopularPosts(popularPosts.posts)
      setLoadingPopularPosts(false)
    } catch (error) {
      setLoadingPopularPosts(false)
    }
  }

  return (
    <div className="communityDiv">
      <AuthPopup
        setIsAuthPopupVisible={setIsAuthPopupVisible}
        isAuthPopupVisible={isAuthPopupVisible}
      />
      <CreateCommunityPost
        communityId={null}
        onCreatePost={getLatestPosts}
        isCreatePostVisible={isCreatePostVisible}
        setisCreatePostVisible={setisCreatePostVisible}
      />
      {/* <CreateCommunity
        currentCommunity={null}
        setCurrentCommunity={() => {}}
        onCreateCommunity={() => {}}
        isCreateCommunityVisible={isCreateCommunityVisible}
        setisCreateCommunityVisible={setisCreateCommunityVisible}
      /> */}
      <Row gutter={[40, 10]} className="communityRow">
        <Col lg={16} className="communityCol">
          {dataReceived &&
            communityPosts?.length > 0 &&
            communityPosts.map((communityPostData, ind) => (
              <CommunityPost
                noMarginPost={ind + 1 === communityPosts?.length}
                joinCommunityHeader
                setIsAuthPopupVisible={setIsAuthPopupVisible}
                communityPost={communityPostData}
                key={communityPostData.postId}
              />
            ))}
          {loading && (
            <CommunityPostSkeleton
              marginTop={dataReceived && communityPosts?.length > 0}
            />
          )}
          {dataReceived && communityPosts?.length === 0 && (
            <div className="noPostsDiv">
              <img src={NotFound} alt="not found illustration" />
              <Title className="noPostsText">No posts found.</Title>
            </div>
          )}
          <div ref={lastPostRef} />
        </Col>
        <Col lg={8} className="communityCol rightCol">
          {token && mentor && (
            <div className="postFromCommunityDiv">
              <div
                className="postDiv"
                onClick={() => {
                  logGa("ITEM_CLICK", {
                    item_name: "create_post_btn",
                    user_id: mentorData?.uid,
                  })
                  setisCreatePostVisible(true)
                }}
              >
                <div className="inputDiv">
                  <Input placeholder="Share your knowledge..." />
                  <Button type="text" className="sendCommentIconBtn">
                    <img src={SendComment} alt="send comment" />
                  </Button>
                </div>
                <div className="createTypeBtnsDiv">
                  <div className="actionTypeDiv">
                    <img
                      src={CreatePostImage}
                      alt="actionImg"
                      className="actionImg"
                    />
                    <Title className="actionText">Image</Title>
                  </div>
                  <div className="actionTypeDiv">
                    <img
                      src={CreatePostVideo}
                      alt="actionImg"
                      className="actionImg"
                    />
                    <Title className="actionText">Video</Title>
                  </div>
                  <div className="actionTypeDiv">
                    <img
                      src={CreatePostArticle}
                      alt="actionImg"
                      className="actionImg"
                    />
                    <Title className="actionText">Article</Title>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="popularPostDiv">
            {popularPosts?.length > 0 && (
              <Title className="title">Most popular from your choices</Title>
            )}
            {popularPosts?.length > 0 &&
              popularPosts.map((communityPopularPostData, ind) => (
                <CommunityPost
                  smallFont={true}
                  noMarginPost={ind + 1 === popularPosts?.length}
                  borderRadius={false}
                  setIsAuthPopupVisible={setIsAuthPopupVisible}
                  communityPost={communityPopularPostData}
                  key={communityPopularPostData.postId}
                />
              ))}
            {loadingPopularPosts && (
              <CommunityPostSkeleton
                borderRadius={false}
                marginTop={popularPosts?.length > 0}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Community
