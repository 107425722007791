import React, { useEffect } from "react"
// import { AuthContext } from "../../context/AuthContext"
import MentorSessions from "./MentorSessions"
import UserSessions from "./UserSessions"
import { useLoggedInUserStore } from "../ZustandStateManagment/UserData/store"
import { mixpanelEvent, mixpanelTimeEvent } from "../../../services/analytics"
const Sessions = () => {
  const { mentor, isLoggedIn } = useLoggedInUserStore()

  useEffect(() => {
    mixpanelTimeEvent("page_view_sessions")

    return () => {
      mixpanelEvent("page_view_sessions", {
        screen_name: "sessions",
        user_type: isLoggedIn ? (mentor ? "mentor" : "student") : "guest",
      })
    }
  }, [])

  return mentor ? <MentorSessions /> : <UserSessions />
}

export default Sessions
