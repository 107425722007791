import React from "react"
import { Button, Typography } from "antd"
import "./CourseCard.less"
import { navigate } from "gatsby"
import RightArrow from "../../../../assets/mentro/rightArrow.svg"
import coursesCoverImg from "../../../assets/mentroWebApp/courses-cover.jpg"
import { mixpanelEvent } from "../../../../services/analytics"

const { Title, Text } = Typography

const CourseCard = ({ course }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false)

  return (
    <div
      key={course.courseId}
      className="courseCardDiv"
      onClick={() => {
        mixpanelEvent("learning_track_item_click", {
          course_id: `${course?.tech}_${course?.lang}`,
        })
        navigate(`/web/resources/${course?.courseId}`)
      }}
    >
      <img
        onLoad={() => setImageLoaded(true)}
        src={course?.banner}
        alt="course banner"
        className={`top ${imageLoaded ? "loaded" : "unloaded"}`}
      />
      {!imageLoaded && (
        <img src={course.image} alt="courses cover" className="top" />
      )}
      <div className="bottom">
        <Title className="courseHeading">{course.name}</Title>
        <div className="bottomBtnDiv">
          <Text className="tag">{course.technology}</Text>
          <Button
            type="text"
            className="goBtn"
            onClick={() => {
              navigate(`/web/resources/${course?.courseId}`)
            }}
          >
            <img src={RightArrow} alt="RightArrow" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CourseCard
