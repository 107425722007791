import React, { useContext, useEffect, useState } from "react"
import "./MentorMakeProfile.less"
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Tag,
  message,
  Upload,
  AutoComplete,
  Switch,
} from "antd"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { useProfile } from "../../../hooks/useProfile"
import ImgCrop from "antd-img-crop"
import DefaultUser from "../../../assets/mentroWebApp/defaultUser.svg"
import ErrorMessage from "../../../assets/mentroWebApp/errorMessage.svg"
import GreenTick from "../../../assets/mentroWebApp/greenTick.svg"
import BackButton from "../../../assets/mentroWebApp/backButton.svg"
import "antd/es/modal/style"
import "antd/es/slider/style"
import { app } from "../../../../../firebaseConfig"

const { Text, Title } = Typography
const { TextArea } = Input
const { CheckableTag } = Tag

const MentorMakeProfile = ({
  setEditProfile,
  orgList,
  interestList,
  getProfile,
}) => {
  const authContext = useLoggedInUserStore()
  const { token } = authContext
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTags, setSelectedTags] = useState<any[]>([])
  const [options, setOptions] = useState<any[]>([
    ...orgList.map(el => {
      return {
        value: el.name,
      }
    }),
  ])

  const [fileList, setFileList] = useState<any>([])
  const [profileImageUrl, setProfileImageUrl] = useState<string>("")
  const [isProfilePhoto, setIsProfilePhoto] = useState({
    status: "hold",
    message: "",
  })

  const { mentorData } = useProfile()
  const {
    profileUrl,
    name,
    phoneNo,
    organisation,
    role,
    interests,
    profileId,
    linkedInUrl,
    twitterUrl,
    quote,
    bio,
    address,
    uid,
    isActive,
  } = mentorData

  const saveProfile = async (values, urlData) => {
    const {
      name,
      phoneNo,
      company,
      role,
      linkedInUrl,
      twitterUrl,
      profileId,
      quote,
      bio,
      address,
      isActive,
    } = values

    const org = orgList.find(org => org.name === company)

    try {
      const response = await fetch(`${process.env.GATSBY_BE_URL}/mentor/edit`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          imageUrl: profileImageUrl === urlData ? profileImageUrl : urlData,
          organisation,
          orgImageUrl: org ? org.imageUrl : "",
          interests: selectedTags,
          name,
          phoneNo,
          company,
          role,
          profileId,
          linkedInUrl,
          twitterUrl,
          quote,
          bio,
          address,
          isActive,
        }),
      })

      const responseData = await response.json()
      if (responseData.success) {
        message.success({
          content: responseData.message,
          icon: (
            <img
              src={GreenTick}
              alt="tick mark"
              style={{
                border: "2px solid #4caf50",
                borderRadius: "50%",
                margin: "0em 1em",
              }}
            />
          ),
        })
        setIsLoading(false)
        setEditProfile(false)
        setProfileImageUrl("")
        form.resetFields()
        getProfile()
      } else {
        message.warning({
          content: responseData.message,
          icon: (
            <img
              src={ErrorMessage}
              alt="error"
              style={{
                width: "32px",
                margin: "0em 1em",
              }}
            />
          ),
        })
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      message.error({
        content: "Something went wrong",
        icon: (
          <img
            src={ErrorMessage}
            alt="error"
            style={{
              width: "32px",
              margin: "0em 1em",
            }}
          />
        ),
      })
    }
  }

  const searchBox = el => {
    const data = [
      ...orgList.map(el => {
        return {
          value: el.name,
        }
      }),
    ]
    const newOps = data.filter(
      (optionVal: any) =>
        optionVal.value.toLowerCase().indexOf(el.toLowerCase()) > -1
    )
    if (newOps.length === 0) {
      setOptions([{ value: el }])
      return
    }
    if (el === "") {
      setOptions([...data])
      return
    }

    setOptions(newOps)
  }

  const onFinish = async (values: any) => {
    if (selectedTags.length < 1) {
      return
    }

    setIsLoading(true)
    if (fileList[0]) {
      const uploadTask = app
        .storage()
        .ref(`users/${uid}/profile.jpg`)
        .put(fileList[0].originFileObj)

      uploadTask.on(
        "state_changed",
        snapshot => {
          return snapshot
        },
        err => console.error(err),
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(urlData => {
              if (urlData) {
                setProfileImageUrl(urlData)
                saveProfile(values, urlData)
              }
            })
            .catch(err => {
              setIsLoading(false)
              message.error({
                content: "Not able to upload the picture",
                icon: (
                  <img
                    src={ErrorMessage}
                    alt="error"
                    style={{
                      width: "32px",
                      margin: "0em 1em",
                    }}
                  />
                ),
              })
              console.error(err)
            })
        }
      )
    } else {
      saveProfile(values, profileImageUrl)
    }
    // saveProfile(values)
  }

  const onFinishFailed = errorInfo => {
    //
  }

  const onUploadFile = ({ fileList: newFileList }) => {
    const isLt2M = newFileList[0].size / 1024 / 1024 < 2
    if (!isLt2M) {
      setIsProfilePhoto({
        status: "error",
        message: "Image size is greater than 2MB",
      })
      return console.error("Image must smaller than 2MB!")
    }

    setFileList(newFileList)
    setIsProfilePhoto({ status: "success", message: "" })
  }

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag.value]
      : selectedTags.filter(t => t !== tag.value)
    setSelectedTags(nextSelectedTags)
  }

  useEffect(() => {
    form.setFields([
      { name: "name", value: name },
      { name: "phoneNo", value: phoneNo },
      { name: "company", value: organisation },
      { name: "role", value: role },
      { name: "profileId", value: profileId },
      { name: "linkedInUrl", value: linkedInUrl },
      { name: "twitterUrl", value: twitterUrl },
      { name: "quote", value: quote },
      { name: "bio", value: bio },
      { name: "address", value: address },
      { name: "isActive", value: isActive },
    ])
    setSelectedTags(interests)
    setProfileImageUrl(profileUrl)
  }, [])

  return (
    <div className="mentorMakeProfileDiv">
      <div className="formDiv">
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="backTopDiv">
            <div className="backDiv" onClick={() => setEditProfile(false)}>
              <img src={BackButton} alt="back icon" />
              <Text className="text">Back to Profile</Text>
            </div>
            <div className="availableDiv">
              <Text className="text">AVAILABILITY</Text>
              <Form.Item name="isActive" className="isActive">
                <Switch defaultChecked={isActive} />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            validateStatus={isProfilePhoto.status === "error" ? "error" : ""}
            help={
              isProfilePhoto.message ? (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  {isProfilePhoto.message}
                </Text>
              ) : null
            }
            label={<Text className="text">PROFILE PICTURE</Text>}
            name="profilePicture"
            className="uploadPicForm"
          >
            <img
              src={profileImageUrl ? profileImageUrl : DefaultUser}
              alt="defaultUser"
              className="defaultUser"
              style={
                fileList.length >= 1 ? { visibility: "hidden" } : undefined
              }
            />

            <ImgCrop grid rotate>
              <Upload
                onChange={onUploadFile}
                listType="picture-card"
                fileList={fileList}
                accept="image/png, image/jpeg"
                className="upload-list-inline"
                maxCount={1}
              >
                <div className="btnDiv">
                  <Button type="text" className="uploadBtn">
                    Upload image
                  </Button>
                </div>
                <Text className="text">
                  You can upload .jpeg or .png image files. Max size of 2MB.
                </Text>
              </Upload>
            </ImgCrop>
            <Button
              type="text"
              className="removeBtn"
              onClick={() => {
                setFileList([])
                setProfileImageUrl("")
              }}
            >
              Remove
            </Button>
          </Form.Item>
          <Row className="nameDiv">
            <Col>
              {" "}
              <Form.Item
                label={<Text className="text">*NAME</Text>}
                name="name"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Please enter your Name
                      </Text>
                    ),
                  },
                ]}
              >
                <Input placeholder="Enter your Name" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={<Text className="text">*PHONE</Text>}
                name="phoneNo"
                rules={[
                  {
                    required: true,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Please enter your Phone No
                      </Text>
                    ),
                  },
                  {
                    pattern:
                      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Please enter a valid Phone No
                      </Text>
                    ),
                  },
                ]}
              >
                <Input placeholder="Enter a Phone No" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="nameDiv">
            <Col>
              <Form.Item
                label={<Text className="text">*COMPANY NAME</Text>}
                name="company"
                rules={[
                  {
                    required: true,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Please enter your Company
                      </Text>
                    ),
                  },
                ]}
              >
                <AutoComplete options={options} onSearch={searchBox} allowClear>
                  <Input type="text" id="select-data" />
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={<Text className="text">*PROFESSIONAL ROLE</Text>}
                name="role"
                rules={[
                  {
                    required: true,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Please enter your Role
                      </Text>
                    ),
                  },
                ]}
              >
                <Input placeholder="Enter your Role" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={<Text className="text">*PROFILE ID</Text>}
            name="profileId"
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a valid profile id
                  </Text>
                ),
              },
            ]}
          >
            <Input addonBefore="mentro.tech/@" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">*LINKEDIN PROFILE</Text>}
            name="linkedInUrl"
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Linkedin Profile
                  </Text>
                ),
              },
              {
                pattern: /https:\/\/(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a valid LinkedIn url
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="https://www.linkedin.com/in/..." />
          </Form.Item>
          <Form.Item
            label={<Text className="text">TWITTER PROFILE</Text>}
            name="twitterUrl"
            rules={[
              {
                pattern:
                  /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a valid Twitter url
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="https://www.twitter.com/..." />
          </Form.Item>
          <Form.Item
            label={<Text className="text">*QUOTE</Text>}
            name="quote"
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a Quote
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter a Quote" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">*BIO</Text>}
            name="bio"
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a bio
                  </Text>
                ),
              },
            ]}
          >
            <TextArea rows={4} placeholder="Enter your Bio" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">ADDRESS</Text>}
            name="address"
          >
            <TextArea rows={4} placeholder="Enter your Address" />
          </Form.Item>
          <Form.Item
            validateStatus={selectedTags.length < 1 ? "error" : ""}
            help={
              selectedTags.length < 1 ? (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please select atleast one interest
                </Text>
              ) : (
                ""
              )
            }
            label={<Text className="text">*INTERESTS</Text>}
            name="tags"
          >
            <div className="tags">
              {interestList.map(tag => (
                <CheckableTag
                  className="checkTag"
                  key={tag.value}
                  checked={selectedTags.indexOf(tag.value) > -1}
                  onChange={checked => handleChange(tag, checked)}
                >
                  {tag.label}
                </CheckableTag>
              ))}
            </div>
          </Form.Item>
          <div className="saveDiv">
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="saveBtn"
                loading={isLoading}
              >
                Save Profile
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default MentorMakeProfile
