import React, { createRef } from "react"
import "./Chats.less"
import { Button, Col, Row, Typography } from "antd"
import { useLocation } from "@reach/router"
import ChatsIll from "../../../assets/mentroWebApp/chatsIll.png"
import PlayStore from "../../../../assets/mentro/playStore.svg"

const { Title, Text } = Typography

const Chats = () => {
  return (
    <div className="chatsParent">
      <Row className="chatsRow">
        <Col className="contentCol" lg={12}>
          <Title className="contentTitle">Want to discuss over chat?</Title>
          <Text className="contentText">
            Head over to Mentro's Android App to chat and check out many
            exclusive features!
          </Text>
          <Button
            type="text"
            className="visitBtn"
            onClick={() => {
              window.open(process.env.GATSBY_PLAYSTORE_LINK)
            }}
          >
            Download the App Now
            <img src={PlayStore} alt="PlayStore" />
          </Button>
        </Col>
        <Col lg={12} className="imageCol">
          <img src={ChatsIll} alt="chats illustration" />
        </Col>
      </Row>
    </div>
  )
}

export default Chats
