import React from "react"
import "./CommunityPostCommentSkeleton.less"
import { Skeleton } from "antd"

const CommunityPostCommentSkeleton = ({
  borderRadius = true,
  image = false,
}) => {
  return (
    <div
      className={`communityPostCommentSkeleton ${
        borderRadius && "borderRadiusComment"
      }`}
      key={"communityPostCommentSkeleton"}
    >
      <div className="topDiv">
        <Skeleton.Avatar
          active
          size="small"
          className="mentorAvatar"
          shape="circle"
        />
        <div className="content">
          <Skeleton.Input className="name" active size="small" />
          <Skeleton.Input className="timeSpan" active size="small" />
        </div>
      </div>
      <div className="bodyDiv">
        <div className="bodyTop">
          <Skeleton.Input active size="small" className="text1" />
          {image && (
            <Skeleton.Avatar
              active
              size="small"
              className="media"
              shape="square"
            />
          )}
          <Skeleton.Input active size="small" className="text2" />
        </div>
      </div>
    </div>
  )
}

export default CommunityPostCommentSkeleton
