import React from "react"
import "./DownloadAppPopup.less"
import { Button, Modal, Typography } from "antd"
import { navigate } from "gatsby"
import PlayStore from "../../../../assets/mentro/playStore.svg"
import PlayStorePng from "../../../../assets/images/playstore.svg"
import { mixpanelEvent } from "../../../../services/analytics"
const { Title } = Typography

const DownloadAppPopup = ({
  setIsDownloadAppPopupVisible,
  isDownloadAppPopupVisible,
}) => {
  return (
    <Modal
      className="downloadAppModal"
      visible={isDownloadAppPopupVisible}
      onOk={() => {}}
      onCancel={() => setIsDownloadAppPopupVisible(false)}
      footer={null}
    >
      <div className="downloadAppDiv">
        <img src={PlayStorePng} alt="PlayStore" className="playStorePng" />
        <Title className="text">
          Download the Android app for better experience!
        </Title>

        <Button
          type="text"
          className="downloadAppBtn"
          onClick={() => {
            mixpanelEvent("popup_click", {
              screen_name: "detail_track_screen",
              popup_name: "download_app",
            })
            setIsDownloadAppPopupVisible(false)
            window.open(process.env.GATSBY_PLAYSTORE_LINK)
          }}
        >
          Download app
          <img src={PlayStore} alt="PlayStore" />
        </Button>
      </div>
    </Modal>
  )
}

export default DownloadAppPopup
