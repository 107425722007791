import React from "react"
import { Button, Tooltip, Typography } from "antd"
import "./MentorCard.less"
import Guest from "../../../../assets/mentroWebApp/defaultUser.svg"
import GoldenStar from "../../../../assets/mentroWebApp/goldenStar.svg"
import VerifiedTick from "../../../../assets/mentroWebApp/verifiedTick.svg"
import { navigate } from "gatsby"
import { logGa, mixpanelEvent } from "../../../../../services/analytics"

const { Title } = Typography

const MentorCard = ({ mentorData }) => {
  const {
    name,
    profileUrl,
    role,
    organisation,
    interests,
    averageRating,
    profileId,
    isVerified,
  } = mentorData

  return (
    <div
      className="mentorCardDiv"
      onClick={() => {
        logGa("ITEM_CLICK", {
          item_name: "start_session_btn",
          screen_name: "mentor_list_screen",
          mentor_profile_id: profileId,
        })
        mixpanelEvent("mentor_item_click", {
          mentor_id: profileId,
        })
        navigate(`/web/@${profileId}`)
      }}
    >
      <img
        src={profileUrl ? profileUrl : Guest}
        alt="mentorProfilePic"
        className="mentorProfilePic"
      />
      <div className="headDiv">
        <div className="nameDiv">
          <Title className="nameText">{name}</Title>
          {isVerified && (
            <img
              src={VerifiedTick}
              alt="VerifiedTick"
              className="verifiedTick"
            />
          )}
        </div>
        <Tooltip title={`${role} at ${organisation}`}>
          <Title className="roleText">
            {role} at {organisation}
          </Title>
        </Tooltip>
      </div>
      <div className="ratingDiv">
        {Array.from(Array(Math.ceil(averageRating))).map((star, ind) => (
          <img
            src={GoldenStar}
            alt="goldenStar"
            className="ratingStar"
            key={ind}
          />
        ))}
        <span>{averageRating}</span>
      </div>
      <div className="bodyDiv">
        <div className="interestsDiv">
          {interests.slice(0, 2).map((intr, ind) => (
            <Tooltip title={intr} key={ind} placement="bottom">
              <span className="interest">{intr}</span>
            </Tooltip>
          ))}
        </div>
      </div>
      <Button
        className="sessionStarBtn"
        type="text"
        onClick={() => {
          logGa("ITEM_CLICK", {
            item_name: "start_session_btn",
            screen_name: "mentor_list_screen",
            mentor_profile_id: profileId,
          })
          mixpanelEvent("mentor_item_click", {
            mentor_id: profileId,
          })
          navigate(`/web/@${profileId}`)
        }}
      >
        Start Session
      </Button>
    </div>
  )
}

export default MentorCard
