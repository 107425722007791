import React, { useContext, useEffect, useRef, useState } from "react"
import "./PublicProfile.less"
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Skeleton,
  Typography,
} from "antd"
import moment from "moment"
import { useLoggedInUserStore } from "../ZustandStateManagment/UserData/store"
import { useLocation } from "@reach/router"
import { getHttpHandler, postHttpHandler } from "../../utils/httpHandler"
import { Link, navigate } from "gatsby"
import { loadScript } from "../../utils/loadScript"
import { getSessionIcon, linkify, monthList, daysList } from "../../utils/data"
// import { AuthContext } from "../../context/AuthContext"
import {
  logGa,
  mixpanelEvent,
  mixpanelTimeEvent,
} from "../../../services/analytics"
import useCommunityData from "../ZustandStateManagment/Communities/store"
//import { CommunityContext } from "../../context/CommunityContext"
import PaymentCard from "../PaymentCard"
import ShareButtons from "../ShareButtons"
import AuthPopup from "../Popups/AuthPopup"
import DefaultUser from "../../assets/mentroWebApp/defaultUser.svg"
import LinkedinIcon from "../../assets/mentroWebApp/linkedin.svg"
import TwitterIcon from "../../assets/mentroWebApp/twitter.svg"
import AverageRating from "../../assets/mentroWebApp/averageRating.svg"
import SessionCompleted from "../../assets/mentroWebApp/sessionCompleted.svg"
import MemberCount from "../../assets/mentroWebApp/memberCount.svg"
import DownArrow from "../../assets/mentroWebApp/downArrow.svg"
import LeftArrow from "../../assets/mentroWebApp/leftArrow.svg"
import RightArrow from "../../assets/mentroWebApp/rightArrow.svg"
import BackArrow from "../../assets/mentroWebApp/backArrow.svg"
import ErrorMessage from "../../assets/mentroWebApp/errorMessage.svg"
import VerifiedTick from "../../assets/mentroWebApp/verifiedTick.svg"
import GoldenStar from "../../assets/mentroWebApp/goldenStar.svg"
import MentroLogo from "../../../assets/mentro/mentroLogo.svg"
import AppliedSuccess from "../../../assets/mentor/appliedSuccess.svg"
import ShareIcon from "../../assets/mentroWebApp/share.svg"
// import GreenShareArrow from "../../assets/mentroWebApp/greenShareArrow.svg"
import {
  ContentTitleModel,
  ContentTextModel,
  ContentImageModel,
  ContentVideoModel,
} from "../Community/CommunityPost/CommunityPostModels"
import {
  osName,
  osVersion,
  browserName,
  browserVersion,
  deviceType,
} from "react-device-detect"
import Cookies from "js-cookie"
import TextArea from "antd/lib/input/TextArea"

const { Text, Title } = Typography

const NO_OF_POST_TO_SHOW = 2
const PREVIEW_ITEMS_COUNT = 2
const reviewLimit = 15
// const MIN_MEMBERS = 10
// const MIN_POSTS = 1

export const getFormattedTime = date => {
  return new Date(date).toLocaleTimeString("en-US", {
    timeStyle: "short",
  })
}

const communityPostModelDataTypes = {
  title: ContentTitleModel,
  text: ContentTextModel,
  image: ContentImageModel,
  video: ContentVideoModel,
}

const PublicProfile = () => {
  const location = useLocation()
  const startSessionRef = useRef<any>(null)
  const [questionForm] = Form.useForm()
  const [noSlotsForm] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isSendRequestModalVisible, setIsSendRequestModalVisible] =
    useState<boolean>(false)
  const [modalDescData, setModalDescData] = useState<{
    successStatus: boolean
    message: string
  }>({
    successStatus: false,
    message: "",
  })
  const [reviewMore, setReviewMore] = useState<boolean>(true)
  const [mentorData, setMentorData] = useState<any>(null)
  const [interestList, setInterestList] = useState<any>([])
  const [sessionList, setSessionList] = useState<any>([])
  const [currentSession, setCurrentSession] = useState<any>(null)
  const [isBookSession, setIsBookSession] = useState<boolean>(false)
  const [createSessionLoading, setCreateSessionLoading] =
    useState<boolean>(false)
  const [slotsRequestLoading, setSlotsRequestLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataReceived, setDataReceived] = useState<boolean>(false)
  const [calendarEvents, setCalendarEvents] = useState<any>(null)
  const [pricingData, setPricingData] = useState<any>(null)
  const [promoCode, setPromoCode] = useState<string>("")
  const [promoCodeFinal, setPromoCodeFinal] = useState<string>("")
  const [selectedDate, setSelectedDate] = useState<any>(new Date())
  const [selectedSlot, setSelectedSlot] = useState<{
    start: any
    end: any
    id: string
  }>({
    start: null,
    end: null,
    id: "",
  })
  const {
    isLoggedIn,
    token,
    user,
    mentorData: loggedMentorData,
    mentor: isMentor,
  } = useLoggedInUserStore()
  const { joinCommunity } = useCommunityData()
  const [isAuthPopupVisible, setIsAuthPopupVisible] = useState<boolean>(false)
  const [isBookSessionCol, setIsBookSessionCol] = useState<boolean>(false)
  const [latestPosts, setLatestPosts] = useState<any[]>([])

  const getData = async () => {
    const data = await getHttpHandler({
      url: `${process.env.GATSBY_BE_URL}/getMentorFormData/`,
      idToken: token,
    })
    setInterestList(data.interests)
  }

  const getProfileData = async profileId => {
    try {
      const profileData = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/v2/users/getProfileData?profileId=${profileId}`,
        idToken: token,
      })
      if (profileData?.mentorData?.uid) setMentorData(profileData)
      if (location.hash === "#startsession") {
        setIsBookSessionCol(true)
        getSessionData(profileData?.mentorData?.uid)
        setTimeout(() => {
          startSessionRef?.current?.scrollIntoView({ behavior: "smooth" })
        }, 500)
      }
    } catch (error) {
      navigate("/error", {
        replace: true,
      })
    }
  }

  const showMoreReview = async () => {
    if (!reviewMore || mentorData.mentorData.reviews.length === 0) {
      return setReviewMore(false)
    }
    let docId =
      mentorData.mentorData.reviews[mentorData.mentorData.reviews.length - 1].id
    try {
      const mentorDataResp = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/getAllReviews?documentId=${docId}&mentorId=${mentorData.mentorData.uid}&limit=${reviewLimit}`,
        idToken: token,
      })

      setMentorData({
        ...mentorData,
        mentorData: {
          ...mentorData.mentorData,
          reviews: [
            ...mentorData.mentorData.reviews,
            ...mentorDataResp.reviews,
          ],
        },
      })
      setReviewMore(mentorDataResp.reviews.length === reviewLimit)
    } catch (error) {
      message.error("Something went wrong")
    }
  }

  const getSessionData = async mentorUid => {
    try {
      const sessionData = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/session/getSessionTypesDetails?mentorId=${mentorUid}`,
        idToken: token,
      })
      setSessionList(sessionData.sessionTypeDetails)
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    if (location.pathname && location.pathname.includes("@")) {
      if (
        token &&
        loggedMentorData.profileId === location.pathname.split("@")[1]
      ) {
        navigate("/web/profile", {
          replace: true,
        })
      }
      getData()
      getProfileData(location.pathname.split("@")[1])
    } else {
      navigate("/web/", {
        replace: true,
      })
    }
  }, [location.pathname, token])

  useEffect(() => {
    if (!mentorData?.mentorData?.uid) return
    getLatestPosts()
    logGa("PAGE_LANDING", {
      screen_name: "mentor_profile",
      mentor_id: mentorData.mentorData.uid,
    })

    mixpanelTimeEvent("page_view_mentor_profile")

    return () => {
      mixpanelEvent("page_view_mentor_profile", {
        screen_name: "mentor_profile",
        mentor_id: mentorData.mentorData.profileId,
      })
    }
  }, [mentorData])

  const getPricingData = async sessionData => {
    try {
      const pricingData = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/v2/users/getUltimatePrice?payment=${sessionData.amount}&promoCode=${promoCode}&typeId=${sessionData.id}&mentorId=${mentorData.mentorData?.uid}&type=session`,
        idToken: token,
      })
      if (pricingData.isValid && promoCode) {
        setPromoCodeFinal(promoCode)
      } else {
        setPromoCodeFinal("")
      }
      setPricingData(pricingData)
    } catch (error) {
      //
    }
  }

  const getCalendarEvents = async (date, sessionTypeId) => {
    setLoading(true)
    const currDate =
      date.getMonth() === new Date().getMonth() ? date.getDate() : 0

    try {
      const calendarEventsData = await getHttpHandler({
        url: `${
          process.env.GATSBY_BE_URL
        }/v2/session/getSlotsForMentees?sessionTypeId=${sessionTypeId}&currentDate=${currDate}&month=${
          date.getMonth() + 1
        }&year=${date.getFullYear()}&tzoffset=%2B0530&uid=${
          mentorData.mentorData.uid
        }`,
        idToken: token,
      })
      setLoading(false)
      setDataReceived(true)
      setCalendarEvents(calendarEventsData.events)

      if (calendarEventsData.events.length > 0) {
        let firstDay: any = Object.keys(calendarEventsData.events)[0]
        let availableSlot: any = calendarEventsData.events[
          firstDay
        ].slots.filter((e: any) => e.isAvailable)

        //As Safari doesn't takes date created in the format of yyyy-mm-dd
        let dateToBeSelected = new Date(date)
        dateToBeSelected.setDate(firstDay)
        setSelectedDate(new Date(dateToBeSelected))
        if (firstDay && availableSlot.length > 0) {
          setSelectedSlot({
            start: availableSlot[0].start,
            end: availableSlot[0].end,
            id: availableSlot[0].id,
          })
        } else {
          setSelectedSlot({
            start: null,
            end: null,
            id: "",
          })
        }
      }
    } catch (error) {
      setDataReceived(false)
      setLoading(false)
    }
  }

  const bookSession = sessionData => {
    if (!isLoggedIn) {
      return navigate("/web/auth", {
        state: {
          navigatedFrom: "bookSession",
          navigatedBackTo: `/web/@${mentorData.mentorData.profileId}`,
        },
      })
    }

    if (isMentor) {
      return message.error(
        "You cannot book session as a mentor. Log in with a student profile to book a session."
      )
    }

    if (!mentorData?.mentorData?.isActive) {
      return message.error(
        `${mentorData.mentorData.name} is not available for session.`
      )
    }

    const loadRazorpayScript = async () => {
      await loadScript("https://checkout.razorpay.com/v1/checkout.js")
    }
    loadRazorpayScript()

    const loadConnectycubeScript = async () => {
      await loadScript(
        "https://cdn.jsdelivr.net/npm/connectycube@3.14.0/dist/connectycube.min.js"
      )
      const CC_CRED = {
        appId: process.env.GATSBY_CC_APP_ID,
        authKey: process.env.GATSBY_CC_AUTH_KEY,
        authSecret: process.env.GATSBY_CC_AUTH_SECRET,
      }
      window.ConnectyCube.init(CC_CRED)
    }
    loadConnectycubeScript()

    setIsBookSession(true)
    logGa("PAGE_LANDING", {
      screen_name: "session_detail_screen",
      session_type_title: sessionData.title,
    })

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    setCurrentSession(sessionData)
    getPricingData(sessionData)
    getCalendarEvents(new Date(), sessionData.id)
  }

  const bookFreeSession = async (orderId, dialogId, description) => {
    setCreateSessionLoading(true)
    try {
      const bookSessionResponse = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/v3/session/createNewSession`,
        idToken: token,
        body: {
          orderCreationId: orderId,
          sessionTypeId: currentSession.id,
          mentorId: mentorData.mentorData?.uid,
          promoCode: promoCodeFinal,
          dialogId: dialogId,
          date: selectedDate.getDate(),
          month: selectedDate.getMonth() + 1,
          year: selectedDate.getFullYear(),
          eventId: selectedSlot.id,
          description,
        },
      })
      if (bookSessionResponse.success) {
        logGa("PAYMENT_STATUS", {
          screen_name: `session_${currentSession.id}`,
          status: "success",
        })
        setCreateSessionLoading(false)
        setModalDescData({
          successStatus: true,
          message: "Session Booked Successfully!!!",
        })
        questionForm.resetFields()
        setIsModalVisible(true)
        getCalendarEvents(new Date(), currentSession.id)
        return true
      }
      setCreateSessionLoading(false)
      logGa("PAYMENT_STATUS", {
        screen_name: `session_${currentSession.id}`,
        status: "error",
      })
      return message.error(
        "Cannot book session!!! Please email us at contact@mentro.tech"
      )
    } catch (error) {
      logGa("PAYMENT_STATUS", {
        screen_name: `session_${currentSession.id}`,
        status: "error",
      })
      setCreateSessionLoading(false)
      return message.error(
        "Cannot book session!!! Please email us at contact@mentro.tech"
      )
    }
  }

  const bookNewSession = async orderId => {
    setCreateSessionLoading(true)
    let sessionStatusInterval
    let apiRepeatCount = 1

    sessionStatusInterval = setInterval(async () => {
      if (apiRepeatCount > 5) {
        setCreateSessionLoading(false)
        setModalDescData({
          successStatus: false,
          message: `Your session is being created. This is taking an unusual longer time. You can rest assured, that your session will be created if the payment was successful. Check back in few minutes under the "Sessions" tab in Home Screen.`,
        })
        questionForm.resetFields()
        setIsModalVisible(true)
        getCalendarEvents(new Date(), currentSession.id)
        logGa("PAYMENT_STATUS", {
          screen_name: `session_${currentSession.id}`,
          status: "pending",
        })
        return clearInterval(sessionStatusInterval)
      }

      apiRepeatCount += 1
      try {
        const bookSessionResp = await getHttpHandler({
          url: `${process.env.GATSBY_BE_URL}/users/getOrderPaymentStatus?orderId=${orderId}`,
          idToken: token,
        })
        if (bookSessionResp?.status === "success") {
          setCreateSessionLoading(false)
          setModalDescData({
            successStatus: true,
            message: "Session Booked Successfully!!!",
          })
          questionForm.resetFields()
          setIsModalVisible(true)
          getCalendarEvents(new Date(), currentSession.id)
          logGa("PAYMENT_STATUS", {
            screen_name: `session_${currentSession.id}`,
            status: "success",
          })
          clearInterval(sessionStatusInterval)
        }
      } catch (error) {
        message.error(
          "Cannot book session!!! Please email us at contact@mentro.tech"
        )
        logGa("PAYMENT_STATUS", {
          screen_name: `session_${currentSession.id}`,
          status: "error",
        })
        apiRepeatCount = 6
        setCreateSessionLoading(false)
      }
    }, 3000)
  }

  const createNewSession = async values => {
    if (!selectedSlot.id) {
      return message.error("Select a Slot")
    }

    let questionAnswers: string = ""
    let gtg = true
    currentSession.questions.map((q, i) => {
      if (!values[`question${i}`] && q.isRequired) return (gtg = false)
      questionAnswers += `Q: ${q.title}\nA: ${values[`question${i}`] || ""}\n\n`
    })

    if (!gtg) return message.error("Please fill all the required questions")

    if (!user.ccPass || !user.ccLogin) {
      return message.error("User not logged in")
    }
    setCreateSessionLoading(true)
    const connectyCube = window.ConnectyCube

    const userCredentials = {
      login: user.ccLogin,
      password: user.ccPass,
    }

    await connectyCube.createSession(userCredentials)
    const newDialog = await connectyCube.chat.dialog.create({
      type: 3,
      occupants_ids: [mentorData.user.ccId],
    })

    if (!newDialog["_id"]) {
      message.error("Session cannot be created.")
    }
    let description = `Hi, I would like to request a ${currentSession.title} session, here are the details you asked for:\n\n${questionAnswers}\nThanks & Regards.`

    try {
      const orderDetail = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/generateOrder`,
        idToken: token,
        body: {
          sessionTypeId: currentSession.id,
          mentorId: mentorData.mentorData.uid,
          promoCode: promoCodeFinal,
          dialogId: newDialog["_id"],
          date: selectedDate.getDate(),
          month: selectedDate.getMonth() + 1,
          eventId: selectedSlot.id,
          year: selectedDate.getFullYear(),
          description,
          from: {
            c: Cookies.get("c") || "",
            ev: Cookies.get("ev") || "",
            cid: Cookies.get("cid") || "",
            device: deviceType,
            os: osName + " " + osVersion,
            browser: browserName + " " + browserVersion,
          },
        },
      })

      if (!orderDetail.id) {
        setCreateSessionLoading(false)
        return message.error("Failed to get order details")
      }
      logGa("ITEM_CLICK", {
        item_name: "proceed_btn",
        screen_name: "payment_sheet",
        amount_value: orderDetail.amount / 100,
        session_type_title: currentSession.title,
      })
      if (orderDetail.amount > 0) {
        const options = {
          key: process.env.GATSBY_RAZORPAY_KEY,
          currency: orderDetail.currency,
          amount: orderDetail.amount,
          name: "Mentro",
          description: "Session Payment",
          image: <img src={MentroLogo} alt="mentroLogo" />,
          order_id: orderDetail.id,
          handler: function (response) {
            bookNewSession(orderDetail.id)
          },
          prefill: {
            name: user.userName,
            email: user?.email,
          },
        }

        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
        setCreateSessionLoading(false)
      } else {
        bookFreeSession(orderDetail.id, newDialog["_id"], description)
      }
    } catch (error) {
      setCreateSessionLoading(false)
      return message.error("Failed to get bill details")
    }
  }

  const slotsRequest = async values => {
    const query = values.query

    if (!query) {
      return message.error("Please enter the required field")
    }

    mixpanelEvent("equest_session_click", {
      mentor_id: mentorData.mentorData.profileId,
    })

    setSlotsRequestLoading(true)
    try {
      const data = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/sendSessionRequest`,
        idToken: token,
        body: {
          mentorId: mentorData.mentorData.uid,
          query,
        },
      })

      noSlotsForm.resetFields()
      setSlotsRequestLoading(false)
      setIsSendRequestModalVisible(true)
    } catch (error) {
      setSlotsRequestLoading(false)
      return message.error("Failed to send request")
    }
  }

  const getLatestPosts = async () => {
    try {
      const latestPosts = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/post/getLatestPosts`,
        idToken: token,
        body: {
          communityId: mentorData.mentorData.uid,
        },
      })
      setLatestPosts(latestPosts.posts.slice(0, NO_OF_POST_TO_SHOW))
    } catch (error) {}
  }

  return (
    <div className="publicViewProfileParentDiv">
      <AuthPopup
        setIsAuthPopupVisible={setIsAuthPopupVisible}
        isAuthPopupVisible={isAuthPopupVisible}
      />
      <Modal
        className="successModal"
        visible={isSendRequestModalVisible}
        onOk={() => {}}
        onCancel={() => setIsSendRequestModalVisible(false)}
        footer={null}
        closable={false}
      >
        <div className="successDiv">
          <div className="top">
            <img src={AppliedSuccess} alt="applied successfully" />
            <Title className="title">Successfully sent your request!!!</Title>
          </div>
        </div>
      </Modal>
      <Modal
        className="successModal"
        visible={isModalVisible}
        onOk={() => {}}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closable={false}
      >
        <div className="successDiv">
          <div className="top">
            {modalDescData.successStatus ? (
              <>
                <img
                  src={AppliedSuccess}
                  alt="applied successfully or failure illustration"
                />
                <Title className="title">{modalDescData.message}</Title>
              </>
            ) : (
              <Title className="text">{modalDescData.message}</Title>
            )}
          </div>
          <Link to="/web/sessions">
            <Button type="text" className="goToSessionBtn">
              Sessions
            </Button>
          </Link>
        </div>
      </Modal>
      {!isBookSession ? (
        <Row className="publicViewProfileRow" gutter={[40, 40]}>
          <Col lg={15} md={24} className="publicViewProfileCol">
            <div className="publicViewProfileDiv">
              <div className="topActionBar">
                <div
                  className="actionDiv"
                  onClick={() => {
                    window.history.length > 1 ? navigate(-1) : navigate("/web/")
                  }}
                >
                  <img src={BackArrow} alt="back icon" />
                  <Text className="text">Back</Text>
                </div>
                <Popconfirm
                  disabled={!mentorData?.mentorData?.profileId}
                  id="sharePopConfirm"
                  icon={null}
                  placement="bottomRight"
                  className="actionDiv"
                  onVisibleChange={visible => {
                    if (!visible) return
                    logGa("ITEM_CLICK", {
                      item_name: "profile_share_btn",
                      profile_id: mentorData?.mentorData?.profileId,
                    })
                  }}
                  title={
                    <ShareButtons
                      url={`Join the Community of ${mentorData?.mentorData?.name} on Mentro Now! 👉 : mentro.tech/@${mentorData?.mentorData.profileId} `}
                    />
                  }
                >
                  <img src={ShareIcon} alt="back icon" />
                  <Text className="text">Share</Text>
                </Popconfirm>
              </div>
              <div className="profilePicture">
                <div className="top">
                  {mentorData ? (
                    <img
                      src={mentorData?.mentorData?.profileUrl || DefaultUser}
                      alt="user"
                      className="profilePic"
                    />
                  ) : (
                    <Skeleton.Avatar
                      size="large"
                      shape="circle"
                      active
                      className="profilePicSkel"
                    />
                  )}
                  <div className="info">
                    <div className="infoDiv">
                      <img src={AverageRating} alt="avgRate" />
                      <Text className="count">
                        {mentorData?.mentorData?.averageRating || (
                          <Skeleton.Button size="small" active />
                        )}
                      </Text>
                      <Title className="infoTitle">Average Rating</Title>
                    </div>
                    <div className="infoDiv">
                      <img
                        src={
                          mentorData?.community?.numMembers
                            ? MemberCount
                            : SessionCompleted
                        }
                        alt="memberCount"
                      />
                      <Text className="count">
                        {mentorData?.mentorData ? (
                          mentorData?.community?.numMembers ||
                          mentorData?.mentorData?.noOfSessionsCompleted
                        ) : (
                          <Skeleton.Button size="small" active />
                        )}
                      </Text>
                      <Title className="infoTitle">
                        {mentorData?.community?.numMembers
                          ? "Members"
                          : "Session Completed"}
                      </Title>
                    </div>
                  </div>
                </div>
                {mentorData && (
                  <span
                    className={`activeStatus ${
                      !mentorData?.mentorData?.isActive && "notActive"
                    }`}
                  >
                    <span className="activeCircle"></span>
                    {mentorData?.mentorData?.isActive
                      ? "Available"
                      : "Not available"}
                  </span>
                )}
                <div className="namesDiv">
                  <Title className="name">
                    {mentorData?.mentorData?.name || (
                      <Skeleton.Button
                        size="small"
                        active
                        className="nameSkel"
                      />
                    )}
                    {mentorData?.mentorData?.isVerified && (
                      <img
                        src={VerifiedTick}
                        alt="VerifiedTick"
                        className="verifiedTick"
                      />
                    )}
                  </Title>
                  <div className="socialDiv">
                    {mentorData ? (
                      <>
                        {mentorData?.mentorData?.linkedInUrl && (
                          <span
                            onClick={() =>
                              window.open(mentorData?.mentorData?.linkedInUrl)
                            }
                            className="socialSpan"
                          >
                            <img src={LinkedinIcon} alt="linkedin" />
                          </span>
                        )}
                        {mentorData?.mentorData?.twitterUrl && (
                          <span
                            className="socialSpan"
                            onClick={() =>
                              window.open(mentorData?.mentorData?.twitterUrl)
                            }
                          >
                            <img src={TwitterIcon} alt="twitter" />
                          </span>
                        )}
                      </>
                    ) : (
                      <Skeleton.Avatar
                        size="small"
                        shape="circle"
                        active
                        className="socialSkel"
                      />
                    )}
                  </div>
                </div>
                <Text className="org">
                  {mentorData ? (
                    `${mentorData?.mentorData?.role} at ${mentorData?.mentorData?.organisation}`
                  ) : (
                    <Skeleton.Button size="small" active className="orgSkel" />
                  )}
                </Text>
                <Text className="profileId">
                  {mentorData && `@${mentorData?.mentorData?.profileId}`}
                </Text>
                <div className="actionBtns">
                  <Button
                    type="text"
                    onClick={() => {
                      if (!mentorData?.mentorData?.uid) return
                      setIsBookSessionCol(true)
                      getSessionData(mentorData?.mentorData?.uid)
                      logGa("ITEM_CLICK", {
                        item_name: "book_session_btn",
                        mentor_id: mentorData?.mentorData?.profileId,
                      })
                      mixpanelEvent("book_session_btn_click", {
                        mentor_id: mentorData?.mentorData?.profileId,
                      })
                      mixpanelEvent("book_session_click", {
                        mentor_id: mentorData?.mentorData?.profileId,
                      })
                      setTimeout(() => {
                        startSessionRef?.current?.scrollIntoView({
                          behavior: "smooth",
                        })
                      }, 500)
                    }}
                    className="actionBtn"
                  >
                    Book Session
                  </Button>
                  <Button
                    type="text"
                    className={`actionBtn whiteActionBtn`}
                    onClick={() => {
                      if (
                        !mentorData?.mentorData?.uid ||
                        (isMentor &&
                          mentorData?.community?.uid === loggedMentorData?.uid)
                      )
                        return
                      if (!isLoggedIn) return setIsAuthPopupVisible(true)
                      if (mentorData?.community?.isMember) {
                        let newMentorData = { ...mentorData }
                        newMentorData.community.isMember = false
                        newMentorData.community.numMembers--
                        setMentorData(newMentorData)
                        joinCommunity(
                          mentorData?.mentorData?.uid,
                          "Leave",
                          false
                        )
                      } else {
                        logGa("ITEM_CLICK", {
                          item_name: "join_mentor_btn",
                          mentor_id: mentorData?.mentorData?.uid,
                        })
                        let newMentorData = { ...mentorData }
                        newMentorData.community.isMember = true
                        newMentorData.community.numMembers++
                        setMentorData(newMentorData)
                        joinCommunity(
                          mentorData?.mentorData?.uid,
                          "Join",
                          false
                        )
                      }
                    }}
                  >
                    {mentorData?.community?.isMember ? "Member" : "+ Join"}
                  </Button>
                </div>
              </div>
              <div className="interestsDiv">
                <Title className="title">Interests</Title>
                {mentorData ? (
                  mentorData?.mentorData?.interests?.map(intr => (
                    <span className="interest" key={intr}>
                      {interestList?.find(intrs => intrs.value === intr)
                        ?.label || intr}
                    </span>
                  ))
                ) : (
                  <>
                    <Skeleton.Button size="small" active className="intrSkel" />
                    <Skeleton.Button size="small" active className="intrSkel" />
                    <Skeleton.Button size="small" active className="intrSkel" />
                  </>
                )}
              </div>
              {mentorData?.mentorData?.programs?.length > 0 && (
                <div className="programsDiv">
                  <Title className="title">Programs</Title>
                  <div className="bannersDiv">
                    {mentorData?.mentorData?.programs?.map(prog => (
                      <img
                        onClick={() => {
                          logGa("ITEM_CLICK", {
                            item_name: "mentor_program",
                            mentor_id: mentorData?.mentorData?.uid,
                          })
                          navigate(`/web/programs/${prog?.programId}`)
                        }}
                        src={prog?.banner}
                        alt="program logo"
                        className="programImg"
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className="bioDiv">
                <Title className="title">Bio</Title>
                <Text className="text">
                  {mentorData ? (
                    linkify(mentorData?.mentorData?.bio)
                  ) : (
                    <>
                      <Skeleton.Button
                        size="small"
                        active
                        className="bioSkel1"
                      />
                      <Skeleton.Button
                        size="small"
                        active
                        className="bioSkel2"
                      />
                      <Skeleton.Button
                        size="small"
                        active
                        className="bioSkel3"
                      />
                    </>
                  )}
                </Text>
                {mentorData?.mentorData?.quote && (
                  <Title className="quote">
                    "{mentorData?.mentorData?.quote}" ~{" "}
                    {mentorData?.mentorData?.name}
                  </Title>
                )}
              </div>
              {latestPosts?.length > 0 && (
                <div className="communityPostsDiv">
                  <Title className="title">Community Posts</Title>
                  {latestPosts?.map(post => (
                    <div
                      key={post?.postId}
                      className="postBody"
                      onClick={() =>
                        navigate(`/web/post/${post?.postId}`, {
                          state: { post },
                        })
                      }
                    >
                      {post?.postContent &&
                        post?.postContent
                          ?.slice(0, PREVIEW_ITEMS_COUNT)
                          ?.map((postData, ind) => {
                            const PostModel =
                              communityPostModelDataTypes[postData.type]
                            return (
                              PostModel && (
                                <PostModel
                                  key={ind}
                                  isReadMoreClicked={true}
                                  communityPostId={post?.postId}
                                  {...postData}
                                />
                              )
                            )
                          })}
                    </div>
                  ))}
                  <div className="visitCommBtnDiv">
                    <Button
                      type="text"
                      onClick={() =>
                        navigate(
                          `/web/community/${mentorData?.mentorData?.uid}`,
                          {
                            state: { community: mentorData?.community },
                          }
                        )
                      }
                      className="visitCommBtn"
                    >
                      Visit Community
                    </Button>
                  </div>
                </div>
              )}
              {mentorData && mentorData?.mentorData?.reviews.length > 0 && (
                <div className="reviewsDiv">
                  <Title className="title">Reviews</Title>
                  {mentorData.mentorData.reviews.map(review => (
                    <div className="review" key={review.id}>
                      <div className="topReview">
                        <img
                          src={review.imageUrl ? review.imageUrl : DefaultUser}
                          alt="user"
                          className="reviewImg"
                        />
                        <div className="rightSec">
                          <Title className="reviewName">
                            {review.menteeName}
                          </Title>
                          <div className="rating">
                            {Array.from(Array(Math.ceil(review.rating))).map(
                              (r, i) => (
                                <img
                                  key={i}
                                  src={GoldenStar}
                                  alt="rating star"
                                  className="ratingStar"
                                />
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <Title className="reviewText">{review.review}</Title>
                    </div>
                  ))}
                  {reviewMore && mentorData?.mentorData?.reviews.length > 0 && (
                    <div className="sep">
                      <Button
                        className="showMore"
                        onClick={showMoreReview}
                        type="text"
                      >
                        Show More <img src={DownArrow} alt="downArrow" />
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
          {isBookSessionCol && (
            <Col
              lg={9}
              md={24}
              ref={startSessionRef}
              className="publicViewProfileCol"
            >
              <div className="rightPanel" id="chooseSession">
                <Title className="chooseSessionTitle">
                  Choose Your Session
                </Title>
                <Row className="sessionsRow">
                  {sessionList.length > 0
                    ? sessionList.map(sessionData => (
                        <Col
                          className="sessionBox"
                          onClick={() => {
                            logGa("ITEM_CLICK", {
                              item_name: `choose_session_${sessionData.title}_${mentorData?.mentorData?.profileId}`,
                              screen_name: "choose_session",
                            })

                            mixpanelEvent("session_select_click", {
                              mentor_id: mentorData?.mentorData?.profileId,
                              session_name: sessionData.title,
                            })

                            bookSession(sessionData)
                          }}
                          key={sessionData.id}
                        >
                          <div className="content">
                            <span className="iconBg">
                              <img
                                src={getSessionIcon(sessionData.title)}
                                alt="session icon"
                              />
                            </span>
                            <div className="sessionInfo">
                              <Title className="sessionTitle">
                                {sessionData.title}
                              </Title>
                              <Title className="costTitle">
                                ₹{sessionData.amount}
                              </Title>
                            </div>
                          </div>
                          <img
                            src={RightArrow}
                            alt="rightArrow"
                            className="rightArrow"
                          />
                        </Col>
                      ))
                    : Array.from(Array(3)).map((item, ind) => (
                        <Col className="sessionBox" key={ind}>
                          <div className="content">
                            <Skeleton.Avatar
                              className="leftIconSkel"
                              active
                              shape="square"
                            />
                            <div className="sessionInfo">
                              <Skeleton.Button
                                size="small"
                                active
                                className="sSkel1"
                              />
                              <Skeleton.Button
                                size="small"
                                active
                                className="sSkel2"
                              />
                            </div>
                          </div>
                          <Skeleton.Avatar active />
                        </Col>
                      ))}
                </Row>
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <Row className="bookSessionRow" gutter={[40, 40]}>
          {dataReceived &&
            (calendarEvents && Object.keys(calendarEvents).length > 0 ? (
              <>
                <Col lg={15} className="bookSessionCol">
                  <div className="leftSection">
                    <div
                      className="back"
                      onClick={() => {
                        setCurrentSession(null)
                        setCalendarEvents(null)
                        setPricingData(null)
                        setPromoCode("")
                        setPromoCodeFinal("")
                        setSelectedDate(new Date())
                        setSelectedSlot({
                          start: null,
                          end: null,
                          id: "",
                        })
                        questionForm.resetFields()
                        setCurrentSession(null)
                        setCreateSessionLoading(false)
                        setIsBookSession(false)
                      }}
                    >
                      <img src={BackArrow} alt="back icon" />
                      <Text className="backText">Back</Text>
                    </div>
                    <div className="sessionInfo">
                      <Title className="sessionTitle">
                        {currentSession.title}
                      </Title>
                      <div className="sessionDesc">
                        <Title className="sessionQuestion">
                          What is {currentSession.title}?
                        </Title>
                        <Title className="sessionDescText">
                          {linkify(currentSession.description)}
                        </Title>
                      </div>
                    </div>
                    <div className="questionFormDiv">
                      <Form
                        form={questionForm}
                        name="basic"
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={createNewSession}
                        onFinishFailed={error => {}}
                        autoComplete="off"
                      >
                        {currentSession.questions.map((quest, ind) => (
                          <Form.Item
                            key={ind}
                            label={
                              <Text className="questionLabel">
                                {quest.title}
                              </Text>
                            }
                            name={`question${ind}`}
                            rules={[
                              {
                                required: quest.isRequired,
                                message: (
                                  <Text className="errorMessage">
                                    <img
                                      src={ErrorMessage}
                                      alt="errorMessage"
                                    />
                                    This field is required
                                  </Text>
                                ),
                              },
                            ]}
                          >
                            <Input placeholder={quest.placeholder} />
                          </Form.Item>
                        ))}
                      </Form>
                    </div>
                    <div className="calendarEvents">
                      <Title className="selectTimeText">Select Time</Title>
                      <Title className="monthText">
                        <img
                          src={LeftArrow}
                          alt="leftArrow"
                          className={`${
                            selectedDate >= new Date() || "disableArrow"
                          }`}
                          onClick={() => {
                            let nDate = new Date()
                            if (selectedDate >= nDate) {
                              let newDate = moment(selectedDate)
                                .subtract(1, "months")
                                .toDate()
                              if (newDate.getMonth() === nDate.getMonth()) {
                                newDate = new Date(
                                  newDate.setDate(nDate.getDate())
                                )
                              } else {
                                newDate = new Date(newDate.setDate(1))
                              }
                              setSelectedDate(newDate)
                              getCalendarEvents(newDate, currentSession.id)
                            }
                          }}
                        />

                        {`${
                          monthList[selectedDate.getMonth()]
                        } - ${selectedDate.getFullYear()}`}
                        <img
                          src={RightArrow}
                          alt="rightArrow"
                          className="rightArrow"
                          onClick={() => {
                            let nDate = new Date()
                            let newDate = moment(selectedDate)
                              .add(1, "M")
                              .toDate()
                            if (newDate.getMonth() === nDate.getMonth()) {
                              newDate = new Date(
                                newDate.setDate(nDate.getDate())
                              )
                            } else {
                              newDate = new Date(newDate.setDate(1))
                            }
                            setSelectedDate(newDate)
                            getCalendarEvents(newDate, currentSession.id)
                          }}
                        />
                      </Title>
                      {calendarEvents &&
                        dataReceived &&
                        Object.keys(calendarEvents).length > 0 && (
                          <div className="days">
                            {Object.keys(calendarEvents).map((day, ind) => (
                              <div
                                key={ind}
                                className={`day ${
                                  parseInt(day) === selectedDate.getDate() &&
                                  "activeDate"
                                } ${
                                  !calendarEvents[day].isAvailable &&
                                  "dayNotAvailable"
                                }`}
                                onClick={() => {
                                  let newDate = new Date(
                                    selectedDate.setDate(parseInt(day))
                                  )
                                  setSelectedDate(newDate)

                                  let availableSlot: any = calendarEvents[
                                    day
                                  ].slots.filter((e: any) => e.isAvailable)

                                  if (availableSlot.length > 0) {
                                    setSelectedSlot({
                                      start: availableSlot[0].start,
                                      end: availableSlot[0].end,
                                      id: availableSlot[0].id,
                                    })
                                  } else {
                                    setSelectedSlot({
                                      start: null,
                                      end: null,
                                      id: "",
                                    })
                                  }
                                }}
                              >
                                <Title className="dayText">
                                  {
                                    daysList[
                                      new Date(
                                        `${selectedDate.getFullYear()}-${
                                          selectedDate.getMonth() + 1
                                        }-${day}`
                                      ).getDay()
                                    ]
                                  }
                                </Title>
                                <Title className="dateText">{day}</Title>
                              </div>
                            ))}
                          </div>
                        )}
                      <Row className="timeRow" gutter={[10, 10]}>
                        {calendarEvents &&
                          calendarEvents[selectedDate.getDate()]?.slots?.map(
                            (timeSlot, ind) => (
                              <Col
                                key={ind}
                                lg={8}
                                xs={12}
                                className={`slotCol ${
                                  !timeSlot.isAvailable && "isNotAvailable"
                                }`}
                                onClick={() => {
                                  if (timeSlot.isAvailable) {
                                    setSelectedSlot({
                                      start: timeSlot.start,
                                      end: timeSlot.end,
                                      id: timeSlot.id,
                                    })
                                  }
                                }}
                              >
                                <Title
                                  className={`slotText ${
                                    selectedSlot.start === timeSlot.start &&
                                    selectedSlot.end === timeSlot.end &&
                                    "activeSlot"
                                  }`}
                                >{`${getFormattedTime(
                                  timeSlot.start
                                )} to ${getFormattedTime(
                                  timeSlot.end
                                )}`}</Title>
                              </Col>
                            )
                          )}
                        {!loading &&
                          calendarEvents &&
                          Object.keys(calendarEvents).length < 1 && (
                            <Title
                              className="selectTimeText"
                              style={{
                                textAlign: "center",
                                width: "100%",
                                fontSize: "1.8em",
                              }}
                            >
                              No Slots Available
                            </Title>
                          )}
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col lg={9} className="bookSessionCol">
                  <div className="rightSection">
                    <PaymentCard
                      bookingTitle="Session"
                      gistData={
                        <Title className="gist2">
                          Schedule your <span>{currentSession.title}</span> with{" "}
                          <span>{mentorData.mentorData.name}</span> on{" "}
                          <span>
                            {selectedDate.getDate()}/
                            {selectedDate.getMonth() + 1}/
                            {selectedDate.getFullYear()}
                          </span>{" "}
                          {selectedSlot.start && "at"}{" "}
                          <span>
                            {selectedSlot.start &&
                              getFormattedTime(selectedSlot.start)}
                          </span>
                          .
                        </Title>
                      }
                      amount={currentSession?.amount}
                      pricingData={pricingData}
                      promoCode={promoCode}
                      setPromoCode={setPromoCode}
                      applyPromoCode={() => getPricingData(currentSession)}
                      makePayment={() =>
                        createNewSession(questionForm.getFieldsValue())
                      }
                      loadingPaymentBtn={createSessionLoading}
                      paymentBtnText="Start Session"
                    />
                  </div>
                </Col>
              </>
            ) : (
              <Col lg={24} className="bookSessionCol">
                <div className="leftSection">
                  <div
                    className="back"
                    onClick={() => {
                      setCurrentSession(null)
                      setCalendarEvents(null)
                      setPricingData(null)
                      setPromoCode("")
                      setPromoCodeFinal("")
                      setSelectedDate(new Date())
                      setSelectedSlot({
                        start: null,
                        end: null,
                        id: "",
                      })
                      questionForm.resetFields()
                      setCurrentSession(null)
                      setCreateSessionLoading(false)
                      setIsBookSession(false)
                    }}
                  >
                    <img src={BackArrow} alt="back icon" />
                    <Text className="backText">Back</Text>
                  </div>
                  <div className="questionFormDiv">
                    <Form
                      form={noSlotsForm}
                      name="basic"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={slotsRequest}
                      onFinishFailed={error => {}}
                      autoComplete="off"
                    >
                      <Form.Item
                        label={
                          <Text className="questionLabel">
                            Describe your Session Request
                          </Text>
                        }
                        name="query"
                        rules={[
                          {
                            required: true,
                            message: (
                              <Text className="errorMessage">
                                <img src={ErrorMessage} alt="errorMessage" />
                                This field is required
                              </Text>
                            ),
                          },
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder="I'm unable to understand the problem behind the Knapsack problem..."
                        />
                      </Form.Item>
                      <Button
                        type="text"
                        className="sendRequestBtn"
                        htmlType="submit"
                        loading={slotsRequestLoading}
                      >
                        Send Request
                      </Button>
                    </Form>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      )}
    </div>
  )
}

export default PublicProfile
