import React, { useCallback, useContext } from "react"
import "./CommunityData.less"
import { Button, message, Popconfirm, Skeleton, Typography } from "antd"
// import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
//import { CommunityContext } from "../../../context/CommunityContext"
import ShareButtons from "../../ShareButtons"
import Guest from "../../../assets/mentroWebApp/defaultUser.svg"
import ShareArrow from "../../../assets/mentroWebApp/shareArrow.svg"
import { navigate } from "gatsby"
import { logGa } from "../../../../services/analytics"
import useCommunityData from "../../ZustandStateManagment/Communities/store"
const { Title } = Typography

const MIN_MEMBERS = 0
const MIN_POSTS = 1

const CommunityData = ({
  screenName = "post_share_btn",
  postId = "",
  currentCommunity,
  loadingCommunity,
  position,
  setPosition,
  setIsAuthPopupVisible,
  setisCreatePostVisible,
}) => {
  const { isLoggedIn, mentor, mentorData } = useLoggedInUserStore()
  const { joinCommunity } = useCommunityData()

  const createPostEligibility = useCallback(() => {
    return mentor && currentCommunity?.uid === mentorData?.uid
  }, [currentCommunity])

  return (
    <div className="communityDataDiv">
      <div
        className="communityInfoDiv"
        onClick={() =>
          navigate(`/web/community/${currentCommunity?.uid}`, {
            state: { community: currentCommunity },
          })
        }
      >
        <img
          src={currentCommunity?.image || Guest}
          alt="community"
          className="communityImg"
        />
        {currentCommunity?.uid ? (
          <Title className="communityName">{currentCommunity?.name}</Title>
        ) : (
          <Skeleton.Button active size="small" className="dataSkeleton" />
        )}
        {currentCommunity?.uid ? (
          <Title className="communityHeadline">
            {currentCommunity?.headline}
          </Title>
        ) : (
          <Skeleton.Button active size="small" className="dataSkeleton" />
        )}
        <div className="commStatsDiv">
          {currentCommunity?.numPosts >= MIN_POSTS && (
            <Title className="communityStats">
              {currentCommunity?.numPosts}
              <br /> Posts
            </Title>
          )}
          {currentCommunity?.numMembers >= MIN_MEMBERS && (
            <Title className="communityStats">
              {currentCommunity?.numMembers}
              <br /> Members
            </Title>
          )}
        </div>
      </div>
      <div className="btnsDiv">
        <Button
          type="text"
          className={`${position ? "memberBtn" : "joinBtn"}`}
          onClick={() => {
            if (
              loadingCommunity ||
              !currentCommunity?.uid ||
              (mentor && currentCommunity?.uid === mentorData?.uid)
            )
              return
            if (!isLoggedIn) return setIsAuthPopupVisible(true)
            if (position) {
              setPosition("")
              joinCommunity(currentCommunity?.uid, "Leave", false)
            } else {
              setPosition("Member")
              joinCommunity(currentCommunity?.uid, "Join", false)
            }
          }}
        >
          {position || "+ Join"}
        </Button>
        {createPostEligibility() && (
          <Button
            type="text"
            className="createBtn"
            onClick={() => {
              logGa(`create_post_btn_${mentorData?.uid}`)
              setisCreatePostVisible(true)
            }}
          >
            +
          </Button>
        )}
        <Popconfirm
          disabled={!currentCommunity?.uid}
          id="sharePopConfirm"
          icon={null}
          placement="bottom"
          onVisibleChange={visible => {
            if (!visible) return
            if (screenName === "post_share_btn") {
              logGa(
                `post_share_btn_${postId}_${
                  isLoggedIn ? (mentor ? "mentor" : "learner") : "guest"
                }`
              )
            } else {
              logGa(
                `community_share_btn_${currentCommunity?.uid}_${
                  isLoggedIn ? (mentor ? "mentor" : "learner") : "guest"
                }`
              )
            }
          }}
          title={
            <ShareButtons
              url={`${process.env.GATSBY_HOME_URL}/community/${currentCommunity?.uid}`}
            />
          }
        >
          <Button type="text" className="shareBtn">
            <img src={ShareArrow} alt="shareArrow" />
          </Button>
        </Popconfirm>
      </div>
    </div>
  )
}

export default CommunityData
