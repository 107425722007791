import React, { useEffect } from "react"
import Seo from "../components/seo"
import Container from "../web/components/Container"
import "../theme/web.less"
import { useAuth } from "../web/hooks/useAuth"
import { AuthContext } from "../web/context/AuthContext"

const IndexWebPage = () => {
  const { mentor, mentorData, token, user, login, logout, mentorCommunities } =
    useAuth()
  // useEffect(() => {
  //   //TAWK.to
  //   var Tawk_API = Tawk_API || {}

  //   var s1: any = document.createElement("script")
  //   var s0: any = document.getElementsByTagName("script")[0]
  //   s1.async = true
  //   s1.src = "https://embed.tawk.to/622f1fada34c2456412af169/1fu417g0k"
  //   s1.charset = "UTF-8"
  //   s1.setAttribute("crossorigin", "*")
  //   s0.parentNode.insertBefore(s1, s0)
  // }, [])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        mentor,
        mentorData,
        mentorCommunities,
        user,
        login,
        logout,
      }}
    >
      <Seo title="Mentro: Learn with Mentors" />
      <Container />
    </AuthContext.Provider>
  )
}

export default IndexWebPage
