import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Col, Row, Typography } from "antd"
import OpportunityCard from "./OpportunityCard"
import OpportunityCardSkeleton from "../../Skeletons/OpportunityCardSkeleton"
import LoaderIll from "../../../assets/mentroWebApp/loaderIll.svg"
import "./Opportunities.less"
import { JobsContext } from "../../../context/JobsContext"
import {
  logGa,
  mixpanelEvent,
  mixpanelTimeEvent,
} from "../../../../services/analytics"
import JobsPopup from "../../Popups/JobsPopup"
import AuthPopup from "../../Popups/AuthPopup"
import useJobsData from "../../ZustandStateManagment/Jobs/store"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
const { Title } = Typography
const jobsPerRow = 3
const jobsPerRowMobView = 2

const Opportunities = () => {
  const { isLoggedIn, mentor } = useLoggedInUserStore()

  const [isAuthPopupVisible, setIsAuthPopupVisible] = useState<boolean>(false)
  const [isJobsVisible, setIsJobsVisible] = useState<boolean>(false)
  // const {
  //   jobOpportunities,
  //   setJobOpportunities,
  //   setError,
  //   loading,
  //   setLoading,
  //   hasMoreJobs,
  //   setHasMoreJobs,
  //   docId,
  //   setDocId,
  //   dataReceived,
  //   setDataReceived,
  //   companyType,
  //   roleType,
  // } = useContext(JobsContext)

  const {
    jobOpportunities,
    setJobOpportunities,
    setError,
    loading,
    setLoading,
    hasMoreJobs,
    setHasMoreJobs,
    docId,
    setDocId,
    dataReceived,
    setDataReceived,
    companyType,
    roleType,
  } = useJobsData()
  const getJobs = async () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    setLoading(true)
    setJobOpportunities([])
    setDataReceived(false)
    setError(false)
    fetch(
      `${
        process.env.GATSBY_BE_URL
      }/users/get-jobs?companyType=${companyType}&roleType=${roleType}&numJobs=${12}`
    )
      .then(res => res.json())
      .then((responseData: { data: any; message: any }) => {
        if (responseData.message === "Success") {
          setJobOpportunities(responseData.data)
          setDataReceived(true)
          setHasMoreJobs(responseData.data.length > 0)
          setError(false)
        }
      })
      .catch(err => {
        setError(true)
        setDataReceived(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getNextJobs = async () => {
    if (!hasMoreJobs || !docId) {
      return
    }

    setLoading(true)
    setError(false)
    fetch(
      `${
        process.env.GATSBY_BE_URL
      }/users/get-jobs?companyType=${companyType}&roleType=${roleType}&docId=${docId}&numJobs=${12}`
    )
      .then(res => res.json())
      .then((responseData: { data: any; message: any }) => {
        if (responseData.message === "Success") {
          const totalJobs = [...jobOpportunities, ...responseData.data]
          setJobOpportunities(totalJobs)
          setHasMoreJobs(responseData.data.length > 0)
          setError(false)
        }
      })
      .catch(err => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getNextJobs()
  }, [docId])

  useEffect(() => {
    getJobs()
  }, [companyType, roleType])

  useEffect(() => {
    setTimeout(() => {
      logGa("POPUP_SHOWN", {
        popup_type: "job_funnel_popup",
      })
      setIsJobsVisible(true)
    }, 5000)
    logGa("PAGE_LANDING", { screen_name: "job_list" })
    mixpanelTimeEvent("page_view_jobs_list")

    return () => {
      mixpanelEvent("page_view_jobs_list", {
        screen_name: "jobs_list",
        user_type: isLoggedIn ? (mentor ? "mentor" : "student") : "guest",
      })
    }
  }, [])

  // -----------------------------------------------------------------
  // INFINITE SCROLLING-----------------------------------------------------------------
  // -----------------------------------------------------------------
  const observer = useRef<any>()
  const lastJobOppElement = useCallback(
    ele => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasMoreJobs) {
            if (
              typeof window !== "undefined" &&
              window.innerWidth >= 768 &&
              jobOpportunities.length % jobsPerRow !== 0
            ) {
              return
            } else if (
              typeof window !== "undefined" &&
              window.innerWidth < 768 &&
              jobOpportunities.length % jobsPerRowMobView !== 0
            ) {
              return
            }
            setDocId(jobOpportunities[jobOpportunities.length - 1].id)
          }
        },
        {
          root: null,
          rootMargin: "40px",
          threshold: 0,
        }
      )
      if (ele) observer.current.observe(ele)
    },
    [loading, hasMoreJobs, jobOpportunities]
  )

  const showLoader = () => {
    return Array.from(Array(jobsPerRow)).map((item, ind) => (
      <Col
        lg={8}
        md={12}
        xs={12}
        className="oppSkelCardCol desktopSkel"
        key={ind}
      >
        <OpportunityCardSkeleton />
      </Col>
    ))
  }

  const showMobileLoader = () => {
    return Array.from(Array(jobsPerRowMobView)).map((item, ind) => (
      <Col xs={12} className="oppSkelCardCol mobileSkel" key={ind}>
        <OpportunityCardSkeleton />
      </Col>
    ))
  }

  return (
    <div className="oppDiv">
      <AuthPopup
        setIsAuthPopupVisible={setIsAuthPopupVisible}
        isAuthPopupVisible={isAuthPopupVisible}
      />
      <JobsPopup
        isJobsVisible={isJobsVisible}
        setIsJobsVisible={setIsJobsVisible}
      />
      <Title className="oppTitle">Job Opportunites</Title>
      <Row className="oppRow">
        {jobOpportunities.length > 0 ? (
          <>
            {jobOpportunities &&
              jobOpportunities.map((jobOpp, index) => (
                <Col lg={8} xs={12} key={index} className="oppCardCol">
                  <OpportunityCard
                    jobOpp={jobOpp}
                    setIsAuthPopupVisible={setIsAuthPopupVisible}
                  />
                </Col>
              ))}
            {loading && hasMoreJobs && (
              <>
                {showLoader()}
                {showMobileLoader()}
              </>
            )}
            <div ref={lastJobOppElement} />
          </>
        ) : (
          <div className="loaderSection">
            {dataReceived && jobOpportunities.length === 0 ? (
              <div className="noJobs">
                <img src={LoaderIll} />
                <Title className="noJobsText">No Jobs Found</Title>
              </div>
            ) : (
              <>
                {showLoader()}
                {showMobileLoader()}
              </>
            )}
          </div>
        )}
      </Row>
    </div>
  )
}

export default Opportunities
