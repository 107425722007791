import React, { useContext, useEffect, useState } from "react"
import "./Calendar.less"
import {
  Button,
  Col,
  Calendar as CalendarAntd,
  Select,
  Form,
  Modal,
  Radio,
  Row,
  TimePicker,
  Typography,
  message,
} from "antd"
import { CloseCircleTwoTone } from "@ant-design/icons"
import Meet from "../../../assets/mentroWebApp/meet.svg"
import Clock from "../../../assets/mentroWebApp/clock.svg"
import BackArrow from "../../../assets/mentroWebApp/backArrow.svg"
import DeleteSlots from "../../../assets/mentroWebApp/deleteSlots.svg"
import { useCalendar } from "../../../hooks/useCalendar"
import {
  daysList,
  defaultSlots,
  getCurrentDate,
  getFormattedTime,
  getNewDate,
  getTimeNumber,
} from "./calendarData"
import { navigate } from "gatsby"
import {
  deleteHttpHandler,
  getHttpHandler,
  patchHttpHandler,
  postHttpHandler,
} from "../../../utils/httpHandler"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import moment from "moment"
import { linkify, monthList } from "../../../utils/data"

const { Title, Text } = Typography
const slotRowHeight = 160
const minsCountPerHour = 60
const MILISECONDS = 60000

const Calendar = () => {
  const {
    events,
    dayName,
    setDayName,
    day,
    setDay,
    month,
    setMonth,
    year,
    setYear,
    setAllEvents,
    currentEvents,
    getEvents,
  } = useCalendar()
  const [currentEvent, setCurrentEvent] = useState<any>(null)
  const [prevSlots, setPrevSlots] = useState<any>(null)
  const { token } = useLoggedInUserStore()
  const [isSlotsLoading, setIsSlotsLoading] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [createSlotsMode, setCreateSlotsMode] = useState<string>("multiple")
  const [isRepeat, setIsRepeat] = useState<boolean>(false)
  const [isDeletingSlot, setIsDeletingSlot] = useState<{
    loading: boolean
    id: string
  }>({ loading: false, id: "" })
  const [newSlotsForm] = Form.useForm()

  const getSlot = (slot: any) => {
    return currentEvents.map((event, ind) => {
      let eventStartTime = parseInt(
        getNewDate(event.start).toTimeString().substring(0, 2)
      )
      if (eventStartTime === slot.startTime) {
        return (
          <div
            key={event.id}
            className="parentSessionSlotDiv"
            style={{
              position: "absolute",
              top: `${
                getTimeNumber(event.start) * (slotRowHeight / minsCountPerHour)
              }px`,
              height: `${
                ((getNewDate(event.end).getTime() -
                  getNewDate(event.start).getTime()) /
                  MILISECONDS) *
                (slotRowHeight / minsCountPerHour)
              }px`,
              zIndex: ind,
            }}
          >
            <div
              className={`sessionSlotDiv ${
                event.isAvailable ? "graySlot" : "greenSlot"
              } `}
              style={{
                height: `${
                  ((getNewDate(event.end).getTime() -
                    getNewDate(event.start).getTime()) /
                    MILISECONDS) *
                  (slotRowHeight / minsCountPerHour)
                }px`,
              }}
              onClick={() => setCurrentEvent(event)}
            >
              <div className="content">
                <Title className="sessionTypeText">{event.title}</Title>
                <Title className="timeText">
                  {getFormattedTime(event?.start)} to{" "}
                  {getFormattedTime(event?.end)}
                </Title>
              </div>
              {event.isAvailable && (
                <Button
                  type="text"
                  onClick={() => deleteSlot(event.id)}
                  style={{ zIndex: ind + 2 }}
                  className="deleteSlotBtn"
                  loading={
                    isDeletingSlot.id === event.id && isDeletingSlot.loading
                  }
                >
                  {isDeletingSlot.id === event.id &&
                  isDeletingSlot.loading ? null : (
                    <svg
                      width="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="removeIcon"
                        d="M17 6H22V8H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V8H2V6H7V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V6ZM18 8H6V20H18V8ZM13.414 14L15.182 15.768L13.768 17.182L12 15.414L10.232 17.182L8.818 15.768L10.586 14L8.818 12.232L10.232 10.818L12 12.586L13.768 10.818L15.182 12.232L13.414 14ZM9 4V6H15V4H9Z"
                        fill="#424259"
                        fill-opacity="0.64"
                      />
                    </svg>
                  )}
                </Button>
              )}
            </div>
          </div>
        )
      }
    })
  }

  const deleteSlot = async eventId => {
    setIsDeletingSlot({ id: eventId, loading: true })
    try {
      const deleteSlotResp = await deleteHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/calendar/deleteEvent?eventId=${eventId}&month=${month}&date=${day}&year=${year}`,
        idToken: token,
      })

      setIsDeletingSlot({ ...isDeletingSlot, loading: false })
      if (deleteSlotResp.success) {
        getEvents()
        return message.success("DELETED Slot")
      } else {
        return message.error("Unable to delete the slot")
      }
    } catch (error) {
      message.error("Something went wrong")
      setIsDeletingSlot({ ...isDeletingSlot, loading: false })
    }
  }

  const getPrevSlotTimes = async (mode: any = createSlotsMode) => {
    try {
      const getSlotsResp = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/calendar/getSlots`,
        idToken: token,
      })
      setPrevSlots(getSlotsResp.slots)
      setPrevSlotsToPicker(getSlotsResp.slots, mode)
    } catch (error) {
      message.error("Something went wrong")
    }
  }

  const singleSlotSubmit = async (values: any) => {
    setIsSlotsLoading(true)
    let finalSlots: any = []
    values.singleTimeSlots.map(({ time }) => {
      let st = getNewDate(time[0]).toTimeString().substring(0, 5)
      let et = getNewDate(time[1]).toTimeString().substring(0, 5)
      finalSlots.push(`${st}-${et}`)
    })

    const body = {
      tzoffset: "+0530",
      slot: finalSlots,
      date: day,
      month: month,
      year: year,
    }

    try {
      const addSlotResp = await patchHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/calendar/editSingleSlot`,
        body,
        idToken: token,
      })

      if (addSlotResp.success) {
        getEvents()
        setCreateSlotsMode("")
        setIsModalVisible(false)
        setIsSlotsLoading(false)
        newSlotsForm.resetFields()
        return message.success("Created Slot")
      } else {
        setIsSlotsLoading(false)
        return message.error("Unable to create the slot")
      }
    } catch (error) {
      message.error("Something went wrong")
      setCreateSlotsMode("")
      setIsSlotsLoading(false)
      setIsModalVisible(false)
    }
  }

  const multipleSlotSubmit = async (values: any) => {
    setIsSlotsLoading(true)
    let repeatSlots: any = []
    let finalSlots = {
      sun: [],
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
    }

    if (isRepeat) {
      values.multipleTimeSlots.map(({ time }) => {
        if (time && time[0]?.isValid() && time[1]?.isValid()) {
          let st = getNewDate(time[0]).toTimeString().substring(0, 5)
          let et = getNewDate(time[1]).toTimeString().substring(0, 5)
          repeatSlots.push(`${st}-${et}`)
        }
      })
      if (repeatSlots.length === 0) {
        setIsSlotsLoading(false)
        return message.error("Please enter atleast one slot")
      }
    } else {
      daysList.map(day => {
        let dayArr: any = []
        if (values[day.key]) {
          values[day.key].map(({ time }) => {
            if (time && time[0]?.isValid() && time[1]?.isValid()) {
              let st = getNewDate(time[0]).toTimeString().substring(0, 5)
              let et = getNewDate(time[1]).toTimeString().substring(0, 5)
              dayArr.push(`${st}-${et}`)
            }
          })
        }
        finalSlots[day.key] = dayArr
      })
    }

    const body = {
      tzoffset: "+0530",
      slots: isRepeat
        ? {
            sun: repeatSlots,
            mon: repeatSlots,
            tue: repeatSlots,
            wed: repeatSlots,
            thu: repeatSlots,
            fri: repeatSlots,
            sat: repeatSlots,
          }
        : finalSlots,
      month: month,
      year: year,
    }

    try {
      const createSlotResp = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/calendar/createSlots`,
        body,
        idToken: token,
      })

      if (createSlotResp.success) {
        getEvents()
        setCreateSlotsMode("")
        setIsModalVisible(false)
        setIsSlotsLoading(false)
        newSlotsForm.resetFields()
        return message.success("Created Multiple Slots")
      } else {
        setIsSlotsLoading(false)
        return message.error("Unable to create multiple the slots")
      }
    } catch (error) {
      message.error("Something went wrong")
      setCreateSlotsMode("")
      setIsSlotsLoading(false)
      setIsModalVisible(false)
    }
    setIsSlotsLoading(false)
  }

  const getTimePicker = (mode, isRequired) => {
    return (
      <Form.List
        name={mode}
        initialValue={
          ["multipleTimeSlots", "singleTimeSlots"].includes(mode) || isRepeat
            ? [{ time: [moment("09:00", "HH:mm"), moment("21:00", "HH:mm")] }]
            : prevSlots?.[mode]?.map((s, i) => ({
                time: [
                  moment(s.split("-")[0], "HH:mm"),
                  moment(s.split("-")[1], "HH:mm"),
                ],
              }))
        }
      >
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, ind) => {
                return (
                  <Row className="getTimeRow" key={key}>
                    <Form.Item
                      {...restField}
                      fieldKey={[fieldKey, "time"]}
                      name={[name, "time"]}
                      rules={[
                        {
                          required: isRequired,
                          message: "Select a time",
                        },
                      ]}
                      initialValue={
                        ["multipleTimeSlots", "singleTimeSlots"].includes(
                          mode
                        ) || isRepeat
                          ? ""
                          : prevSlots?.[mode]?.[ind]
                          ? [
                              moment(
                                prevSlots?.[mode]?.[ind]?.split("-")[0],
                                "HH:mm"
                              ),
                              moment(
                                prevSlots?.[mode]?.[ind]?.split("-")[1] || "",
                                "HH:mm"
                              ),
                            ]
                          : ""
                      }
                    >
                      <TimePicker.RangePicker
                        minuteStep={15}
                        format={"HH:mm"}
                        placeholder={["Start Time", "End Time"]}
                        className="timePicker"
                        suffixIcon={
                          <CloseCircleTwoTone twoToneColor="#4caf50" />
                        }
                        clearIcon={
                          <CloseCircleTwoTone twoToneColor="#4caf50" />
                        }
                      />
                    </Form.Item>
                    {ind + 1 !== fields.length ? (
                      <div className="deleteSlots" onClick={() => remove(name)}>
                        <img src={DeleteSlots} alt="delete" />
                      </div>
                    ) : (
                      <Button type="dashed" onClick={() => add(moment())} block>
                        +
                      </Button>
                    )}
                  </Row>
                )
              })}
            </>
          )
        }}
      </Form.List>
    )
  }

  const createNewSlots = async (values: any) => {
    createSlotsMode === "single"
      ? singleSlotSubmit(values)
      : multipleSlotSubmit(values)
  }

  const setPrevSlotsToPicker = (
    pSlots: any = prevSlots,
    slotsMode: string = createSlotsMode
  ) => {
    let mode = slotsMode === "single" ? "singleTimeSlots" : "multipleTimeSlots"

    if (slotsMode === "multiple" && !isRepeat) {
      pSlots && Object.keys(pSlots).length > 0
        ? Object.keys(pSlots).map(d =>
            pSlots?.[d].length > 0
              ? pSlots[d].map(ps =>
                  newSlotsForm.setFieldsValue({
                    [d]: pSlots?.[d]?.map(s => ({
                      time: [
                        moment(s.split("-")[0], "HH:mm"),
                        moment(s.split("-")[1], "HH:mm"),
                      ],
                    })),
                  })
                )
              : newSlotsForm.setFieldsValue({
                  [d]: [
                    {
                      time: [
                        moment("09:00", "HH:mm"),
                        moment("21:00", "HH:mm"),
                      ],
                    },
                  ],
                })
          )
        : daysList.map(d =>
            newSlotsForm.setFieldsValue({
              [d.key]: [
                {
                  time: [moment("09:00", "HH:mm"), moment("21:00", "HH:mm")],
                },
              ],
            })
          )
    } else {
      pSlots && pSlots["sun"] && pSlots["sun"].length > 0
        ? newSlotsForm.setFieldsValue({
            [mode]: [
              {
                time: [
                  moment(pSlots["sun"][0].split("-")[0], "HH:mm"),
                  moment(pSlots["sun"][0].split("-")[1], "HH:mm"),
                ],
              },
            ],
          })
        : newSlotsForm.setFieldsValue({
            [mode]: [
              {
                time: [moment("09:00", "HH:mm"), moment("21:00", "HH:mm")],
              },
            ],
          })
    }
  }

  const multipleSlotForm = () => {
    return (
      <>
        <div className="repeatDiv">
          <Text className="text repeatText">Repeat: </Text>
          <Radio.Group
            onChange={e => {
              setPrevSlotsToPicker()
              setIsRepeat(e.target.value)
            }}
            value={isRepeat}
            className="repeatRadioGrp"
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        {isRepeat
          ? getTimePicker("multipleTimeSlots", false)
          : daysList.map((day, ind) => {
              return (
                <div className="dayDiv" key={ind}>
                  <Text className="text">{day.key.toUpperCase()}: </Text>
                  <div className="eachDayDiv">
                    {getTimePicker(day.key, false)}
                  </div>
                </div>
              )
            })}
      </>
    )
  }

  const onDateChange = date => {
    setDayName(date.day())
    setDay(date.date())
    setMonth(date.month() + 1)
    setYear(date.year())
  }

  useEffect(() => {
    getPrevSlotTimes()
  }, [])

  return (
    <div className="calendarParent">
      <Modal
        className="createNewSlotsModal"
        title={
          <Text className="modalHeading">
            {createSlotsMode === "single"
              ? `Add Slot for ${day} ${monthList[month - 1]}`
              : `Create Slot for ${monthList[month - 1]} ${year}`}
          </Text>
        }
        visible={isModalVisible}
        onOk={createNewSlots}
        onCancel={() => {
          setCreateSlotsMode("")
          setIsModalVisible(false)
        }}
        footer={null}
      >
        <Form
          form={newSlotsForm}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={createNewSlots}
          onFinishFailed={error => {}}
          autoComplete="off"
        >
          {createSlotsMode === "single"
            ? getTimePicker("singleTimeSlots", true)
            : multipleSlotForm()}

          <div className="actionsDiv">
            <Form.Item>
              <Button
                type="text"
                className="resetBtn"
                onClick={() => {
                  newSlotsForm.resetFields()
                  setPrevSlotsToPicker()
                }}
              >
                Reset
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="text"
                className="saveBtn"
                htmlType="submit"
                loading={isSlotsLoading}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <div className="head">
        <img
          src={BackArrow}
          alt="backArrow"
          className="backArrow"
          onClick={() => navigate("/web/dashboard")}
        />
        <Title className="title">Calendar</Title>
      </div>
      <Row gutter={[20, 20]} className="calendarRow">
        <Col lg={8} className="leftCol">
          <Button
            type="text"
            className="createNewSlotsBtn"
            onClick={() => {
              getPrevSlotTimes("multiple")
              setCreateSlotsMode("multiple")
              setIsModalVisible(true)
            }}
          >
            {`+ Create Slot for ${monthList[month - 1]} ${year}`}
          </Button>
          <div className="topCalendar">
            <CalendarAntd
              fullscreen={false}
              value={moment(`${year}-${month}-${day}`, "YYYY-MM-DD")}
              headerRender={({ value: val, type, onChange, onTypeChange }) => {
                const value = val
                const start = 0
                const end = 12
                const monthOptions: any = []

                const current = value.clone()
                const localeData = value.localeData()
                const months: any = []
                for (let i = 0; i < 12; i++) {
                  current.month(i)
                  months.push(localeData.monthsShort(current))
                }
                let startMonth =
                  value.year().toString() ===
                  getCurrentDate().getFullYear().toString()
                    ? getCurrentDate().getMonth()
                    : 0
                for (let index = startMonth; index < end; index++) {
                  monthOptions.push(
                    <Select.Option
                      className="month-item"
                      key={`${index}`}
                      value={`${index}`}
                    >
                      {months[index]}
                    </Select.Option>
                  )
                }
                const month = value.month()

                const year = value.year()
                const options: any = []
                for (
                  let i = getCurrentDate().getFullYear();
                  i < year + 10;
                  i += 1
                ) {
                  options.push(
                    <Select.Option key={i} value={i} className="year-item">
                      {i}
                    </Select.Option>
                  )
                }
                return (
                  <div style={{ padding: 8 }}>
                    <Row gutter={8}>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          className="my-year-select"
                          onChange={(newYear: any) => {
                            let now = value.clone().year(newYear)
                            let startMonth =
                              newYear.toString() ===
                              getCurrentDate().getFullYear().toString()
                                ? getCurrentDate().getMonth()
                                : 0
                            now = now.month(startMonth)
                            onChange(now)
                          }}
                          value={String(year)}
                        >
                          {options}
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          value={String(month)}
                          onChange={selectedMonth => {
                            const newValue = value.clone()
                            newValue.month(parseInt(selectedMonth, 10))
                            onChange(newValue)
                          }}
                        >
                          {monthOptions}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                )
              }}
              onChange={onDateChange}
            />
          </div>
          <div className="detailsCard">
            <Title className="detailTitle">
              {currentEvent?.title || "Unassigned"}
            </Title>
            <Text className="detailDesc">
              {currentEvent?.description
                ? linkify(currentEvent.description)
                : "No Description"}
            </Text>
            <div className="meet">
              <img src={Meet} alt="meet" />
              <Text
                className="meetLink"
                onClick={() =>
                  currentEvent?.meetLink && window.open(currentEvent.meetLink)
                }
              >
                {currentEvent?.meetLink || "Meet Link not available"}
              </Text>
            </div>
            <div className="clock">
              <img src={Clock} alt="clock" />
              <Text className="clockDesc">
                Start Time:{" "}
                <span>
                  {currentEvent?.start
                    ? getFormattedTime(currentEvent.start)
                    : "Not Scheduled"}
                </span>
              </Text>
            </div>
            <div className="clock">
              <img src={Clock} alt="clock" />
              <Text className="clockDesc">
                End Time:{" "}
                <span>
                  {currentEvent?.end
                    ? getFormattedTime(currentEvent.end)
                    : "Not Scheduled"}
                </span>
              </Text>
            </div>
          </div>
        </Col>
        <Col lg={16} className="rightCol">
          <div className="rightCalendar">
            <div className="topMenu">
              <Button
                type="text"
                className="todayBtn"
                onClick={() => {
                  setDayName(getCurrentDate().getDay())
                  setDay(getCurrentDate().getDate())
                  setMonth(getCurrentDate().getMonth() + 1)
                  setYear(getCurrentDate().getFullYear())
                }}
              >
                Today
              </Button>
            </div>
            <div className="slotsDiv">
              <Row className="slotHeadRow">
                <Text className="dayText">{daysList[dayName].dayName}</Text>
                <Button
                  type="text"
                  className="addSlotBtn"
                  onClick={() => {
                    setCreateSlotsMode("single")
                    setIsModalVisible(true)
                  }}
                >
                  {`Add slot for ${day} ${monthList[month - 1]}`}
                </Button>
              </Row>
              <div className="slotBottom">
                {/* Slots starting from First Slot of the Day */}
                {defaultSlots
                  .slice(
                    parseInt(
                      getNewDate(currentEvents[0]?.start)
                        .toTimeString()
                        .substring(0, 2)
                    )
                  )
                  .map((slot, index) => {
                    return (
                      <Row className="slotRow" key={index}>
                        <Col xs={4} className="labelCol">
                          <Text className="slotLabel">{slot.label}</Text>
                        </Col>
                        <Col xs={20} className="availableSlotCol">
                          {getSlot(slot)}
                        </Col>
                      </Row>
                    )
                  })}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Calendar
