import React from "react"
import "./MentorCardSkeleton.less"
import { Skeleton } from "antd"

const MentorCardSkeleton = () => {
  return (
    <div className="mentorCardSkeletonDiv">
      <div className="div1">
        <Skeleton.Avatar shape="square" size="large" className="imgSkel" />
      </div>
      <div className="div2">
        <Skeleton.Input className="input1" active size="small" />
      </div>
      <Skeleton.Button active size="small" shape="square" className="div3" />
      <div>
        <div className="div4">
          <Skeleton.Button active size="small" shape="square" />
        </div>
        <div className="div4">
          <Skeleton.Button active size="small" shape="square" />
        </div>
      </div>
      <Skeleton.Input active className="input2" size="default" />
    </div>
  )
}

export default MentorCardSkeleton
