import React from "react"
import { Skeleton } from "antd"
import "./CohortCardSkeleton.less"

const CohortCardSkeleton = () => {
  return (
    <div className="cohortCardSkelDiv">
      <Skeleton.Avatar active shape="square" size="large" className="top" />
      <div className="bottom">
        <div className="tags">
          <Skeleton.Button size="small" active className="tag" />
          <Skeleton.Button size="small" active className="tag" />
        </div>
        <Skeleton.Input size="small" active className="cohortHeading" />
        <div className="programBy">
          <Skeleton.Avatar
            active
            shape="circle"
            size="large"
            className="userImg"
          />
          <div className="userData">
            <Skeleton.Button size="small" active className="userName" />
            <Skeleton.Button size="small" active className="userHeadline" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CohortCardSkeleton
