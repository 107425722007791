import React, { useState, useEffect } from "react"
//import { AuthContext } from "../context/AuthContext"
import { getHttpHandler } from "../utils/httpHandler"
import { useLoggedInUserStore } from "../components/ZustandStateManagment/UserData/store"
const limitSessions = 15

export const useSessions = () => {
  const [sessions, setSessions] = useState<any>([])
  const [activeTab, setActiveTab] = useState("upcoming")
  const [docId, setDocId] = useState("")
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [hasMoreSessions, setHasMoreSessions] = useState<boolean>(false)
  const [dataReceived, setDataReceived] = useState<boolean>(false)

  const { token } = useLoggedInUserStore()

  const getNextSessions = async () => {
    if (!hasMoreSessions || !docId) {
      return
    }
    setLoading(true)
    setError(false)
    let currentTime = new Date().getTime()
    try {
      const sessionsData = await getHttpHandler({
        url: `${
          process.env.GATSBY_BE_URL
        }/session/getSessions?limit=${limitSessions}&status=${
          activeTab === "upcoming" ? "" : activeTab
        }&currentTime=${currentTime}&documentId=${docId}`,
        idToken: token,
      })
      setSessions(prevData => {
        return [...prevData, ...sessionsData.sessionData]
      })
      setHasMoreSessions(sessionsData.sessionData.length > 0)
      setError(false)
      setLoading(false)
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  const getSessions = async () => {
    setLoading(true)
    setError(false)
    setDataReceived(false)
    let currentTime = new Date().getTime()
    try {
      const sessionsData = await getHttpHandler({
        url: `${
          process.env.GATSBY_BE_URL
        }/session/getSessions?limit=${limitSessions}&status=${
          activeTab === "upcoming" ? "" : activeTab
        }&currentTime=${currentTime}&documentId=${""}`,
        idToken: token,
      })
      setSessions(sessionsData.sessionData)
      setHasMoreSessions(sessionsData.sessionData.length > 0)
      setError(false)
      setLoading(false)
      setDataReceived(true)
    } catch (error) {
      setError(true)
      setLoading(false)
      setDataReceived(false)
    }
  }

  useEffect(() => {
    if (token) {
      getSessions()
    }
  }, [activeTab, token])

  useEffect(() => {
    getNextSessions()
  }, [docId])

  return {
    getSessions,
    sessions,
    setSessions,
    activeTab,
    setActiveTab,
    hasMoreSessions,
    loading,
    setDocId,
    dataReceived,
  }
}
