import React, { useContext, useEffect, useState } from "react"
import "./MakeProfile.less"
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Tag,
  message,
  Upload,
  Select,
} from "antd"
import { useProfile } from "../../../hooks/useProfile"
import ImgCrop from "antd-img-crop"
import DefaultUser from "../../../assets/mentroWebApp/defaultUser.svg"
import ErrorMessage from "../../../assets/mentroWebApp/errorMessage.svg"
import GreenTick from "../../../assets/mentroWebApp/greenTick.svg"
import BackButton from "../../../assets/mentroWebApp/backButton.svg"
import Internship from "../../../assets/mentroWebApp/internship.svg"
import FullTime from "../../../assets/mentroWebApp/fullTime.svg"
import "antd/es/modal/style"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import "antd/es/slider/style"
//import { CommunityContext } from "../../../context/CommunityContext"
import useCommunityData from "../../ZustandStateManagment/Communities/store"
const { Text, Title } = Typography
const { TextArea } = Input
const { CheckableTag } = Tag

const MakeProfile = ({ setEditProfile, getProfile }) => {
  const authContext = useLoggedInUserStore()
  const { token } = authContext
  const { filters } = useCommunityData()
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [jobTypeValue, setJobTypeValue] = useState<string>("")
  // const [fileList, setFileList] = useState<any>([])
  // const [profileImageUrl, setProfileImageUrl] = useState<string>("")
  // const [isProfilePhoto, setIsProfilePhoto] = useState({
  //   status: "hold",
  //   message: "",
  // })

  const { userData } = useProfile()
  const {
    userName,
    bio,
    interests,
    interestedCompanies,
    institution,
    github,
    linkedIn,
    jobType,
    imageUrl,
    profileId,
  } = userData

  const urls = [
    {
      urlName: "Linkedin",
      urlKey: "linkedIn",
      urlLink: "",
      rule: [
        {
          pattern: /https:\/\/(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
          message: (
            <Text className="errorMessage">
              <img src={ErrorMessage} alt="errorMessage" />
              Please enter a valid LinkedIn url
            </Text>
          ),
        },
      ],
    },
    {
      urlName: "Github",
      urlKey: "github",
      urlLink: "",
      rule: [
        {
          pattern: /https:\/\/(www\.)?github\.com\/[A-z0-9_-]+\/?/,
          message: (
            <Text className="errorMessage">
              <img src={ErrorMessage} alt="errorMessage" />
              Please enter a valid Github url
            </Text>
          ),
        },
      ],
    },
  ]

  const saveProfile = async values => {
    const {
      userName,
      github,
      linkedIn,
      bio,
      institution,
      interestedCompanies,
    } = values

    try {
      const response = await fetch(
        `${process.env.GATSBY_BE_URL}/users/updateUser`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userName,
            github,
            linkedIn,
            bio,
            institution,
            interests: selectedTags,
            interestedCompanies,
            jobType: jobTypeValue,
            // imageUrl: profileImageUrl === urlData ? profileImageUrl : urlData,
          }),
        }
      )

      const responseData = await response.json()
      if (responseData.success) {
        message.success({
          content: responseData.message,
          icon: (
            <img
              src={GreenTick}
              alt="tick mark"
              style={{
                border: "2px solid #4caf50",
                borderRadius: "50%",
                margin: "0em 1em",
              }}
            />
          ),
        })
        setIsLoading(false)
        setEditProfile(false)
        form.resetFields()
        // setProfileImageUrl("")
        getProfile()
      }
    } catch (error) {
      setIsLoading(false)
      message.error({
        content: "Something went wrong",
        icon: (
          <img
            src={ErrorMessage}
            alt="error"
            style={{
              width: "32px",
              margin: "0em 1em",
            }}
          />
        ),
      })
    }
  }

  const onFinish = async (values: any) => {
    if (selectedTags.length < 1 || jobTypeValue === "") {
      return
    }
    setIsLoading(true)
    // if (fileList[0]) {
    //   const uploadTask = app
    //     .storage()
    //     .ref(`users/mentee/${profileId}/profile.png`)
    //     .put(fileList[0].originFileObj)

    //   uploadTask.on(
    //     "state_changed",
    //     snapshot => {
    //       return snapshot
    //     },
    //     err => console.error(err),
    //     () => {
    //       uploadTask.snapshot.ref
    //         .getDownloadURL()
    //         .then(urlData => {
    //           if (urlData) {
    //             setProfileImageUrl(urlData)
    //             saveProfile(values, urlData)
    //           }
    //         })
    //         .catch(err => {
    //           setIsLoading(false)
    //           message.error({
    //             content: "Not able to upload the picture",
    //             icon: (
    //               <img
    //                 src={ErrorMessage}
    //                 alt="error"
    //                 style={{
    //                   width: "32px",
    //                   margin: "0em 1em",
    //                 }}
    //               />
    //             ),
    //           })
    //         })
    //     }
    //   )
    // } else {
    //   saveProfile(values, profileImageUrl)
    // }
    saveProfile(values)
  }

  const onFinishFailed = errorInfo => {
    //
  }

  const tagsHandleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter(t => t !== tag)

    setSelectedTags(nextSelectedTags)
  }

  // const onUploadFile = ({ fileList: newFileList }) => {
  //   const isLt2M = newFileList[0].size / 1024 / 1024 < 2
  //   if (!isLt2M) {
  //     setIsProfilePhoto({
  //       status: "error",
  //       message: "Image size is greater than 2MB",
  //     })
  //     return console.error("Image must smaller than 2MB!")
  //   }

  //   setFileList(newFileList)
  //   setIsProfilePhoto({ status: "success", message: "" })
  // }

  useEffect( () => {
    form.setFields([
      { name: "userName", value: userName },
      { name: "bio", value: bio },
      { name: "institution", value: institution },
      { name: "github", value: github },
      { name: "linkedIn", value: linkedIn },
      { name: "interestedCompanies", value: interestedCompanies },
    ])
    setSelectedTags(interests)
    setJobTypeValue(jobType)
    // setProfileImageUrl(imageUrl)
  }, [userData])


  const jobTypes = [
    {
      label: (
        <div className="jobRadio">
          <img src={FullTime} alt="full time" />
          <Title className="jobText">Full Time</Title>
        </div>
      ),
      value: "fullTime",
    },
    {
      label: (
        <div className="jobRadio">
          <img src={Internship} alt="internship" />
          <Title className="jobText">Internship</Title>
        </div>
      ),
      value: "internship",
    },
  ]

  return (
    <div className="makeProfileDiv">
      <div className="backDiv" onClick={() => setEditProfile(false)}>
        <img src={BackButton} alt="back icon" />
        <Text className="text">Back to Profile</Text>
      </div>
      <div className="formDiv">
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* <Form.Item
            validateStatus={isProfilePhoto.status === "error" ? "error" : ""}
            help={
              isProfilePhoto.message ? (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  {isProfilePhoto.message}
                </Text>
              ) : null
            }
            label={<Text className="text">PROFILE PICTURE</Text>}
            name="profilePicture"
            className="uploadPicForm"
          >
            <img
              src={profileImageUrl ? profileImageUrl : DefaultUser}
              alt="defaultUser"
              className="defaultUser"
              style={
                fileList.length >= 1 ? { visibility: "hidden" } : undefined
              }
            />

            <ImgCrop grid rotate>
              <Upload
                onChange={onUploadFile}
                listType="picture-card"
                fileList={fileList}
                accept="image/png, image/jpeg"
                className="upload-list-inline"
                maxCount={1}
              >
                <div className="btnDiv">
                  <Button type="text" className="uploadBtn">
                    Upload image
                  </Button>
                </div>
                <Text className="text">
                  You can upload .jpeg or .png image files. Max size of 2MB.
                </Text>
              </Upload>
            </ImgCrop>
            <Button
              type="text"
              className="removeBtn"
              onClick={() => {
                setFileList([])
                setProfileImageUrl("")
              }}
            >
              Remove
            </Button>
          </Form.Item> */}
          <Form.Item
            label={<Text className="text">*NAME</Text>}
            name="userName"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                whitespace: true,
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your First Name
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your Name" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">*COMPANY / ORGANISATION</Text>}
            name="institution"
            rules={[
              {
                whitespace: true,
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Organisation
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your Company" />
          </Form.Item>
          <div className="interestsDiv">
            <Form.Item
              validateStatus={
                selectedTags && selectedTags.length < 1 ? "error" : ""
              }
              help={
                selectedTags && selectedTags.length < 1 ? (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please select atleast one interest
                  </Text>
                ) : null
              }
              label={<Text className="text">*INTERESTS</Text>}
              name="tags"
            >
              <div className="tags">
                {filters &&
                  filters?.["expertise"].map(tag => (
                    <CheckableTag
                      className="checkTag"
                      key={tag}
                      checked={selectedTags&&selectedTags.indexOf(tag) > -1}
                      onChange={checked => tagsHandleChange(tag, checked)}
                    >
                      {tag}
                    </CheckableTag>
                  ))}
              </div>
            </Form.Item>
          </div>
          <Form.Item
            label={<Text className="text">*INTERESTED COMPANIES</Text>}
            name="interestedCompanies"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Interested Companies
                  </Text>
                ),
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select your interested companies"
              defaultValue={[]}
              className="interestedCompaniesSelect"
            >
              {filters &&
                filters?.["companies"]?.map(company => (
                  <Select.Option key={company} value={company}>
                    {company}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={<Text className="text">*JOB TYPE</Text>}
            name="jobType"
            wrapperCol={{ span: 24 }}
            validateStatus={!jobTypeValue ? "error" : ""}
            help={
              !jobTypeValue ? (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please select atleast one job type
                </Text>
              ) : null
            }
          >
            <div className="switchDiv">
              <ul>
                {jobTypes.map(jT => (
                  <li
                    className={jobTypeValue === jT.value ? "activeList" : ""}
                    onClick={() => setJobTypeValue(jT.value)}
                  >
                    {jT.label}
                  </li>
                ))}
              </ul>
            </div>
          </Form.Item>

          <Row className="urlDiv">
            {urls.map((url, ind) => (
              <Col key={ind}>
                <Form.Item
                  label={
                    <Text className="text">{url.urlName.toUpperCase()}</Text>
                  }
                  name={url.urlKey}
                  rules={url.rule}
                >
                  <Input placeholder={`Enter your ${url.urlName} url`} />
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Form.Item
            label={<Text className="text">*BIO</Text>}
            name="bio"
            rules={[
              {
                whitespace: true,
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Bio
                  </Text>
                ),
              },
            ]}
          >
            <TextArea rows={4} placeholder="Enter your Bio" />
          </Form.Item>
          <div className="saveDiv">
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="saveBtn"
                loading={isLoading}
              >
                Save Profile
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default MakeProfile
