import React from "react"
import "./CohortDetailSkeleton.less"
import { Skeleton } from "antd"

const CohortDetailSkeleton = () => {
  return (
    <div className="cohortDetailSkelDiv">
      <Skeleton.Avatar
        active
        shape="square"
        size="large"
        className="cohortBanner"
      />
      <div className="bodyDiv">
        <Skeleton.Input size="small" active className="cohortHeading" />
        <Skeleton.Button size="small" active className="cohortDesc" />
        <Skeleton.Input size="large" active className="discountDiv" />

        <div className="detailsDiv">
          {Array.from(Array(3)).map((_, index) => (
            <div className="detailDiv" key={index}>
              <Skeleton.Avatar
                active
                shape="circle"
                size="small"
                className="detailIcon"
              />
              <Skeleton.Input
                size="small"
                active
                className={`detailDesc${index + 1}`}
              />
            </div>
          ))}
        </div>
        <Skeleton.Input size="large" active className="mentorDetail" />
        <div className="learningsDiv">
          <Skeleton.Input size="small" active className="learningHeading" />
          <div className="learnings">
            {Array.from(Array(4)).map((_, index) => (
              <span className="learningText" key={index}>
                <Skeleton.Avatar
                  active
                  shape="circle"
                  size="default"
                  className="tick"
                />
                <Skeleton.Button size="small" active />
              </span>
            ))}
          </div>
        </div>
        <div className="optionsDiv">
          <Skeleton.Input size="small" active className="optionsHeading" />
          <div className="options">
            <Skeleton.Input size="small" active className="optionText1" />
            <Skeleton.Input size="small" active className="optionText2" />
          </div>
        </div>
        <Skeleton.Button size="small" active className="joinBtn" />
      </div>
    </div>
  )
}

export default CohortDetailSkeleton
