import React, { useEffect, useState } from "react"
import "./MentorViewProfile.less"
import { Button, Col, message, Popconfirm, Row, Typography } from "antd"
import { useProfile } from "../../../hooks/useProfile"
import { getHttpHandler } from "../../../utils/httpHandler"
import { linkify } from "../../../utils/data"
//import CreateCommunity from "../../Community/CreateCommunity"
import MentorMakeProfile from "../MentorMakeProfile"
import SharePopup from "../../Popups/SharePopup"
import ShareButtons from "../../ShareButtons"
import EditSessionsPanel from "../EditSessionsPanel"
import EditIcon from "../../../assets/mentroWebApp/editIcon.svg"
import DefaultUser from "../../../assets/mentroWebApp/defaultUser.svg"
import LinkedinIcon from "../../../assets/mentroWebApp/linkedin.svg"
import TwitterIcon from "../../../assets/mentroWebApp/twitter.svg"
import AverageRating from "../../../assets/mentroWebApp/averageRating.svg"
import SessionCompleted from "../../../assets/mentroWebApp/sessionCompleted.svg"
import MemberCount from "../../../assets/mentroWebApp/memberCount.svg"
import GoldenStar from "../../../assets/mentroWebApp/goldenStar.svg"
import DownArrow from "../../../assets/mentroWebApp/downArrow.svg"
import VerifiedTick from "../../../assets/mentroWebApp/verifiedTick.svg"
//import ShareArrow from "../../../assets/mentroWebApp/shareArrow.svg"
import GreenShareArrow from "../../../assets/mentroWebApp/greenShareArrow.svg"
//import { logGa } from "../../../../services/analytics"
import { navigate } from "gatsby"

const { Text, Title } = Typography
const reviewLimit = 15

const MentorViewProfile = ({ interestList, orgList }) => {
  const { mentorData, token, getProfile, mentorCommunities } = useProfile()
  const [editProfile, setEditProfile] = useState<boolean>(false)
  const [isEditSession, setIsEditSession] = useState<boolean>(false)
  const [sessionList, setSessionList] = useState<any>([])
  const [reviews, setReviews] = useState<any>([])
  const [reviewMore, setReviewMore] = useState<boolean>(true)
  const [isSharePopupVisible, setIsSharePopupVisible] = useState<boolean>(false)
  // const [isCreateCommunityVisible, setisCreateCommunityVisible] =
  //   useState<boolean>(false)
  // const [currentCommunity, setCurrentCommunity] = useState<any>(null)

  const getSessionData = async () => {
    try {
      const sessionData = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/getSessionTypesDetails`,
        idToken: token,
      })
      setSessionList(sessionData.sessionTypeDetails)
    } catch (error) {
      //
    }
  }

  const showMoreReview = async () => {
    if (!reviewMore || mentorData?.mentorData?.reviews?.length === 0) {
      return setReviewMore(false)
    }
    let docId = mentorData.reviews[mentorData.reviews.length - 1].id
    try {
      const mentorDataResp = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/getAllReviews?documentId=${docId}&mentorId=${mentorData.uid}&limit=${reviewLimit}`,
        idToken: token,
      })

      setReviews([...reviews, ...mentorDataResp.reviews])
      setReviewMore(mentorDataResp.reviews.length === reviewLimit)
    } catch (error) {
      message.error("Something went wrong")
    }
  }

  useEffect(() => {
    if (!mentorData) return
    getSessionData()
    setReviews(mentorData.reviews)
  }, [mentorData])

  return (
    <div className="mentorViewProfileParentDiv">
      <SharePopup
        isSharePopupVisible={isSharePopupVisible}
        setIsSharePopupVisible={setIsSharePopupVisible}
      />
      {/* <CreateCommunity
        currentCommunity={currentCommunity}
        setCurrentCommunity={setCurrentCommunity}
        onCreateCommunity={getProfile}
        isCreateCommunityVisible={isCreateCommunityVisible}
        setisCreateCommunityVisible={setisCreateCommunityVisible}
      /> */}
      <Row className="viewProfileRow" gutter={[40, 40]}>
        <Col
          lg={15}
          className={`viewProfileCols ${isEditSession && "hideProfileSection"}`}
        >
          {!editProfile ? (
            <div className="viewProfileDiv">
              <div className="topActionBar">
                <div className="actionDiv" onClick={() => setEditProfile(true)}>
                  <img src={EditIcon} alt="edit profile icon" />
                  <Text className="text">Edit Profile</Text>
                </div>
              </div>
              <div className="profilePicture">
                <div className="top">
                  <img
                    src={
                      mentorData?.profileUrl
                        ? mentorData?.profileUrl
                        : DefaultUser
                    }
                    alt="user"
                    className="profilePic"
                  />
                  <div className="info">
                    <div className="infoDiv">
                      <img src={AverageRating} alt="avgRate" />
                      <Text className="count">{mentorData?.averageRating}</Text>
                      <Title className="infoTitle">Average Rating</Title>
                    </div>
                    <div className="infoDiv">
                      <img
                        src={
                          mentorCommunities?.numMembers
                            ? MemberCount
                            : SessionCompleted
                        }
                        alt="memberCount"
                      />
                      <Text className="count">
                        {token &&
                          (mentorCommunities?.numMembers ||
                            mentorData?.noOfSessionsCompleted)}
                      </Text>
                      <Title className="infoTitle">
                        {mentorCommunities?.numMembers
                          ? "Members"
                          : "Session Completed"}
                      </Title>
                    </div>
                  </div>
                </div>
                <span
                  className={`activeStatus ${
                    !mentorData?.isActive && "notActive"
                  }`}
                >
                  <span className="activeCircle"></span>
                  {mentorData?.isActive ? "Available" : "Not available"}
                </span>
                <div className="namesDiv">
                  <Title className="name">
                    {mentorData?.name}
                    {mentorData?.isVerified && (
                      <img
                        src={VerifiedTick}
                        alt="VerifiedTick"
                        className="verifiedTick"
                      />
                    )}
                  </Title>
                  <div className="socialDiv">
                    {mentorData?.linkedInUrl && (
                      <span
                        className="socialSpan"
                        onClick={() => window.open(mentorData?.linkedInUrl)}
                      >
                        <img src={LinkedinIcon} alt="linkedin" />
                      </span>
                    )}
                    {mentorData?.twitterUrl && (
                      <span
                        className="socialSpan"
                        onClick={() => window.open(mentorData?.twitterUrl)}
                      >
                        <img src={TwitterIcon} alt="twitter" />
                      </span>
                    )}
                  </div>
                </div>
                <Text className="org">
                  {mentorData?.role} at {mentorData?.organisation}
                </Text>
                <Text className="profileId">@{mentorData?.profileId}</Text>
                <div className="actionBtns">
                  <Popconfirm
                    disabled={!mentorData?.profileId}
                    id="sharePopConfirm"
                    icon={null}
                    placement="bottom"
                    // onVisibleChange={() => {
                    //   if (mentorData.onBoardingStatus !== "verified") {
                    //     return setIsSharePopupVisible(true)
                    //   }
                    // }}
                    title={
                      <ShareButtons
                        url={`Join the Community of ${mentorData?.name} on Mentro Now! 👉 : mentro.tech/@${mentorData?.profileId} `}
                      />
                    }
                  >
                    <Button type="text" className="actionBtn whiteActionBtn">
                      <img src={GreenShareArrow} alt="share profile icon" />
                      Share
                    </Button>
                  </Popconfirm>
                  <Button
                    type="text"
                    className="actionBtn"
                    onClick={() =>
                      navigate(`/web/community/${mentorData?.uid}`)
                    }
                  >
                    Visit Community
                  </Button>
                </div>
              </div>
              <div className="interestsDiv">
                <Title className="title">Skills</Title>
                {mentorData?.interests?.map((intr, ind) => (
                  <span className="interest" key={ind}>
                    {interestList?.find(intrs => intrs.value === intr)?.label ||
                      intr}
                  </span>
                ))}
              </div>
              {/* <div className="communitiesDiv">
                <Title className="title">Communities</Title>
                <div className="addCommDiv">
                  <Button
                    type="text"
                    className="addCommunityBtn"
                    onClick={() => {
                      logGa(
                        `ITEM_CLICK_create_community_btn_${mentorData?.uid}`
                      )
                      setCurrentCommunity(null)
                      setisCreateCommunityVisible(true)
                    }}
                  >
                    Create Community +
                  </Button>
                </div>
              </div> */}
              <div className="bioDiv">
                <Title className="title">Bio</Title>
                <Text className="text">{linkify(mentorData?.bio)}</Text>
                <Title className="quote">
                  "{mentorData?.quote}" ~ {mentorData?.name}
                </Title>
              </div>
              {reviews.length > 0 && (
                <div className="reviewsDiv">
                  <Title className="title">Reviews</Title>
                  {reviews.map(review => (
                    <div className="review" key={review.id}>
                      <div className="topReview">
                        <img
                          src={review.imageUrl ? review.imageUrl : DefaultUser}
                          alt="user"
                          className="reviewImg"
                        />
                        <div className="rightSec">
                          <Title className="reviewName">
                            {review.menteeName}
                          </Title>
                          <div className="rating">
                            {Array.from(Array(Math.ceil(review.rating))).map(
                              (r, i) => (
                                <img
                                  key={i}
                                  src={GoldenStar}
                                  alt="rating star"
                                  className="ratingStar"
                                />
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <Title className="reviewText">{review.review}</Title>
                    </div>
                  ))}
                  {reviewMore && mentorData?.reviews.length > 0 && (
                    <div className="sep">
                      <Button
                        className="showMore"
                        onClick={showMoreReview}
                        type="text"
                      >
                        Show More <img src={DownArrow} alt="downArrow" />
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <MentorMakeProfile
              getProfile={getProfile}
              interestList={interestList}
              setEditProfile={setEditProfile}
              orgList={orgList}
            />
          )}
        </Col>
        <Col
          lg={9}
          className={`viewProfileCols ${editProfile && "hideEditSessionPanel"}`}
        >
          <EditSessionsPanel
            setIsSharePopupVisible={setIsSharePopupVisible}
            onBoardingStatus={mentorData?.onBoardingStatus === "verified"}
            sessionList={sessionList}
            getSessionData={getSessionData}
            isEditSession={isEditSession}
            setIsEditSession={setIsEditSession}
          />
        </Col>
      </Row>
    </div>
  )
}

export default MentorViewProfile
