import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import "./CommunityDetails.less"
import { useLocation, useParams } from "@reach/router"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { getHttpHandler, postHttpHandler } from "../../../utils/httpHandler"
import { Col, message, Row, Typography } from "antd"
import AuthPopup from "../../Popups/AuthPopup"
import CommunityPost from "../CommunityPost"
import CommunityPostSkeleton from "../CommunityPostSkeleton"
import NotFound from "../../../assets/mentroWebApp/loaderIll.svg"
import CreateCommunityPost from "../CreateCommunityPost"
import CommunityData from "../CommunityData"

const { Title } = Typography
const MAX_NO_OF_TOPICS = 10

const CommunityDetails = () => {
  const params = useParams()
  const state: any = useLocation().state
  const { token, user, mentor, mentorData } = useLoggedInUserStore()
  const [isCreatePostVisible, setisCreatePostVisible] = useState<boolean>(false)
  const [currentCommunity, setCurrentCommunity] = useState<any>(null)
  const [currentCommunityPosts, setCurrentCommunityPosts] = useState<any[]>([])
  const [isAuthPopupVisible, setIsAuthPopupVisible] = useState<boolean>(false)
  const [loadingCommunityPosts, setLoadingCommunityPosts] =
    useState<boolean>(false)
  const [dataReceived, setDataReceived] = useState<boolean>(false)
  const [hasMorePosts, setHasMorePosts] = useState<boolean>(false)
  const [lastPostId, setLastPostId] = useState<string>("")
  const [loadingCommunity, setLoadingCommunity] = useState<boolean>(false)
  const [position, setPosition] = useState<string>("")
  const [popularPosts, setPopularPosts] = useState<any[]>([])
  const [loadingPopularPosts, setLoadingPopularPosts] = useState<boolean>(false)

  useEffect(() => {
    if (state?.community?.uid) {
      setCurrentCommunity(state?.community)
      setPosition(
        mentor && state?.community?.uid === mentorData?.uid
          ? "Owner"
          : (state?.community?.isMember && "Member") || ""
      )
    }
    if (!params?.communityId) return
    getCommunityPosts()
    getCommunityDetails(params?.communityId)
    if (params?.communityId && window.innerWidth > 768) {
      getPopularPosts(params?.communityId)
    }

    return () => {
      setCurrentCommunity(null)
      setPosition("")
      setCurrentCommunityPosts([])
    }
  }, [params?.communityId])

  useEffect(() => {
    getNextCommunityPosts()
  }, [lastPostId])

  const getCommunityDetails = async communityId => {
    setLoadingCommunity(true)
    try {
      const communityDetails = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/get?communityId=${communityId}`,
        idToken: token,
      })
      setLoadingCommunity(false)
      setCurrentCommunity(communityDetails?.community || null)
      setPosition(
        mentor && communityDetails?.community?.uid === mentorData?.uid
          ? "Owner"
          : (communityDetails?.community?.isMember && "Member") || ""
      )
    } catch (error) {
      setLoadingCommunity(false)
      message.error("Something went wrong!")
    }
  }

  const getCommunityPosts = async () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    setLoadingCommunityPosts(true)
    setDataReceived(false)
    try {
      const communityPosts = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/post/getLatestPosts`,
        idToken: token,
        body: {
          lastPostId: "",
          topics: [],
          communityId: params?.communityId,
        },
      })
      setDataReceived(true)
      setCurrentCommunityPosts(communityPosts.posts || [])
      setHasMorePosts(communityPosts.posts.length > 0)
      setLoadingCommunityPosts(false)
    } catch (error) {
      setLoadingCommunityPosts(false)
      setDataReceived(false)
    }
  }

  const getNextCommunityPosts = async () => {
    if (!hasMorePosts || !lastPostId) {
      return
    }
    setLoadingCommunityPosts(true)
    try {
      const nextLatestPosts = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/post/getLatestPosts`,
        idToken: token,
        body: {
          lastPostId: lastPostId,
          topics: [],
          communityId: params?.communityId,
        },
      })
      setCurrentCommunityPosts(prevData => {
        return [...prevData, ...nextLatestPosts.posts]
      })
      setHasMorePosts(nextLatestPosts.posts.length > 0)
      setLoadingCommunityPosts(false)
    } catch (error) {
      setLoadingCommunityPosts(false)
    }
  }

  // -----------------------------------------------------------------
  // INFINITE SCROLLING-----------------------------------------------------------------
  // -----------------------------------------------------------------
  const observer = useRef<any>()
  const lastPostRef = useCallback(
    ele => {
      if (loadingCommunityPosts) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasMorePosts) {
            setLastPostId(
              currentCommunityPosts[currentCommunityPosts.length - 1]?.postId ||
                ""
            )
          }
        },
        {
          root: null,
          rootMargin: "40px",
          threshold: 0,
        }
      )
      if (ele) observer.current.observe(ele)
    },
    [loadingCommunityPosts, hasMorePosts]
  )
  //-----------------------------------

  const getPopularPosts = async communityId => {
    setLoadingPopularPosts(true)
    try {
      const popularPosts = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/post/getPopularPosts`,
        idToken: token,
        body: {
          topics: user?.interests?.slice(0, MAX_NO_OF_TOPICS) || [],
          communityId,
        },
      })
      setPopularPosts(popularPosts.posts)
      setLoadingPopularPosts(false)
    } catch (error) {
      setLoadingPopularPosts(false)
    }
  }

  return (
    <div className="communityDetailsDiv">
      <CreateCommunityPost
        communityId={currentCommunity?.uid}
        onCreatePost={getCommunityPosts}
        isCreatePostVisible={isCreatePostVisible}
        setisCreatePostVisible={setisCreatePostVisible}
      />
      <AuthPopup
        setIsAuthPopupVisible={setIsAuthPopupVisible}
        isAuthPopupVisible={isAuthPopupVisible}
      />
      <Row gutter={[40, 10]} className="communityDetailsRow">
        <Col lg={16} className="communityDetailsCol">
          {dataReceived &&
            currentCommunityPosts?.length > 0 &&
            currentCommunityPosts.map((communityPostData, ind) => (
              <CommunityPost
                noMarginPost={ind + 1 === currentCommunityPosts?.length}
                setIsAuthPopupVisible={setIsAuthPopupVisible}
                communityPost={communityPostData}
                key={communityPostData.postId}
              />
            ))}
          {loadingCommunityPosts && (
            <CommunityPostSkeleton
              marginTop={currentCommunityPosts?.length > 0}
            />
          )}
          {dataReceived && currentCommunityPosts.length === 0 && (
            <div className="noPostsDiv">
              <img src={NotFound} alt="not found illustration" />
              <Title className="noPostsText">No posts found.</Title>
            </div>
          )}
          <div ref={lastPostRef} />
        </Col>
        <Col lg={8} className="communityDetailsCol rightCol">
          <CommunityData
            currentCommunity={currentCommunity}
            loadingCommunity={loadingCommunity}
            position={position}
            setPosition={setPosition}
            setIsAuthPopupVisible={setIsAuthPopupVisible}
            setisCreatePostVisible={setisCreatePostVisible}
          />
          <div className="popularPostDiv">
            {popularPosts?.length > 0 && (
              <Title className="title">Most popular from this community</Title>
            )}
            {popularPosts?.length > 0 &&
              popularPosts.map((communityPopularPostData, ind) => (
                <CommunityPost
                  smallFont={true}
                  noMarginPost={ind + 1 === popularPosts?.length}
                  borderRadius={false}
                  setIsAuthPopupVisible={setIsAuthPopupVisible}
                  communityPost={communityPopularPostData}
                  key={communityPopularPostData.postId}
                />
              ))}
            {loadingPopularPosts && (
              <CommunityPostSkeleton
                borderRadius={false}
                marginTop={popularPosts?.length > 0}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default CommunityDetails
