import React, { useContext, useEffect, useState } from "react"
import "./Container.less"
import { Layout } from "antd"
import { Router, useLocation, Redirect } from "@reach/router"
import { getHttpHandler } from "../../utils/httpHandler"
import { JobsContext } from "../../context/JobsContext"
import SideBar from "../Layouts/SideBar"
import MentorDashboard from "../MentorsApp/MentorDashboard"
import Sessions from "../Sessions"
import Calendar from "../MentorsApp/Calendar"
import MentorRoute from "../PrivateRoutes/MentorRoute"
import SignedRoute from "../PrivateRoutes/SignedRoute"
import Profile from "../Profile"
import BottomBar from "../Layouts/BottomBar"
import MobileNavbar from "../Layouts/Navbars/MobileNavbar"
import PublicProfile from "../PublicProfile"
import Chats from "../MentorsApp/Chats"
import Opportunities from "../Tabs/Opportunities"
import Mentors from "../Tabs/Mentors"
import PublicRoute from "../PublicRoute/PublicRoute"
import useLoggedStatus from "../useLoggedStatus"
import Programs from "../Programs"
import ProgramsDetails from "../ProgramsDetails"
import UserOnBoardPopup from "../Popups/UserOnBoardPopup"
import Community from "../Community"
import CommunityPostDetails from "../Community/CommunityPostDetails"
import CommunityDetails from "../Community/CommunityDetails"
import CommunityJoin from "../Community/CommunityJoin"
// import { navigate } from "gatsby"
import { logGa } from "../../../services/analytics"
import AuthTemplate from "../Auth/AuthTemplate"
import { useLoggedInUserStore } from "../ZustandStateManagment/UserData/store"
import useCommunityData from "../ZustandStateManagment/Communities/store"
import Courses from "../Courses"
import CourseDetail from "../Courses/CourseDetail/CourseDetail"
import mixpanel from "mixpanel-browser"
const { Content } = Layout

const MOBILE_NAV_BAR_HIDE = ["/web/calendar"]
const BOTTOM_BAR_SHOW = [
  "/web/",
  "/web",
  "/web/community",
  "/web/dashboard",
  "/web/mentors",
  "/web/jobs",
  "/web/calendar",
  "/web/sessions",
  "/web/chats",
  "/web/programs",
  "/web/resources",
]

const Container = () => {
  const authContext = useLoggedInUserStore()
  const location = useLocation()
  const { filters, setFilters, setCommunities } = useCommunityData()
  const { mentor, token, user } = authContext
  // const [filters, setFilters] = useState<any>()
  const [isUserOnBoardPopupVisible, setIsUserOnBoardPopupVisible] =
    useState(false)
  const authState = useLoggedStatus()

  //JOBS---
  const [companyType, setCompanyType] = useState<string>("")
  const [roleType, setRoleType] = useState<string>("")
  const [docId, setDocId] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [hasMoreJobs, setHasMoreJobs] = useState<boolean>(false)
  const [jobOpportunities, setJobOpportunities] = useState<any[]>([])
  const [dataReceived, setDataReceived] = useState<boolean>(false)

  //COMMUNITY---
  // const [currentPost, setCurrentPost] = useState<any>(null)
  // const [currentCommunity, setCurrentCommunity] = useState<any>(null)
  // // const [communities, setCommunities] = useState<any[]>([])
  // const [latestPosts, setLatestPosts] = useState<any[]>([])
  // const [lastCommentId, setLastCommentId] = useState<string>("")
  // const [topCommentId, setTopCommentId] = useState<string>("")
  // const [hasMoreComments, setHasMoreComments] = useState<boolean>(false)
  // const [comments, setComments] = useState<any[]>([])

  //COMMUNITY API CALLS-----------
  // const reactToPost = async (postId, status) => {
  //   try {
  //     const reactPostResp = await postHttpHandler({
  //       url: `${process.env.GATSBY_BE_URL}/community/post/like?postId=${postId}&status=${status}`,
  //       idToken: token,
  //     })
  //     // if (reactPostResp.success) {
  //     //   return
  //     // }
  //     // return message.error("Something went wrong!")
  //   } catch (error) {
  //     message.error("Something went wrong!")
  //   }
  // }

  // const reactToComment = async (postId, commentId, status) => {
  //   try {
  //     const likeCommentResp = await postHttpHandler({
  //       url: `${process.env.GATSBY_BE_URL}/community/comment/like`,
  //       idToken: token,
  //       body: {
  //         postId,
  //         commentId,
  //         status,
  //       },
  //     })
  //     // if (reactPostResp.success) {
  //     //   return
  //     // }
  //     // return message.error("Something went wrong!")
  //   } catch (error) {
  //     message.error("Something went wrong!")
  //   }
  // }

  // const reactToReply = async (postId, commentId, replyId, status) => {
  //   try {
  //     const likeReplyResp = await postHttpHandler({
  //       url: `${process.env.GATSBY_BE_URL}/community/reply/like`,
  //       idToken: token,
  //       body: {
  //         postId,
  //         commentId,
  //         replyId,
  //         status,
  //       },
  //     })
  //     // if (reactPostResp.success) {
  //     //   return
  //     // }
  //     // return message.error("Something went wrong!")
  //   } catch (error) {
  //     message.error("Something went wrong!")
  //   }
  // }

  // const getCommunityPostComments = async (
  //   postId,
  //   topCommId = "",
  //   lastCommId = ""
  // ) => {
  //   try {
  //     const commentsResp = await getHttpHandler({
  //       url: `${process.env.GATSBY_BE_URL}/community/comment/get?postId=${postId}&commentId=${topCommId}&lastCommentId=${lastCommId}`,
  //       idToken: token,
  //     })
  //     setComments(commentsResp?.comments || [])
  //     setHasMoreComments(commentsResp?.comments.length > 0)
  //   } catch (error) {
  //     message.error("Something went wrong!")
  //   }
  // }

  // const joinCommunity = async (communityId, status, redirect = false) => {
  //   try {
  //     const joinCommunityResp = await postHttpHandler({
  //       url: `${process.env.GATSBY_BE_URL}/community/join`,
  //       idToken: token,
  //       body: {
  //         communityId,
  //         referrerId: "",
  //         status,
  //       },
  //     })
  //     if (joinCommunityResp.status === "success") {
  //       message.success(
  //         `${status === "Join" ? "Joined" : "Left"} community successfully!`
  //       )
  //       if (redirect) {
  //         navigate(`/web/community/${communityId}`)
  //       }
  //       getFollowedCommunities()
  //       return
  //     }
  //   } catch (error) {
  //     message.error("Something went wrong! Please try again.")
  //   }
  // }

  const getFollowedCommunities = async () => {
    try {
      const myFollowedCommunities = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/followed`,
        idToken: token,
      })
      setCommunities(myFollowedCommunities.communities)
    } catch (error) {}
  }

  //GET FILTERS CALL-------------
  const getUsersFilters = async () => {
    try {
      const filters = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/filters`,
        idToken: token,
      })
      await setFilters(filters)
    } catch (error) {}
  }

  // console.log(filters, "{}PP")

  useEffect(() => {
    if (token && !mentor && user && filters?.["expertise"]?.length > 0) {
      if (!user?.jobType) {
        setTimeout(() => {
          setIsUserOnBoardPopupVisible(true)
          logGa("POPUP_SHOWN", {
            popup_type: "user_onboard_popup",
          })
        }, 5000)
      }
    }
  }, [filters])

  useEffect(() => {
    if (token) {
      getFollowedCommunities()
      getUsersFilters()
    }
  }, [token])

  useEffect(() => {
    mixpanel.init(process.env.GATSBY_MIX_PANEL_TOKEN, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    })

    if (user) {
      // Set this to a unique identifier for the user performing the event.
      mixpanel.identify(user.uid)
    }

    // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
  }, [user])

  return (
    <>
      <div className="sidebarDesktop">
        <SideBar />
      </div>
      {!MOBILE_NAV_BAR_HIDE.includes(location.pathname) && <MobileNavbar />}
      <Layout style={{ minHeight: "100vh" }}>
        <Content
          className={`contentStyling ${
            ["/web/sessions"].includes(location.pathname) && "whiteBg"
          }`}
        >
          <UserOnBoardPopup
            isUserOnBoardPopupVisible={isUserOnBoardPopupVisible}
            setIsUserOnBoardPopupVisible={setIsUserOnBoardPopupVisible}
          />
          <Router className="router-box" basepath="/web">
            {token && mentor ? (
                <Redirect noThrow from="/" to="dashboard" />
              ) : (
                <Redirect noThrow from="/" to="resources" />
            )}
            <PublicRoute path="auth" component={AuthTemplate} />
            <MentorRoute path="dashboard" component={MentorDashboard} />
            <PublicRoute path="community" component={Community} />
            <PublicRoute
              path="community/:communityId"
              component={CommunityDetails}
            />
            <PublicRoute path="join/:communityId" component={CommunityJoin} />
            <PublicRoute path="post/:postId" component={CommunityPostDetails} />
            <PublicRoute path="mentors" component={Mentors} />
            <PublicRoute path="jobs" component={Opportunities} />
            <SignedRoute path="profile" component={Profile} />
            <SignedRoute path="sessions" component={Sessions} />
            <MentorRoute path="calendar" component={Calendar} />
            <SignedRoute path="chats" component={Chats} />
            <PublicRoute path=":profileId" component={PublicProfile} />
            <PublicRoute path="programs" component={Programs} />
            <PublicRoute
              path="programs/:programId"
              component={ProgramsDetails}
            />
            <PublicRoute path="resources" component={Courses} />
            <PublicRoute path="resources/:courseId" component={CourseDetail} />
          </Router>
        </Content>
        {BOTTOM_BAR_SHOW.includes(location.pathname) &&
          authState === "known" && <BottomBar />}
      </Layout>
    </>
  )
}

export default Container
