import { create } from "zustand"
// import Cookies from "js-cookie"
// import { fbAuth } from "../../../../../firebaseConfig"
import { persist } from "zustand/middleware"

interface JobsDataState {
  jobOpportunities: any[]
  setJobOpportunities: (jobOpportunities: any[]) => void
  error: boolean
  setError: (error: boolean) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  hasMoreJobs: boolean
  setHasMoreJobs: (hasMoreJobs: boolean) => void
  docId: string | null
  setDocId: (docId: string) => void
  dataReceived: boolean
  setDataReceived: (dataReceived: boolean) => void
  companyType: string | null
  setCompanyType: (companyType: string) => void
  roleType: string | null
  setRoleType: (roleType: string) => void
}

export const useJobsData = create<JobsDataState>()((set, get) => ({
  jobOpportunities: [],
  setJobOpportunities: (jobOpportunities: any[]) => {
      console.log(jobOpportunities, "{}{}{}{}")
    set(state => ({ ...state, jobOpportunities: jobOpportunities }))
    // console.log(jobOpportunities)
  },
  //   setCurrentPostId: (currentPostId: any) => {
  //     set(state => ({ ...state, currentPostId: currentPostId }))
  //   },
  error: false,
  setError: (error: boolean) => {set(state => ({ ...state, error: error }))},
  loading: false,
  setLoading: (loading: boolean) => {set(state => ({ ...state, loading: loading }))},
  hasMoreJobs: false,
  setHasMoreJobs: (hasMoreJobs: boolean) => {
    set(state => ({ ...state, hasMoreJobs: hasMoreJobs }))
  },
  docId: "",
  setDocId: (docId: string) => {
    //   console.log(docId)
    set(state => ({ ...state, docId: docId }))
  },
  dataReceived: false,
  setDataReceived: (dataReceived: boolean) => {set(state => ({ ...state, dataReceived: dataReceived }))},
  companyType: "",
  setCompanyType: (companyType: string) => {set(state => ({ ...state, companyType: companyType }))},
  roleType: "",
  setRoleType: (roleType: string) => {set(state => ({ ...state, roleType: roleType }))},
}))

export default useJobsData
