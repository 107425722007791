import { useState, useCallback, useEffect } from "react"
import { useLoggedInUserStore } from "../components/ZustandStateManagment/UserData/store"

export const useProfile = () => {
  const login = useLoggedInUserStore((state) => state.login)

  const isSsr = typeof window === "undefined"
  const localData = !isSsr ? localStorage.getItem("userData") : null
  const user = localData ? JSON.parse(localData).state.user : null
  const mentor = localData ? JSON.parse(localData).state.mentorData : null
  const mentorComm = localData ? JSON.parse(localData).state.community : null
  const token = localData ? JSON.parse(localData).state.token : null
  // const token = Cookies.get("token")
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState<any>(user)
  const [mentorData, setMentorData] = useState<any>(mentor)
  const [mentorCommunities, setMentorCommunities] = useState<any>(mentorComm)
  const [jobType, setJobType] = useState<boolean>(false)

  const getProfile = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await fetch(
        `${process.env.GATSBY_BE_URL}/users/v2/getUserProfile`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const userData = await response.json()
      setUserData(userData.user)
      setMentorData(userData.mentorData)
      setMentorCommunities(userData.community)
      setJobType(!!userData?.user?.jobType)
      setIsLoading(false)
      login(userData?.mentor,
        userData?.mentorData,
        token,
        userData?.user,
        userData?.community)
    } catch (error) {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (token) {
      getProfile()
    }
  }, [])

  return { userData, mentorData, mentorCommunities, getProfile, token, jobType }
}
