export const defaultSlots = [
  { startTime: 0, endTime: 1, label: "12 AM" },
  { startTime: 1, endTime: 2, label: "1 AM" },
  { startTime: 2, endTime: 3, label: "2 AM" },
  { startTime: 3, endTime: 4, label: "3 AM" },
  { startTime: 4, endTime: 5, label: "4 AM" },
  { startTime: 5, endTime: 6, label: "5 AM" },
  { startTime: 6, endTime: 7, label: "6 AM" },
  { startTime: 7, endTime: 8, label: "7 AM" },
  { startTime: 8, endTime: 9, label: "8 AM" },
  { startTime: 9, endTime: 10, label: "9 AM" },
  { startTime: 10, endTime: 11, label: "10 AM" },
  { startTime: 11, endTime: 12, label: "11 AM" },
  { startTime: 12, endTime: 13, label: "12 PM" },
  { startTime: 13, endTime: 14, label: "1 PM" },
  { startTime: 14, endTime: 15, label: "2 PM" },
  { startTime: 15, endTime: 16, label: "3 PM" },
  { startTime: 16, endTime: 17, label: "4 PM" },
  { startTime: 17, endTime: 18, label: "5 PM" },
  { startTime: 18, endTime: 19, label: "6 PM" },
  { startTime: 19, endTime: 20, label: "7 PM" },
  { startTime: 20, endTime: 21, label: "8 PM" },
  { startTime: 21, endTime: 22, label: "9 PM" },
  { startTime: 22, endTime: 23, label: "10 PM" },
  { startTime: 23, endTime: 0, label: "11 PM" },
]

export const daysList = [
  { key: "sun", dayName: "Sunday" },
  { key: "mon", dayName: "Monday" },
  { key: "tue", dayName: "Tuesday" },
  { key: "wed", dayName: "Wednesday" },
  { key: "thu", dayName: "Thursday" },
  { key: "fri", dayName: "Friday" },
  { key: "sat", dayName: "Saturday" },
]

export const getNewDate = (date: any) => {
  return new Date(date)
}

export const getCurrentDate = () => {
  return new Date()
}

export const getFormattedTime = date => {
  let formattedTime = getNewDate(date).toLocaleTimeString("en-US").split(" ")
  let part1 =
    formattedTime[0].split(":")[0] + ":" + formattedTime[0].split(":")[1]
  return part1 + " " + formattedTime[1]
}

export const getTimeNumber = time => {
  return parseInt(getNewDate(time).toTimeString().substring(3, 5))
}
