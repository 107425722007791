import React, { useContext, useEffect, useState } from "react"
import "./MentorDashboard.less"
import { Button, Typography, Row, Col, message, Modal, Form, Input } from "antd"
import DashboardIll from "../../../assets/mentroWebApp/dashboardIll.png"
import TopRightArrow from "../../../assets/mentroWebApp/topRightArrow.svg"
import ExportDash from "../../../assets/mentroWebApp/withdrawDash.svg"
import CalendarDash from "../../../assets/mentroWebApp/calendarDash.svg"
import SessionDash from "../../../assets/mentroWebApp/sessionDash.svg"
import Google from "../../../assets/auth/google.svg"
import ErrorMessage from "../../../assets/mentroWebApp/errorMessage.svg"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { getHttpHandler, postHttpHandler } from "../../../utils/httpHandler"
import { Link, navigate } from "gatsby"
import SharePopup from "../../Popups/SharePopup"
import Cookies from "js-cookie"
import {
  logGa,
  mixpanelEvent,
  mixpanelTimeEvent,
} from "../../../../services/analytics"

const { Title, Text } = Typography

const payoutDetails = [
  {
    label: "Account Name",
    paymentDetail: "accName",
    placeholder: "Enter your Account Name",
  },
  {
    label: "Account Number",
    paymentDetail: "accNum",
    placeholder: "Enter your Account Number",
  },
  {
    label: "Account IFSC",
    paymentDetail: "accIFSC",
    placeholder: "Enter your Account IFSC",
  },
  {
    label: "UPI",
    paymentDetail: "upi",
    placeholder: "Enter your UPI",
  },
]

const MentorDashboard = () => {
  const { token } = useLoggedInUserStore()
  const [mentorDashData, setMentorDashData] = useState<any>(null)
  const [isCalendarLoading, setIsCalendarLoading] = useState<boolean>(false)
  const [withDrawStatusLoading, setWithDrawStatusLoading] =
    useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isModalSaveLoading, setIsModalSaveLoading] = useState<boolean>(false)
  const [isSharePopupVisible, setIsSharePopupVisible] = useState<boolean>(false)
  const onBoardingStatus: any = JSON.parse(
    Cookies.get("onBoardingStatus") || `{ "shouldOpen": true, "opened": false }`
  )
  const [onBoardStatus, setOnBoardStatus] = useState<{
    shouldOpen: boolean
    opened: boolean
  }>(onBoardingStatus)

  const [payoutForm] = Form.useForm()

  const withDrawPayout = async () => {
    setWithDrawStatusLoading(true)
    try {
      const responseData = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/payout`,
        idToken: token,
      })
      getMentorsData()
      if (responseData.success) {
        return setWithDrawStatusLoading(false)
      }
      message.error(responseData.message)
      setWithDrawStatusLoading(false)
    } catch (error: any) {
      setWithDrawStatusLoading(false)
      message.error("Something went wrong")
    }
  }

  const getMentorsData = async () => {
    try {
      const mentorsResponseData = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/getMentorDataForDashboard`,
        idToken: token,
      })
      setMentorDashData(mentorsResponseData.data)
      checkForPopup(onBoardStatus, mentorsResponseData.data)

      let payoutDetails = mentorsResponseData.data.payoutDetails

      payoutForm.setFields([
        ...Object.keys(payoutDetails).map(pKey => ({
          name: pKey,
          value: payoutDetails[pKey],
        })),
      ])
    } catch (error: any) {
      //
    }
  }

  const setupCalendar = async () => {
    setIsCalendarLoading(true)
    try {
      const mentorsResponseData = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/getMentorDataForDashboard`,
        idToken: token,
      })
      setMentorDashData(mentorsResponseData.data)
      setIsCalendarLoading(false)
      if (mentorsResponseData.data.setupCalendar) {
        navigate("/web/calendar")
      } else {
        window.open(
          `${process.env.GATSBY_BE_URL}/mentor/calendar/setup?authorization=Bearer ${token}`
        )
      }
    } catch (error: any) {
      setIsCalendarLoading(false)
      message.error("Something went wrong")
    }
  }

  const setPayout = async values => {
    const { accName, accNum, accIFSC, upi } = values
    const body = {
      accName: accName || "",
      accNum: accNum || "",
      accIFSC: accIFSC || "",
      upi: upi || "",
    }

    if (upi || (accName && accNum && accIFSC)) {
      setIsModalSaveLoading(true)
      try {
        const responseData = await postHttpHandler({
          url: `${process.env.GATSBY_BE_URL}/mentor/setPayoutInfo`,
          idToken: token,
          body,
        })

        setIsModalVisible(false)
        getMentorsData()
        if (responseData.success) {
          payoutForm.resetFields()
          setIsModalSaveLoading(false)
          return message.success("Payment Details Edited Successfully!!!")
        }
        setIsModalSaveLoading(false)
        message.error("Something went wrong")
      } catch (error) {
        setIsModalSaveLoading(false)
        message.error("Something went wrong")
      }
    } else {
      message.error("Please enter complete Account details OR Upi Id.")
    }
  }

  useEffect(() => {
    if (token) {
      getMentorsData()

      mixpanelTimeEvent("page_view_dashboard")
    }
    return () => {
      mixpanelEvent("page_view_dashboard", {
        screen_name: "dashboard",
      })
    }
  }, [token])

  const checkForPopup = (onBoardStatus, mentorData) => {
    if (mentorData.onBoardingStatus === "verified") {
      setOnBoardStatus({
        ...onBoardStatus,
        shouldOpen: false,
      })
      Cookies.set(
        "onBoardingStatus",
        JSON.stringify({
          ...onBoardStatus,
          shouldOpen: false,
        }),
        {
          expires: 365,
        }
      )
    } else {
      if (onBoardStatus.shouldOpen && !onBoardStatus.opened) {
        setIsSharePopupVisible(true)
        logGa("POPUP_SHOWN", {
          popup_type: "share_self_profile_popup",
        })
        setOnBoardStatus({
          ...onBoardStatus,
          opened: true,
        })
        Cookies.set(
          "onBoardingStatus",
          JSON.stringify({
            ...onBoardStatus,
            opened: true,
          }),
          {
            expires: 1,
          }
        )
      }
    }
  }

  return (
    <div className="mentorDashboardParent">
      <SharePopup
        isSharePopupVisible={isSharePopupVisible}
        setIsSharePopupVisible={setIsSharePopupVisible}
      />
      <Modal
        className="editSessionModal"
        visible={isModalVisible}
        title={<Text className="modalHeading">Edit Payment Details</Text>}
        onOk={setPayout}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form
          form={payoutForm}
          name="basic"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={setPayout}
          onFinishFailed={error => {}}
          autoComplete="off"
        >
          {payoutDetails.map((paymentDetail, ind) => (
            <>
              {paymentDetail.paymentDetail === "upi" && (
                <div className="sep">
                  <span className="or">OR</span>
                </div>
              )}
              <Form.Item
                key={ind}
                label={<Text className="labelText">{paymentDetail.label}</Text>}
                name={paymentDetail.paymentDetail}
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <Input placeholder={paymentDetail.placeholder} />
              </Form.Item>
            </>
          ))}
          <div className="actionsDiv">
            <Form.Item>
              <Button
                type="text"
                className="saveBtn"
                htmlType="submit"
                loading={isModalSaveLoading}
              >
                Save Details
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <div className="mentorDashboard">
        <Row className="top" gutter={[10, 10]}>
          <Col className="topLeft" lg={18} md={24}>
            <div>
              <Title className="greet">
                Good Day, {mentorDashData?.name || "Mentor"}
              </Title>
              <Title className="subText">
                Welcome to your Dashboard! Here you can manage your sessions,
                calendar, payouts and more.
              </Title>
            </div>
            <Link to="/web/profile" className="profileLinkBtn">
              <Button type="text" className="profileBtn">
                Visit Profile
                <img src={TopRightArrow} alt="icon" />
              </Button>
            </Link>
          </Col>
          <Col lg={6} className="dashboardIllCol">
            <img
              src={DashboardIll}
              alt="dashboardIll"
              className="dashboardIll"
            />
          </Col>
        </Row>
        <div className="bottom">
          <div className="card">
            <div className="bg">
              <div>
                <div className="head">
                  <div className="iconDiv">
                    <img src={SessionDash} alt="icon" />
                  </div>
                  <Text className="headText">Session Details</Text>
                </div>
                <Title className="cardSubText">
                  Check your scheduled sessions or edit their details
                </Title>
              </div>
              <Link to="/web/sessions" className="linkBtn">
                <Button type="text" className="cardBtn">
                  View Sessions
                  <img src={TopRightArrow} alt="icon" />
                </Button>
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="bg">
              <div>
                <div className="head">
                  <div className="iconDiv">
                    <img src={ExportDash} alt="icon" />
                  </div>
                  <Text className="headText">Withdraw</Text>
                </div>
                <Title className="cardSubText withdrawAmount">
                  ₹ {mentorDashData?.income || "--"}
                </Title>
              </div>
              <div className="withdrawBtns">
                <Button
                  type="text"
                  className="rightBtn editBtn"
                  onClick={() => {
                    setIsModalVisible(true)
                  }}
                >
                  Edit Details
                </Button>
                <Button
                  type="text"
                  className="rightBtn"
                  onClick={withDrawPayout}
                  loading={withDrawStatusLoading}
                >
                  Withdraw
                </Button>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="bg">
              <div>
                <div className="head">
                  <div className="iconDiv">
                    <img src={CalendarDash} alt="icon" />
                  </div>
                  <Text className="headText">Calendar</Text>
                </div>
                <Title className="cardSubText">
                  Set up your calendar as per your schedule
                </Title>
              </div>
              {!mentorDashData?.setupCalendar ? (
                <Button
                  onClick={setupCalendar}
                  loading={isCalendarLoading}
                  className="googleAuthBtn"
                >
                  <img src={Google} alt="google logo" />
                  <Text>Sign in with google</Text>
                </Button>
              ) : (
                <Button
                  type="text"
                  className="cardBtn"
                  onClick={setupCalendar}
                  loading={isCalendarLoading}
                >
                  Schedule
                  <img src={TopRightArrow} alt="icon" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MentorDashboard
