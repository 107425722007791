import React, { useEffect, useState, useContext } from "react"
import "./CommunityPostComment.less"
import { message, Typography } from "antd"
import moment from "moment"
import { navigate } from "gatsby"
import {
  CommentImageModel,
  CommentTextModel,
} from "./CommunityPostCommentModels"
import Guest from "../../../assets/mentroWebApp/defaultUser.svg"
import LikeImg from "../../../assets/mentroWebApp/likePost.svg"
import LikedImg from "../../../assets/mentroWebApp/likedPost.svg"
//import { CommunityContext } from "../../../context/CommunityContext"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import useCommunityData from "../../ZustandStateManagment/Communities/store"
import { postHttpHandler } from "../../../utils/httpHandler"

const { Title, Text } = Typography

const CommunityPostReply = ({
  setIsReply = pVal => {},
  reply,
  shadowComment = false,
  borderRadius = true,
  noMarginComment = true,
  setIsAuthPopupVisible = pVal => {},
}) => {
  const { reactToReply } = useCommunityData();
  const { isLoggedIn, token } = useLoggedInUserStore()
  const [reactionStatus, setReactionStatus] = useState<boolean>(false)
  const [reactionCount, setReactionCount] = useState<number>(0)

  useEffect(() => {
    setReactionStatus(reply?.hasLiked)
    setReactionCount(reply?.like)
  }, [reply])

  return (
    <div
      className={`communityPostCommentDiv ${
        noMarginComment && "noMarginComment"
      } ${shadowComment && "shadowComment"}  ${
        borderRadius && "borderRadiusComment"
      }`}
      key={reply?.id}
    >
      <div
        className="topDiv"
        onClick={() => {
          const profileId = reply?.replier?.profileId
          if (profileId) {
            navigate(`/web/@${profileId}`)
          }
        }}
      >
        <img src={reply?.replier?.image || Guest} alt="userImg" />
        <div className="content">
          <Title className="name">{reply?.replier?.name}</Title>
          <Text className="timeSpan">
            {moment(reply?.lastUpdated).fromNow()}
          </Text>
        </div>
      </div>
      <div className="bodyDiv">
        <div className="bodyTop">
          {reply?.text && <CommentTextModel text={reply?.text} />}
          {reply?.image && <CommentImageModel image={reply?.image} />}
        </div>
        <div className="bottomDiv">
          <div
            className="actionDiv"
            onClick={ async (e) => {
              e.preventDefault()
              if (!isLoggedIn) return setIsAuthPopupVisible(true)
              if (reactionStatus) {
                setReactionCount(reactionCount - 1)
              } else {
                setReactionCount(reactionCount + 1)
              }
              setReactionStatus(!reactionStatus)
              reactToReply(
                reply?.postId,
                reply?.commentId,
                reply?.id,
                reactionStatus ? "Unlike" : "Like"
              )
                try {
                  const likeReplyResp = await postHttpHandler({
                    url: `${process.env.GATSBY_BE_URL}/community/reply/like`,
                    idToken: token,
                    body: {
                      postId: reply?.postId,
                      commentId: reply?.commentId,
                      replyId: reply?.id,
                      status: reactionStatus ? "Unlike" : "Like",
                    },
                  })
                  } catch (error) {
                  message.error("Something went wrong!")
                }
            }}
          >
            <Text className="actionText">Likes</Text>
            {reactionStatus ? (
              <img src={LikedImg} alt="like reply" className="actionImg" />
            ) : (
              <img src={LikeImg} alt="like reply" className="actionImg" />
            )}
            <Text className={`actionCount ${reactionStatus && "greenCount"}`}>
              {reactionCount}
            </Text>
          </div>
          <Text className="divider">|</Text>
          <div className="actionDiv" onClick={() => setIsReply(true)}>
            <Text className="actionText">Reply</Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunityPostReply
