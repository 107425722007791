import React, { useContext } from "react"
import "./OpportunitiesFilter.less"
import { Checkbox, Typography } from "antd"
import { JobsContext } from "../../../context/JobsContext"

const { Title, Text } = Typography

const filters = [
  {
    filterKey: "companyType",
    filterName: "Company Type",
    filterOptions: [
      { label: <Text className="filterLabel">Product</Text>, value: "product" },
      { label: <Text className="filterLabel">Service</Text>, value: "service" },
    ],
    defaultOption: [""],
  },
  {
    filterKey: "roleType",
    filterName: "Role Type",
    filterOptions: [
      {
        label: <Text className="filterLabel">Full Time</Text>,
        value: "fullTime",
      },
      { label: <Text className="filterLabel">Intern</Text>, value: "intern" },
    ],
    defaultOption: [""],
  },
]

const OpportunitiesFilter = () => {
  const {
    setJobOpportunities,
    setCompanyType,
    companyType,
    setRoleType,
    roleType,
    setDocId,
  } = useContext(JobsContext)

  const onChange = (e, filterKey) => {
    setJobOpportunities([])
    setDocId("")

    if (filterKey === "companyType") {
      if (e.length === 2)
        if (companyType === "product") setCompanyType(e[1])
        else setCompanyType(e[0])
      else if (e.length === 1) setCompanyType(e[0])
      else setCompanyType("")
    } else if (filterKey === "roleType") {
      if (e.length === 2)
        if (roleType === "fullTime") setRoleType(e[1])
        else setRoleType(e[0])
      else if (e.length === 1) setRoleType(e[0])
      else setRoleType("")
    }
  }

  return (
    <div className="oppFilterDiv">
      {filters.map(filter => {
        return (
          <div className="filterBox" key={filter.filterKey}>
            <Title className="headText">{filter.filterName}</Title>
            <Checkbox.Group
              options={filter.filterOptions}
              defaultValue={filter.defaultOption}
              value={
                filter.filterKey === "companyType" ? [companyType] : [roleType]
              }
              onChange={e => onChange(e, filter.filterKey)}
            />
          </div>
        )
      })}
    </div>
  )
}

export default OpportunitiesFilter
