import { useState, useEffect } from "react"
// import { AuthContext } from "../context/AuthContext"
import { getHttpHandler } from "../utils/httpHandler"
const tzoffset = "%2B0530"
import { useLoggedInUserStore } from "../components/ZustandStateManagment/UserData/store"
export const useCalendar = () => {
  const [events, setEvents] = useState<any>({})
  const [dayName, setDayName] = useState<number>(new Date().getDay())
  const [day, setDay] = useState<number>(new Date().getDate())
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1)
  const [year, setYear] = useState<number>(new Date().getFullYear())
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [dataReceived, setDataReceived] = useState<boolean>(false)
  const [currentEvents, setCurrentEvents] = useState<any[]>([])

  const { token } = useLoggedInUserStore()
  const getEvents = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    setError(false)
    setEvents([])
    setCurrentEvents([])
    setDataReceived(false)
    try {
      const eventsData = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/calendar/getEvents?year=${year}&tzoffset=${tzoffset}&month=${month}`,
        idToken: token,
      })
      setEvents(eventsData.events)
      setAllEvents(eventsData.events)
      setError(false)
      setLoading(false)
      setDataReceived(true)
    } catch (error) {
      setError(true)
      setLoading(false)
      setDataReceived(false)
    }
  }

  const setAllEvents = events => {
    Object.keys(events).map((key, index) => {
      if (day.toString() === key.toString()) {
        setCurrentEvents(events[key])
      }
    })
  }

  useEffect(() => {
    getEvents()
  }, [token, day, month, year])

  return {
    events,
    dayName,
    setDayName,
    day,
    setDay,
    month,
    setMonth,
    year,
    setYear,
    loading,
    dataReceived,
    setAllEvents,
    currentEvents,
    getEvents,
  }
}
