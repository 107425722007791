export const programFaqs = [
  {
    question: "When does the program start and what are the timings?",
    answer:
      "You will get an email initation of the program details. The live sessions will be held on Saturdays and Sundays for everyone to attend.",
  },
  {
    question: "Will this be live or pre-recorded?",
    answer:
      "This is a LIVE, interactive batch with personal attention opportunities with the mentor.",
  },
  {
    question: "Since the program is live, will you be sharing recordings?",
    answer: "Yes, the recordings will be shared so that you learn at self-pace as well.",
  },
  {
    question: "I made the payment but didn't receive any confirmation email.",
    answer:
      'Drop a mail on contact@mentro.tech for any kind of queries. We will respond within the day and sort out the issue.',
  },
  {
    question: "Will I get Job or internship support?",
    answer:
      "Yes, we will get you connected with potential HRs at MNCs and Top tech startups of India for interviews.",
  },
]
