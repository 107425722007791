import React, { useContext, useEffect, useState } from "react"
import "./EditSessionsPanel.less"
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Switch,
  Typography,
} from "antd"
import { getSessionIcon } from "../../../utils/data"
import PencilEdit from "../../../assets/mentroWebApp/pencil.svg"
import BackButton from "../../../assets/mentroWebApp/backButton.svg"
import ErrorMessage from "../../../assets/mentroWebApp/errorMessage.svg"
import DeleteIcon from "../../../assets/mentroWebApp/deleteSlots.svg"
import { patchHttpHandler, postHttpHandler } from "../../../utils/httpHandler"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { logGa } from "../../../../services/analytics"

const { Title, Text } = Typography
const { TextArea } = Input
const { Option } = Select

const EditSessionsPanel = ({
  setIsSharePopupVisible,
  onBoardingStatus,
  sessionList,
  getSessionData,
  isEditSession,
  setIsEditSession,
}) => {
  const [isEditSessionLoading, setIsEditSessionLoading] =
    useState<boolean>(false)
  const [currentSession, setCurrentSession] = useState<any>(null)
  const [editSessionsForm] = Form.useForm()
  const { token } = useLoggedInUserStore()

  const createNewSession = async (values: {
    amount: any
    description: any
    isEnabled: any
    title: any
    questions
    duration: number
  }) => {
    const {
      amount,
      description,
      isEnabled,
      title,
      questions: newQuestions,
      duration,
    } = values

    const newQ = newQuestions.map((q: { question: any; isRequired: any }) => ({
      isRequired: q.isRequired || false,
      title: q.question,
    }))

    const body = {
      description,
      amount,
      isEnabled,
      iconUrl: "",
      title,
      questions: newQ,
      duration,
    }

    setIsEditSessionLoading(true)
    try {
      const saveResponse = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/createNewSessionType`,
        body,
        idToken: token,
      })
      setIsEditSessionLoading(false)
      if (saveResponse.success) {
        setIsEditSession(false)
        setCurrentSession(null)
        editSessionsForm.resetFields()
        message.success("New Session created successfully")
        getSessionData()
      } else {
        message.error(saveResponse.message)
      }
    } catch (error) {
      setIsEditSessionLoading(false)
      message.error("Something went wrong")
    }
  }

  const saveSessionDetails = async (values: {
    amount: any
    description: any
    isEnabled: any
    title: any
    questions: any
    duration: number
  }) => {
    const {
      amount,
      description,
      isEnabled,
      title,
      questions: newQuestions,
      duration,
    } = values

    const newQ = newQuestions.map(
      (q: { question: any; isRequired: any }, ind) => ({
        isRequired: q.isRequired || false,
        title: q.question,
      })
    )

    const body = {
      amount,
      description,
      questions: newQ,
      isEnabled,
      sessionTypeId: currentSession.id,
      title,
      duration,
    }

    setIsEditSessionLoading(true)
    try {
      const saveResponse = await patchHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/editNewSessionType`,
        body,
        idToken: token,
      })
      setIsEditSessionLoading(false)
      if (saveResponse.success) {
        setIsEditSession(false)
        setCurrentSession(null)
        editSessionsForm.resetFields()
        getSessionData()
      } else {
        message.error(saveResponse.message)
      }
    } catch (error) {
      message.error("Something went wrong")
      setIsEditSessionLoading(false)
    }
  }

  useEffect(() => {
    if (currentSession) {
      editSessionsForm.setFields([
        { name: "title", value: currentSession.title },
        { name: "description", value: currentSession.description },
        { name: "isEnabled", value: currentSession.isEnabled },
        { name: "amount", value: currentSession.amount },
        { name: "duration", value: currentSession.duration },
      ])
    }
  }, [isEditSession])

  return (
    <div className="editSessionsPanel">
      <div
        className="backDiv"
        onClick={() => {
          if (isEditSession) {
            setIsEditSession(false)
            editSessionsForm.resetFields()
            editSessionsForm.setFieldsValue({ questions: [] })
            setCurrentSession(null)
          }
        }}
      >
        {isEditSession && <img src={BackButton} alt="back icon" />}
        <Title className="editSessionTitle">
          {isEditSession
            ? currentSession.id
              ? "Edit Session Details"
              : "Create New Session"
            : "Edit Your Sessions"}
        </Title>
      </div>
      {!isEditSession ? (
        <Row className="allSessionsRow">
          <Title
            className="moreSessions"
            onClick={() => {
              if (!onBoardingStatus) {
                setIsSharePopupVisible(true)
                logGa("POPUP_SHOWN", {
                  popup_type: "share_self_profile_popup",
                })
              }
              setCurrentSession({
                id: "",
                description: "",
                amount: "100.00",
                iconUrl: "",
                title: "",
                isEnabled: true,
                canEdit: true,
                questions: [
                  {
                    title: "Provide a sharable link to your resume",
                    isRequired: true,
                  },
                ],
                duration: 45,
              })
              editSessionsForm.setFieldsValue({
                questions: [
                  {
                    title: "Provide a sharable link to your resume",
                    isRequired: true,
                  },
                ],
              })
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              })
              setIsEditSession(true)
            }}
          >
            + CREATE NEW SESSION
          </Title>
          {sessionList?.length > 0 ? (
            sessionList?.map((sessionData: any) => (
              <Col
                className={`sessionCol ${
                  !sessionData.isEnabled && "sessionNotEnabled"
                }`}
                key={sessionData.id}
                onClick={() => {
                  if (!onBoardingStatus) {
                    setIsSharePopupVisible(true)
                    logGa("POPUP_SHOWN", {
                      popup_type: "share_self_profile_popup",
                    })
                  }
                  setCurrentSession(sessionData)
                  editSessionsForm.setFieldsValue({
                    questions: sessionData.questions.map(q => ({
                      title: q.title,
                      isRequired: q.isRequired,
                    })),
                  })
                  setIsEditSession(true)
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })
                }}
              >
                <div className="content">
                  <span className="iconBg">
                    <img
                      src={getSessionIcon(sessionData.title)}
                      alt="session icon"
                    />
                  </span>
                  <div className="sessionInfo">
                    <Title className="sessionTitle">{sessionData.title}</Title>
                    <Title className="costTitle">₹{sessionData.amount}</Title>
                  </div>
                </div>
                <img src={PencilEdit} alt="rightArrow" className="pencilEdit" />
              </Col>
            ))
          ) : (
            <Col className="sessionCol">
              <div className="content">
                <Skeleton.Avatar
                  className="leftIconSkel"
                  active
                  shape="square"
                />
                <div className="sessionInfo">
                  <Skeleton.Button size="small" active className="sSkel1" />
                  <Skeleton.Button size="small" active className="sSkel2" />
                </div>
              </div>
              <Skeleton.Avatar active />
            </Col>
          )}
        </Row>
      ) : (
        <div className="editSessionsDiv">
          <Form
            form={editSessionsForm}
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={currentSession.id ? saveSessionDetails : createNewSession}
            onFinishFailed={error => {}}
            autoComplete="off"
            className="editSessionsForm"
          >
            <div className="topForm">
              <Form.Item
                label={
                  <Text className="questionLabel">Enable this Session</Text>
                }
                // initialValue={currentSession.isEnabled}
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 8 }}
                name="isEnabled"
                className="isEnabled"
              >
                <Switch defaultChecked={currentSession.isEnabled} />
              </Form.Item>
              <Form.Item
                label={
                  <Text className="questionLabel">NAME OF THE SESSION</Text>
                }
                name="title"
                rules={[
                  {
                    required: true,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Enter name of the Session
                      </Text>
                    ),
                  },
                ]}
              >
                <Input placeholder="Enter name of the Session" />
              </Form.Item>
              <Form.Item
                label={<Text className="questionLabel">DESCRIPTION</Text>}
                name="description"
                // initialValue={currentSession.description}
                rules={[
                  {
                    required: true,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Enter description
                      </Text>
                    ),
                  },
                ]}
              >
                <TextArea rows={4} placeholder="Enter description" />
              </Form.Item>
              <Form.Item
                label={<Text className="questionLabel">DURATION</Text>}
                name="duration"
                // initialValue={currentSession.duration}
                rules={[
                  {
                    required: true,
                    message: (
                      <Text className="errorMessage">
                        <img src={ErrorMessage} alt="errorMessage" />
                        Select a duration
                      </Text>
                    ),
                  },
                ]}
              >
                <Select style={{ width: "100%" }}>
                  <Option value={15}>15 minutes</Option>
                  <Option value={30}>30 minutes</Option>
                  <Option value={45}>45 minutes</Option>
                  <Option value={60}>60 minutes</Option>
                </Select>
              </Form.Item>
              <div className="questionsDiv">
                <Text className="questionLabel">WRITE YOUR QUESTIONS</Text>
                <Form.List name="questions">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, ind) => {
                            return (
                              <Row key={key} className="questDiv">
                                <Form.Item
                                  {...restField}
                                  fieldKey={[fieldKey, "isRequired"]}
                                  name={[name, "isRequired"]}
                                  labelCol={{ span: 22 }}
                                  wrapperCol={{ span: 2 }}
                                  label={
                                    <Text className="questionLabel">
                                      Required
                                    </Text>
                                  }
                                  className="isRequired"
                                >
                                  <Switch
                                    defaultChecked={
                                      currentSession.questions?.[ind]
                                        ?.isRequired || false
                                    }
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  fieldKey={[fieldKey, "question"]}
                                  name={[name, "question"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: (
                                        <Text className="errorMessage">
                                          <img
                                            src={ErrorMessage}
                                            alt="errorMessage"
                                          />
                                          Enter a Question
                                        </Text>
                                      ),
                                    },
                                  ]}
                                  initialValue={
                                    currentSession.questions?.[ind]?.title || ""
                                  }
                                  className="questionsFormItem"
                                >
                                  <Input />
                                </Form.Item>
                                <Button
                                  type="text"
                                  className="delQuestBtn"
                                  onClick={() => remove(name)}
                                >
                                  <img src={DeleteIcon} alt="delete question" />
                                </Button>
                              </Row>
                            )
                          }
                        )}
                        <Button
                          type="text"
                          onClick={() => add()}
                          className="moreQuestion"
                        >
                          + ADD MORE QUESTION
                        </Button>
                      </>
                    )
                  }}
                </Form.List>
              </div>
              <div className="priceDiv">
                <Text className="priceDetailsText">Price Details</Text>
                <div className="priceFormItemDiv">
                  <Form.Item
                    label={
                      <Text className="questionLabel">EDIT YOUR PRICE</Text>
                    }
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: (
                          <Text className="errorMessage">
                            <img src={ErrorMessage} alt="errorMessage" />
                            Enter a price
                          </Text>
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter a price"
                      disabled={!onBoardingStatus}
                    />
                  </Form.Item>
                  <span className="rupeeText">₹</span>
                </div>
              </div>
            </div>
            <Button
              type="text"
              htmlType="submit"
              className="saveBtn"
              loading={isEditSessionLoading}
            >
              Save Changes
            </Button>
          </Form>
        </div>
      )}
    </div>
  )
}

export default EditSessionsPanel
