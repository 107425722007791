import React from "react"
import "./MentorCardSkeletonMobile.less"
import { Button, Col, Row, Skeleton } from "antd"

const MentorCardSkeletonMobile = () => {
  return (
    <div className="mobileMentorCardSkel">
      <Row className="top">
        <Col>
          <Skeleton.Avatar active className="mentorProfilePic" size="small" />
        </Col>
        <Col xs={19}>
          <div className="mentorInfo">
            <div className="headDiv">
              <Skeleton.Input active className="nameText" size="small" />
              <Skeleton.Button active size="small" />
            </div>
            <Skeleton.Input active className="subText" size="small" />
          </div>
        </Col>
      </Row>
      <div className="interestsDiv">
        <Skeleton.Button active size="small" className="interest" />
        <Skeleton.Button active size="small" className="interest" />
      </div>
      <Row className="btns" gutter={[15, 15]}>
        <Col xs={12}>
          <Skeleton.Button active size="small" className="btn" />
        </Col>
        <Col xs={12}>
          <Skeleton.Button active size="small" className="btn" />
        </Col>
      </Row>
    </div>
  )
}

export default MentorCardSkeletonMobile
