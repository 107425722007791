import React from "react"
import "./CommunityPostComment.less"
import { Typography } from "antd"
import { linkify } from "../../../utils/data"

const { Text } = Typography

export const CommentTextModel = ({ text }) => {
  // // const READ_MORE_LENGTH = 150
  // // const [isReadMoreClicked, setIsReadMoreClicked] = useState(false)
  // // const handleReadMoreClick = () => setIsReadMoreClicked(!isReadMoreClicked)

  // return (
  //   <>
  //     <Text
  //       className={`commentText ${
  //         textModel?.text?.length > READ_MORE_LENGTH &&
  //         !isReadMoreClicked &&
  //         "hideText"
  //       }`}
  //     >
  //       {linkify(textModel.text)}
  //     </Text>
  //     {textModel?.text?.length > READ_MORE_LENGTH && !isReadMoreClicked && (
  //       <Text onClick={() => handleReadMoreClick()} className="learnMore">
  //         ... Learn more
  //       </Text>
  //     )}
  //   </>
  // )
  return <Text className={`commentText`}>{linkify(text)}</Text>
}

export const CommentImageModel = ({ image }) => (
  <div className="commentImageModel">
    <img src={image} className="commentImage" alt="imageModel" />
  </div>
)
