import React, { useState } from "react"
import "./Signup.less"
import firebase from "firebase"
import { useLocation } from "@reach/router"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { Typography, Form, Input, Button, Progress, message } from "antd"
import BackIcon from "../../../assets/auth/backIcon.svg"
import Google from "../../../assets/auth/google.svg"
import ErrorMessage from "../../../assets/auth/errorMessage.svg"
import { Link, navigate } from "gatsby"
import useSignupStore from "../../ZustandStateManagment/UserData/store"

import {
  signUpApiAuth,
  signUpApi,
  signUpApiAuthGoogle,
  signUpApiGoogle,
} from "../../ZustandStateManagment/UserData/Api"
// import Resources from "../../Tabs/Resources"
const { Text, Title } = Typography

const phoneRegex =
  /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

const Signup = ({ popUp = false, onAuthComplete = () => {} }) => {
  const formData1 = useSignupStore()
  // const loggedIn = useLoggedInUserStore()
  const state: any = useLocation().state
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  // const authContext = useLoggedInUserStore()
  const [current, setCurrent] = useState(0)
  const [phoneNum, setPhoneNum] = useState("")
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    phoneNo: "",
    imageUrl: "",
  })
  const addUser = useSignupStore(state => state.addUser)
  const addUserData = useLoggedInUserStore(state => state.addUserData)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const onFinishProfile = values => {
    const { userName, phoneNo } = values
    addUser({ ...formData1.formData, userName, phoneNo })
    setFormData({ ...formData, userName, phoneNo })
    next()
  }
  const onFinishAuthData = async values => {
    const { email, password } = values

    addUser({ ...formData1.formData, email, password })
    setFormData({ ...formData, email, password })
    setIsLoading(true)
    let idToken: string | undefined
    let [result, status] = await signUpApiAuth(email, password)

    if (status) {
      idToken = await result?.user?.getIdToken()
    } else {
      setIsLoading(false)
      if (result.code === "auth/email-already-in-use") {
        return message.error("Email is already used!")
      } else {
        return message.error("Something went wrong")
      }
    }

    try {
      let loginData = await signUpApi(idToken, formData1, email, password)
      // loginData = loginData.json()
      if (loginData.token) {
        const { mentor, mentorData, token, user, communities } = loginData

        addUserData(mentor, mentorData, token, user, communities, true)

        setIsLoading(false)
        // if (user.isReferral) {
        //   navigate("/referral")
        // } else {
        if (state && state.navigatedFrom) {
          return navigate(state.navigatedBackTo)
        }
        if (popUp) {
          return onAuthComplete()
        }
        navigate("/web/")
        // }
      } else {
        message.error("Something went wrong!!!")
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error, "{][]")
      return message.error("Something went wrong")
    }
  }

  const googleProvider = new firebase.auth.GoogleAuthProvider()

  const signUpWithGoogle = async () => {
    setIsGoogleLoading(true)

    let idToken: string | undefined
    let [result, status] = await signUpApiAuthGoogle(googleProvider)

    if (status) {
      idToken = await result?.user?.getIdToken()
    } else {
      setIsGoogleLoading(false)
      if (result.code === "auth/popup-closed-by-user") {
        return message.error("Popup was closed")
      } else {
        return message.error("Something went wrong")
      }
    }

    // if (!phoneRegex.test(phoneNum)) {
    //   form.validateFields(["phoneNo"])
    //   // return message.error("Please enter valid phone number")
    //   return
    // }
    try {
      let loginData = await signUpApiGoogle(phoneNum, idToken)
      if (loginData.token) {
        if (loginData.userNotFound) {
          return message.error("Failure")
        }
        setPhoneNum("")
        const { mentor, mentorData, token, user, communities } = loginData
        addUserData(mentor, mentorData, token, user, communities, true)

        setIsGoogleLoading(false)

        // if (user.isReferral) {
        // navigate("/referral")
        // } else {
        if (state && state.navigatedFrom) {
          return navigate(state.navigatedBackTo)
        }
        if (popUp) {
          return onAuthComplete()
        }
        navigate("/web/")
        // }
      } else {
        message.error("Something went wrong!!!")
        return setIsGoogleLoading(false)
      }
    } catch (error) {
      setIsGoogleLoading(false)
      return message.error("Something went wrong")
    }
    setIsGoogleLoading(false)
  }

  const steps = [
    {
      title: "profileData",
      content: (
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishProfile}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={<Text className="text">NAME</Text>}
            name="userName"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Name
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your Name" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">PHONE NUMBER</Text>}
            name="phoneNo"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: false,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Phone Number
                  </Text>
                ),
              },
              {
                pattern:
                  /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a valid Phone Number
                  </Text>
                ),
              },
            ]}
          >
            <Input
              placeholder="Enter your Phone Number"
              onChange={e => setPhoneNum(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <div className="continueDiv">
              <Button
                type="text"
                htmlType="submit"
                className="continueBtn"
                loading={isLoading}
              >
                Next
              </Button>
            </div>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "authData",
      content: (
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishAuthData}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={<Text className="text">EMAIL</Text>}
            name="email"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Email
                  </Text>
                ),
              },
              {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a valid Email
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your Email" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">CREATE PASSWORD</Text>}
            name="password"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a Password
                  </Text>
                ),
              },
              {
                min: 6,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter at least 6 characters
                  </Text>
                ),
              },
            ]}
          >
            <Input type="password" placeholder="Enter your Password" />
          </Form.Item>
          <div className="buttonsDiv">
            <Button type="text" className="backBtn" onClick={prev}>
              Back
            </Button>
            <Button type="text" className="backIconBtn" onClick={prev}>
              <img src={BackIcon} alt="back icon" />
            </Button>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="continueBtn"
                loading={isLoading}
              >
                Continue
              </Button>
            </Form.Item>
          </div>
        </Form>
      ),
    },
  ]

  return (
    <div className="signUpFormDiv">
      {/* <Steps current={current} className="stepsAntd">
        {steps.map((item, index) => (
          <Step
            key={item.title}
            className="stepAntd"
            icon={
              current >= index ? (
                <img src={AuthStepIcon} alt="checked" />
              ) : (
                <img src={AuthStepEmptyIcon} alt="un checked" />
              )
            }
          />
        ))}
      </Steps> */}
      <Text className="progressCount">{current + 1}/2</Text>
      <Progress
        showInfo={false}
        percent={(current * 100 + 2) / 2}
        strokeColor="#4caf50"
      />
      <Title className="signupTitle">Sign Up to Mentro.</Title>
      <div className="steps-content">{steps[current].content}</div>
      <div className="sep">
        <span className="or">OR</span>
      </div>
      <div className="googleAuth">
        <Button onClick={signUpWithGoogle} loading={isGoogleLoading}>
          <img src={Google} alt="google logo" />
          <Text>Sign up with google</Text>
        </Button>
      </div>
      <div className="policyDiv">
        <Text className="text">
          By continuing, you agree to Mentro's{" "}
          <span
            onClick={() =>
              window.open(`${process.env.GATSBY_HOME_URL}/info/privacy-policy/`)
            }
          >
            Privacy Policy
          </span>
        </Text>
      </div>
    </div>
  )
}
export default Signup
