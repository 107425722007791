import React, { useContext, useState } from "react"
import "./SignupPopup.less"
import firebase from "firebase"
import { useLocation } from "@reach/router"
import { AuthContext } from "../../../../context/AuthContext"
import { app } from "../../../../../../firebaseConfig"
import { Typography, Form, Input, Button, Progress, message } from "antd"
import BackIcon from "../../../../assets/auth/backIcon.svg"
import Google from "../../../../assets/auth/google.svg"
import ErrorMessage from "../../../../assets/auth/errorMessage.svg"
import { Link, navigate } from "gatsby"
import { useLoggedInUserStore } from "../../../ZustandStateManagment/UserData/store"
const { Text, Title } = Typography

const phoneRegex =
  /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

const SignupPopup = ({ popUp = false, onAuthComplete = () => {} }) => {
  const state: any = useLocation().state
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  const authContext = useLoggedInUserStore()
  const [current, setCurrent] = useState(0)
  const [phoneNum, setPhoneNum] = useState("")
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    phoneNo: "",
    imageUrl: "",
  })

  const onFinishAuthData = async values => {
    const { userName, phoneNo, email, password } = values
    setFormData({ ...formData, userName, phoneNo, email, password })
    setIsLoading(true)
    let idToken: string | undefined

    try {
      const response = await app
        .auth()
        .createUserWithEmailAndPassword(email, password)
      idToken = await response?.user?.getIdToken()
    } catch (error: any) {
      setIsLoading(false)
      if (error.code === "auth/email-already-in-use") {
        return message.error("Email is already used!")
      } else {
        return message.error("Something went wrong")
      }
    }

    try {
      const loginResponse = await fetch(
        `${process.env.GATSBY_BE_URL}/users/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            userName: userName,
            email: email,
            password: password,
            phoneNo: phoneNo,
            imageUrl: "",
          }),
        }
      )

      const loginData = await loginResponse.json()
      if (loginData.token) {
        const { mentor, mentorData, token, user, communities } = loginData
        authContext.login(mentor, mentorData, token, user, communities)

        setIsLoading(false)
        // if (user.isReferral) {
        //   navigate("/referral")
        // } else {
        if (state && state.navigatedFrom) {
          return navigate(state.navigatedBackTo)
        }
        if (popUp) {
          return onAuthComplete()
        }
        navigate("/web/")
        // }
      } else {
        message.error("Something went wrong!!!")
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      return message.error("Something went wrong")
    }
  }

  const googleProvider = new firebase.auth.GoogleAuthProvider()

  const signUpWithGoogle = async () => {
    let idToken: string | undefined

    // if (!phoneRegex.test(phoneNum)) {
    //   form.validateFields(["phoneNo"])
    //   // return message.error("Please enter valid phone number")
    //   return
    // }

    setIsGoogleLoading(true)
    try {
      const resp = await app.auth().signInWithPopup(googleProvider)
      idToken = await resp?.user?.getIdToken()
    } catch (error: any) {
      setIsGoogleLoading(false)
      if (error.code === "auth/popup-closed-by-user") {
        return message.error("Popup was closed")
      }
      return message.error("Something went wrong")
    }

    try {
      const loginResponse = await fetch(
        `${process.env.GATSBY_BE_URL}/users/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            phoneNo: phoneNum,
          }),
        }
      )

      const loginData = await loginResponse.json()
      if (loginData.token) {
        if (loginData.userNotFound) {
          return message.error("Failure")
        }
        setPhoneNum("")
        const { mentor, mentorData, token, user, communities } = loginData
        authContext.login(mentor, mentorData, token, user, communities)

        setIsGoogleLoading(false)

        // if (user.isReferral) {
        // navigate("/referral")
        // } else {
        if (state && state.navigatedFrom) {
          return navigate(state.navigatedBackTo)
        }
        if (popUp) {
          return onAuthComplete()
        }
        navigate("/web/")
        // }
      } else {
        message.error("Something went wrong!!!")
        return setIsGoogleLoading(false)
      }
    } catch (error) {
      setIsGoogleLoading(false)
      return message.error("Something went wrong")
    }
    setIsGoogleLoading(false)
  }

  return (
    <div className="signUpPopuFormDiv">
      <Title className="signupTitle">Sign Up to Mentro.</Title>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinishAuthData}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={<Text className="text">NAME</Text>}
          name="userName"
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter your Name
                </Text>
              ),
            },
          ]}
        >
          <Input placeholder="Enter your Name" />
        </Form.Item>
        <Form.Item
          label={<Text className="text">PHONE NUMBER</Text>}
          name="phoneNo"
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: false,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter your Phone Number
                </Text>
              ),
            },
            {
              pattern:
                /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter a valid Phone Number
                </Text>
              ),
            },
          ]}
        >
          <Input
            placeholder="Enter your Phone Number"
            onChange={e => setPhoneNum(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={<Text className="text">EMAIL</Text>}
          name="email"
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter your Email
                </Text>
              ),
            },
            {
              pattern:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter a valid Email
                </Text>
              ),
            },
          ]}
        >
          <Input placeholder="Enter your Email" />
        </Form.Item>
        <Form.Item
          label={<Text className="text">CREATE PASSWORD</Text>}
          name="password"
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter a Password
                </Text>
              ),
            },
            {
              min: 6,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter at least 6 characters
                </Text>
              ),
            },
          ]}
        >
          <Input type="password" placeholder="Enter your Password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="text"
            htmlType="submit"
            className="continueBtn"
            loading={isLoading}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
      <div className="sep">
        <span className="or">OR</span>
      </div>
      <div className="googleAuth">
        <Button onClick={signUpWithGoogle} loading={isGoogleLoading}>
          <img src={Google} alt="google logo" />
          <Text>Sign up with google</Text>
        </Button>
      </div>
      <div className="policyDiv">
        <Text className="text">
          By continuing, you agree to Mentro's{" "}
          <span
            onClick={() =>
              window.open(`${process.env.GATSBY_HOME_URL}/info/privacy-policy/`)
            }
          >
            Privacy Policy
          </span>
        </Text>
      </div>
    </div>
  )
}
export default SignupPopup
