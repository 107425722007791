import React, { useEffect, useState } from "react"
import "./CourseDetail.less"
import { Col, Row, Tag, Typography } from "antd"
import { getHttpHandler, postHttpHandler } from "../../../utils/httpHandler"
import { useParams, useLocation } from "@reach/router"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import CohortCardSkeleton from "../../Cohorts/CohortCardSkeleton"
import AuthPopup from "../../Popups/AuthPopup"
import useMediaQuery from "../../../hooks/useMediaQuery"
import DownloadAppPopup from "../../Popups/DownloadAppPopup"
import Cookies from "js-cookie"
import { COURSES } from "../constants"
import {
  mixpanelEvent,
  mixpanelTimeEvent,
} from "../../../../services/analytics"

const { Title, Text } = Typography
const { CheckableTag } = Tag

const DIFFICULTY_TYPES = {
  BASIC: "Basic",
  INTERMEDIATE: "Intermediate",
  ADVANCED: "Advanced",
}

const DIFFICULTY_TYPES_MAP = {
  [DIFFICULTY_TYPES.BASIC]: "Easy",
  [DIFFICULTY_TYPES.INTERMEDIATE]: "Medium",
  [DIFFICULTY_TYPES.ADVANCED]: "Hard",
}

const CONTENT_TYPES = {
  THEORY: "Theory",
  PROBLEM: "Problem",
}

const CONTENT_TYPES_MAP = {
  [CONTENT_TYPES.THEORY]: "Theory",
  [CONTENT_TYPES.PROBLEM]: "Problem",
}

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const readSVG = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2501_4311)">
      <path
        d="M2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM11 5H4V19H11V5ZM13 5V19H20V5H13ZM14 7H19V9H14V7ZM14 10H19V12H14V10Z"
        fill="#4CAF50"
      />
    </g>
    <defs>
      <clipPath id="clip0_2501_4311">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const videoSVG = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2501_4314)">
      <path
        d="M19.606 6.995C19.53 6.697 19.314 6.472 19.067 6.403C18.63 6.28 16.5 6 12 6C7.5 6 5.372 6.28 4.931 6.403C4.687 6.471 4.471 6.696 4.394 6.995C4.285 7.419 4 9.196 4 12C4 14.804 4.285 16.58 4.394 17.006C4.47 17.303 4.686 17.528 4.932 17.596C5.372 17.72 7.5 18 12 18C16.5 18 18.629 17.72 19.069 17.597C19.313 17.529 19.529 17.304 19.606 17.005C19.715 16.581 20 14.8 20 12C20 9.2 19.715 7.42 19.606 6.995ZM21.543 6.498C22 8.28 22 12 22 12C22 12 22 15.72 21.543 17.502C21.289 18.487 20.546 19.262 19.605 19.524C17.896 20 12 20 12 20C12 20 6.107 20 4.395 19.524C3.45 19.258 2.708 18.484 2.457 17.502C2 15.72 2 12 2 12C2 12 2 8.28 2.457 6.498C2.711 5.513 3.454 4.738 4.395 4.476C6.107 4 12 4 12 4C12 4 17.896 4 19.605 4.476C20.55 4.742 21.292 5.516 21.543 6.498ZM10 15.5V8.5L16 12L10 15.5Z"
        fill="#C74D44"
      />
    </g>
    <defs>
      <clipPath id="clip0_2501_4314">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const checkedSVG = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#4CAF50" />
    <path
      d="M10.3998 14.5373L17.7534 7.18286L18.8854 8.31406L10.3998 16.7997L5.30859 11.7085L6.43979 10.5773L10.3998 14.5373Z"
      fill="white"
    />
  </svg>
)

const unCheckedSVG = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      rx="11"
      stroke="#4CAF50"
      strokeWidth="2"
    />
    <path
      d="M10.0014 14.1372L17.355 6.78284L18.487 7.91404L10.0014 16.3996L4.91016 11.3084L6.04136 10.1772L10.0014 14.1372Z"
      fill="#4CAF50"
    />
  </svg>
)

const solidLineSVG = (
  <svg
    width="245"
    height="2"
    viewBox="0 0 245 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L244 0.999979"
      stroke="#4CAF50"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

const dashedLineSVG = (
  <svg
    width="245"
    height="2"
    viewBox="0 0 245 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L244 0.999979"
      stroke="#4CAF50"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="10 10"
    />
  </svg>
)

const CourseDataCard = ({ data, course_info, onMarkAsRead }) => {
  const [isRead, setIsRead] = useState(data.read)

  const handleMarkAsRead = () => {
    if (!isRead) {
      onMarkAsRead(data.docId, () => {
        setIsRead(true)
      })
    }
  }

  return (
    <div className="courseDataDiv" key={data.docId}>
      <div className="actionDiv">
        <div
          style={{
            width: "24px",
            height: "24px",
            cursor: !isRead ? "pointer" : "auto",
          }}
          onClick={handleMarkAsRead}
        >
          {isRead ? checkedSVG : unCheckedSVG}
        </div>
        <div
          className="solidLineDiv"
          style={{
            display: "flex",
          }}
        >
          {isRead ? solidLineSVG : dashedLineSVG}
        </div>
      </div>
      <div className="contentDiv">
        <Text
          style={{
            borderRadius: "4px",
            background: "rgba(76, 175, 80, 0.08)",
            width: "fit-content",
            color: "#4CAF50",
            fontFamily: "Poppins",
            fontSize: "14px",
            padding: "4px 16px",
          }}
        >
          {DIFFICULTY_TYPES_MAP[data.difficulty]}
        </Text>
        <Title
          className="title"
          style={{
            marginBottom: "24px",
          }}
        >
          {data.topic}
        </Title>
        <div
          style={{
            marginTop: "auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a
            href={data.typeLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              mixpanelEvent("resource_item_action", {
                action_type: "read",
                course_id: `${course_info?.tech}_${course_info?.lang}`
              })
              handleMarkAsRead()
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {readSVG}

              <Text
                className="text"
                style={{
                  color: "#4CAF50",
                }}
              >
                Read
              </Text>
            </div>
          </a>
          <a
            href={data.videoLink}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              mixpanelEvent("resource_item_action", {
                action_type: "video",
                course_id: `${course_info?.tech}_${course_info?.lang}`
              })
              handleMarkAsRead()
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {videoSVG}
              <Text
                className="text"
                style={{
                  color: "#C74D44",
                }}
              >
                Video
              </Text>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

const CourseDetail = () => {
  const isMobileMedia = useMediaQuery("screen and (max-width: 767px)")
  const params = useParams()
  const query = useQuery()
  const courseRoot = COURSES.find(course => course.courseId === params.courseId)
  const { token, isLoggedIn } = useLoggedInUserStore()
  const [sortQuery, setSortQuery] = useState({
    difficulty: DIFFICULTY_TYPES.BASIC,
    type: CONTENT_TYPES.THEORY,
  })
  const [course, setCourse] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [dataReceived, setDataReceived] = useState(false)
  const [isAuthPopupVisible, setIsAuthPopupVisible] = useState<boolean>(false)
  const [isDownloadAppPopupVisible, setIsDownloadAppPopupVisible] =
    useState<boolean>(false)

  const getCourseDetail = async ({
    difficulty = sortQuery.difficulty,
    type = sortQuery.type,
  } = {}) => {
    try {
      const { data } = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/getLRResource?technology=${encodeURIComponent(courseRoot?.tech)}&language=${encodeURIComponent(courseRoot?.lang)}&difficulty=${encodeURIComponent(difficulty)}&type=${encodeURIComponent(type)}`,
        idToken: token,
      })

      setLoading(false)
      setDataReceived(true)
      return data.data
    } catch (error) {
      setLoading(false)
      setDataReceived(false)
    }
  }

  const markAsRead = async (docId: string, cbFn) => {
    if (!isLoggedIn) return setIsAuthPopupVisible(true)
    cbFn()
    try {
      const resp = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/addLRData?docId=${docId}&tech=${courseRoot?.tech}&lang=${courseRoot?.lang}`,
        idToken: token,
      })

      getData()
    } catch (error) {}
  }

  useEffect(() => {
    let matches = false
    const query = "screen and (max-width: 767px)"
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      matches = media.matches
    }
    const listener = () => {
      matches = media.matches
    }
    try {
      media.addEventListener("change", listener)

      const isMobile = matches

      getData({ isMobile })
      return () => media.removeEventListener("change", listener)
    } catch {
      // Old versions of Safari without support for 'addEventListener'
      media.addListener(listener)
      return () => media.removeListener(listener)
    }
  }, [])

  useEffect(() => {
    let showDownloadModal = Cookies.get("showDownloadModal")
    if (!showDownloadModal) {
      setTimeout(() => {
        mixpanelEvent("popup_shown", {
          screen_name: "detail_track_screen",
          popup_name: "download_app",
        })
        setIsDownloadAppPopupVisible(true)
        Cookies.set("showDownloadModal", JSON.stringify(true), {
          expires: 2,
        })
      }, 15000)
    }
  }, [])

  useEffect(() => {
    if (params.courseId) {
      mixpanelTimeEvent("page_view_detail_track_screen")
    }

    return () => {
      mixpanelEvent("page_view_detail_track_screen", {
        screen_name: "detail_track_screen",
        course_id: `${courseRoot?.tech}_${courseRoot?.lang}`,
      })
    }
  }, [courseRoot])

  const getData = async ({
    difficulty = sortQuery.difficulty,
    type = sortQuery.type,
    isMobile = isMobileMedia,
  } = {}) => {
    setLoading(true)

    const queryData = {
      difficulty,
      type,
    }

    try {
      let data = await getCourseDetail(queryData)

      if (!isMobile) {
        const problemData: any[] = await getCourseDetail({
          ...queryData,
          type: CONTENT_TYPES.PROBLEM,
        })
        data = [...data, ...problemData]
      }

      setLoading(false)
      setDataReceived(true)

      setCourse(data)
    } catch (error) {
      setLoading(false)
      setDataReceived(false)
    }
  }

  const handleDifficultyChange = (tag, checked) => {
    if (checked) {
      setCourse([])
      const newSortQuery = { ...sortQuery }
      newSortQuery.difficulty = tag
      setSortQuery(newSortQuery)
      getData(newSortQuery)
    }
  }

  const handleContentTypeChange = (tag, checked) => {
    if (checked) {
      setCourse([])
      const newSortQuery = { ...sortQuery }
      newSortQuery.type = tag
      setSortQuery(newSortQuery)
      getData(newSortQuery)
    }
  }

  const theoryCourseData =
    course.filter(data => data.type === CONTENT_TYPES.THEORY) || []

  const problemCourseData =
    course.filter(data => data.type === CONTENT_TYPES.PROBLEM) || []

  return (
    <div className="courseDetailDiv">
      <AuthPopup
        setIsAuthPopupVisible={setIsAuthPopupVisible}
        isAuthPopupVisible={isAuthPopupVisible}
      />
      <DownloadAppPopup
        setIsDownloadAppPopupVisible={setIsDownloadAppPopupVisible}
        isDownloadAppPopupVisible={isDownloadAppPopupVisible}
      />
      <div className="tags">
        {Object.values(DIFFICULTY_TYPES).map(difficulty => (
          <CheckableTag
            className="checkTag"
            key={difficulty}
            checked={difficulty === sortQuery.difficulty}
            onChange={checked => handleDifficultyChange(difficulty, checked)}
          >
            {DIFFICULTY_TYPES_MAP[difficulty]}
          </CheckableTag>
        ))}
      </div>
      {isMobileMedia ? (
        <div className="tags">
          {Object.values(CONTENT_TYPES).map(contentType => (
            <CheckableTag
              className="checkTag"
              key={contentType}
              checked={contentType === sortQuery.type}
              onChange={checked =>
                handleContentTypeChange(contentType, checked)
              }
            >
              {CONTENT_TYPES_MAP[contentType]}
            </CheckableTag>
          ))}
        </div>
      ) : null}
      <Row className="courseRow">
        {theoryCourseData && theoryCourseData.length > 0 && (
          <div className="courseDataRow">
            <Title
              className="title"
              style={{
                marginBottom: "24px",
              }}
            >
              {CONTENT_TYPES_MAP[CONTENT_TYPES.THEORY]}
            </Title>
            <div className="courseDataDivWrapper">
              {theoryCourseData.map(data => (
                <CourseDataCard
                  data={data}
                  course_info={courseRoot}
                  onMarkAsRead={markAsRead}
                  key={data.docId}
                />
              ))}
            </div>
          </div>
        )}
        {problemCourseData && problemCourseData.length > 0 && (
          <div className="courseDataRow">
            <Title
              className="title"
              style={{
                marginBottom: "24px",
              }}
            >
              {CONTENT_TYPES_MAP[CONTENT_TYPES.PROBLEM]}
            </Title>
            <div className="courseDataDivWrapper">
              {problemCourseData.map(data => (
                <CourseDataCard
                  data={data}
                  course_info={courseRoot}
                  onMarkAsRead={markAsRead}
                  key={data.docId}
                />
              ))}
            </div>
          </div>
        )}

        {loading && course.length === 0 && (
          <div className="skel">
            <CohortCardSkeleton />
          </div>
        )}
      </Row>
    </div>
  )
}

export default CourseDetail
