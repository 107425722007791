import React, { useState } from "react"
import "./Signin.less"
import { useLocation } from "@reach/router"
import { app, googleProvider } from "../../../../../firebaseConfig"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { Typography, Form, Input, Button, message } from "antd"
import Google from "../../../assets/auth/google.svg"
import ErrorMessage from "../../../assets/auth/errorMessage.svg"
import { navigate } from "gatsby"
import {
  loginApi,
  loginApiGoogle,
} from "../../ZustandStateManagment/UserData/Api"

const { Text, Title } = Typography

const Signin = ({
  setForgotPass = () => {},
  popUp = false,
  onAuthComplete = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  //const authContext = useLoggedInUserStore()
  const [form] = Form.useForm()
  const addUserData = useLoggedInUserStore(state => state.addUserData)

  const state: any = useLocation().state

  const onFinish = async values => {
    const { email, password } = values

    setIsLoading(true)
    let idToken: string | undefined
    try {
      const response = await app
        .auth()
        .signInWithEmailAndPassword(email, password)
      idToken = await response?.user?.getIdToken()
    } catch (err: any) {
      setIsLoading(false)
      if (err.code === "auth/user-not-found") {
        return message.error("Invalid Email")
      } else if (err.code === "auth/wrong-password") {
        return message.error("Invalid Password")
      } else {
        return message.error("Something went wrong")
      }
    }
    try {
      const loginData = await loginApi(idToken)

      if (loginData.token) {
        const { mentor, mentorData, token, user, communities } = loginData
        addUserData(mentor, mentorData, token, user, communities, true)

        setIsLoading(false)
        // if (user.isReferral) {
        //   navigate("/referral")
        // } else {
        if (state && state.navigatedFrom) {
          return navigate(state.navigatedBackTo, {
            replace: true,
          })
        }
        if (popUp) {
          return onAuthComplete()
        }
        navigate("/web/", {
          replace: true,
        })
        // }
      } else {
        message.error("Something went wrong!!!")
        return setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      return message.error("Something went wrong")
    }
  }

  const signInWithGoogle = async () => {
    setIsGoogleLoading(true)
    let idToken: string | undefined
    try {
      const resp = await app.auth().signInWithPopup(googleProvider)
      idToken = await resp?.user?.getIdToken()
    } catch (error: any) {
      setIsGoogleLoading(false)
      if (error.code === "auth/popup-closed-by-user") {
        return message.error("Popup was closed")
      }
      return message.error("Something went wrong")
    }

    try {
      const loginData = await loginApiGoogle(idToken)
      if (loginData.token) {
        if (loginData.userNotFound) {
          return message.error("Failure")
        }
        const { mentor, mentorData, token, user, communities } = loginData
        addUserData(mentor, mentorData, token, user, communities, true)

        setIsGoogleLoading(false)
        // if (user.isReferral) {
        //   navigate("/referral")
        // } else {
        if (state && state.navigatedFrom) {
          return navigate(state.navigatedBackTo, {
            replace: true,
          })
        }
        if (popUp) {
          return onAuthComplete()
        }
        navigate("/web/", {
          replace: true,
        })
        // }
      } else {
        message.error("Something went wrong!!!")
        return setIsGoogleLoading(false)
      }
    } catch (error) {
      setIsGoogleLoading(false)
      return message.error("Something went wrong")
    }
    setIsGoogleLoading(false)
  }

  return (
    <div className="signInFormDiv">
      <Title className="signinTitle">Sign In to Mentro.</Title>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={<Text className="text">EMAIL</Text>}
          name="email"
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter your Email
                </Text>
              ),
            },
            {
              pattern:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter a valid Email
                </Text>
              ),
            },
          ]}
        >
          <Input placeholder="Enter your Email" />
        </Form.Item>
        <Form.Item
          label={<Text className="text">PASSWORD</Text>}
          name="password"
          wrapperCol={{ span: 24 }}
          className="passwordForm"
          rules={[
            {
              required: true,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter a Password
                </Text>
              ),
            },
          ]}
        >
          <Input type="password" placeholder="Enter your Password" />
        </Form.Item>
        <Text className="forgotPass" onClick={() => setForgotPass()}>
          forgot password?
        </Text>
        <div className="continueDiv">
          <Form.Item>
            <Button
              type="text"
              htmlType="submit"
              className="continueBtn"
              loading={isLoading}
            >
              Continue
            </Button>
          </Form.Item>
        </div>
      </Form>
      <div className="sep">
        <span className="or">OR</span>
      </div>
      <div className="googleAuth">
        <Button onClick={signInWithGoogle} loading={isGoogleLoading}>
          <img src={Google} alt="google logo" />
          <Text>Sign in with google</Text>
        </Button>
      </div>
      <div className="policyDiv">
        <Text className="text">
          By continuing, you agree to Mentro's{" "}
          <span
            onClick={() =>
              window.open(`${process.env.GATSBY_HOME_URL}/info/privacy-policy/`)
            }
          >
            Privacy Policy
          </span>
        </Text>
      </div>
    </div>
  )
}
export default Signin
