import React, { useContext, useEffect, useState } from "react"
import "./CommunityJoin.less"
import { useParams } from "@reach/router"
import { Button, message, Skeleton, Typography } from "antd"
import { getHttpHandler } from "../../../utils/httpHandler"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import Guest from "../../../assets/mentroWebApp/defaultUser.svg"
import AuthPopup from "../../Popups/AuthPopup"
// import { CommunityContext } from "../../../context/CommunityContext"
import useCommunityData from "../../ZustandStateManagment/Communities/store"
import { navigate } from "gatsby"
import { logGa } from "../../../../services/analytics"

const { Title } = Typography

const CommunityJoin = () => {
  const params = useParams()
  const { token, isLoggedIn, mentor, mentorData } = useLoggedInUserStore()
  const { joinCommunity } = useCommunityData()
  const [currentCommunity, setCurrentCommunity] = useState<any>(null)
  const [loadingCommunity, setLoadingCommunity] = useState<boolean>(false)
  const [isAuthPopupVisible, setIsAuthPopupVisible] = useState<boolean>(false)
  const [loadingCommunityJoin, setLoadingCommunityJoin] =
    useState<boolean>(false)

  const getCommunityDetails = async communityId => {
    setLoadingCommunity(true)
    try {
      const communityDetails = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/get?communityId=${communityId}`,
        idToken: token,
      })
      setLoadingCommunity(false)
      setCurrentCommunity(communityDetails?.community || null)
      if (communityDetails?.community?.position) {
        navigate(`/web/community/${communityId}`, {
          state: {
            community: communityDetails?.community,
          },
        })
      }
    } catch (error) {
      setLoadingCommunity(false)
      message.error("Something went wrong!")
    }
  }

  useEffect(() => {
    if (params?.communityId) {
      logGa(`PAGE_LANDING_join_community_screen_commId_${params?.communityId}`)
      getCommunityDetails(params?.communityId)
    }
  }, [params?.communityId])

  return (
    <div className="communityJoinDiv">
      <AuthPopup
        setIsAuthPopupVisible={setIsAuthPopupVisible}
        isAuthPopupVisible={isAuthPopupVisible}
      />
      <div className="communityDataDiv">
        <div className="commInfoDiv">
          {currentCommunity?.image ? (
            <img
              src={currentCommunity?.image || Guest}
              alt="community"
              className="communityImg"
            />
          ) : (
            <Skeleton.Avatar active shape="circle" className="communityImg" />
          )}
          <Title className="communityName">
            {currentCommunity?.name || <Skeleton.Button active size="small" />}
          </Title>
        </div>
        <Title className="communityHeadline">
          {currentCommunity?.headline || (
            <>
              <Skeleton.Input active size="small" className="headlineSkel1" />
              <Skeleton.Input active size="small" className="headlineSkel2" />
            </>
          )}
        </Title>
        <div className="commStatsDiv">
          {currentCommunity?.uid ? (
            <Title className="communityStats">
              {currentCommunity?.numPosts}
              <br /> Posts
            </Title>
          ) : (
            <Skeleton.Button active size="small" />
          )}
          {currentCommunity?.uid ? (
            currentCommunity?.numMembers > 0 && (
              <Title className="communityStats">
                {currentCommunity?.numMembers}
                <br /> Members
              </Title>
            )
          ) : (
            <Skeleton.Button active size="small" />
          )}
        </div>
        <Button
          loading={loadingCommunityJoin}
          type="text"
          className="joinBtn"
          onClick={() => {
            if (
              loadingCommunity ||
              (mentor && currentCommunity?.uid === mentorData?.uid)
            )
              return
            if (!isLoggedIn) return setIsAuthPopupVisible(true)
            if (!currentCommunity?.isMember) {
              logGa(
                `ITEM_CLICK_join_community_btn_commId_${currentCommunity?.uid}`
              )
              setLoadingCommunityJoin(true)
              joinCommunity(currentCommunity?.uid, "Join", true)
            }
          }}
        >
          {mentor && currentCommunity?.uid === mentorData?.uid
            ? "Owner"
            : (currentCommunity?.isMember && "Member") || "Join"}
        </Button>
        <Title className="communitySubText">
          <span
            className="highlight"
            onClick={() => {
              logGa(
                `ITEM_CLICK_join_community_screen_visit_community_commId_${currentCommunity?.uid}`
              )
              navigate(`/web/community/${currentCommunity?.uid}`, {
                state: { community: currentCommunity },
              })
            }}
          >
            Visit Community
          </span>{" "}
          without Joining
        </Title>
      </div>
    </div>
  )
}

export default CommunityJoin
