import React, { useCallback, useRef, useState } from "react"
import "./UserSessions.less"
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Rate,
  Row,
  Skeleton,
  Table,
  Tabs,
  Typography,
} from "antd"
//import RightLargeArrow from "../../../assets/mentroWebApp/rightLargeArrow.svg"
import AverageRating from "../../../assets/mentroWebApp/averageRating.svg"
import MeetArrow from "../../../assets/mentroWebApp/meetArrow.svg"
//import BackArrow from "../../../assets/mentroWebApp/backArrow.svg"
import DateIcon from "../../../assets/mentroWebApp/date.svg"
import Amount from "../../../assets/mentroWebApp/amount.svg"
import Clock from "../../../assets/mentroWebApp/clock.svg"
import NotFound from "../../../../assets/pageNotFound/notFound.svg"
import { useSessions } from "../../../hooks/useSessions"
import { navigate } from "gatsby-link"
import { patchHttpHandler } from "../../../utils/httpHandler"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"

const { Title, Text } = Typography
const { TabPane } = Tabs

const UserSessions = () => {
  const [isRateLoading, setIsRateLoading] = useState<boolean>(false)
  const [isRateModal, setIsRateModal] = useState<boolean>(false)
  const [ratingSessionId, setRatingSessionId] = useState<string>("")
  const [rateSessionForm] = Form.useForm()
  const authContext = useLoggedInUserStore()
  const { token } = authContext

  const {
    getSessions,
    sessions,
    setSessions,
    activeTab,
    setActiveTab,
    hasMoreSessions,
    loading,
    setDocId,
    dataReceived,
  } = useSessions()
  // -----------------------------------------------------------------
  // INFINITE SCROLLING-----------------------------------------------------------------
  // -----------------------------------------------------------------
  const observer = useRef<any>()
  const lastSessionElement = useCallback(
    ele => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasMoreSessions) {
            setDocId(sessions[sessions.length - 1].sessionId)
          }
        },
        {
          root: null,
          rootMargin: "40px",
          threshold: 0,
        }
      )
      if (ele) observer.current.observe(ele)
    },
    [loading, hasMoreSessions]
  )
  //-----------------------------------

  const columns = [
    {
      title: <Text className="tableColName">TOPIC</Text>,
      dataIndex: "topic",
      key: "topic",
      width: "20%",
    },
    {
      title: <Text className="tableColName">MENTOR</Text>,
      dataIndex: "mentor",
      key: "mentor",
      width: "20%",
    },
    {
      title: <Text className="tableColName">TIME</Text>,
      dataIndex: "time",
      key: "time",
    },
    {
      title: <Text className="tableColName">DATE</Text>,
      dataIndex: "date",
      key: "date",
    },
    {
      title: <Text className="tableColName">AMOUNT</Text>,
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: <Text className="tableColName">STATUS</Text>,
      dataIndex: "status",
      key: "status",
      render: status =>
        status.status === "skeleton" ? (
          <Skeleton.Button className="skel1" active size="small" />
        ) : (
          <Text className={`status ${status.status}`}>{status.statusName}</Text>
        ),
    },
    {
      title: <Text className="tableColName">MEET</Text>,
      dataIndex: "meet",
      key: "meet",
    },
    {
      title: <Text className="tableColName">RATE</Text>,
      dataIndex: "rate",
      key: "rate",
    },
  ]

  const getFormattedTime = date => {
    let formattedTime = new Date(date).toLocaleTimeString("en-US").split(" ")
    let part1 =
      formattedTime[0].split(":")[0] + ":" + formattedTime[0].split(":")[1]
    return part1 + " " + formattedTime[1]
  }

  const getSessionsData = () => {
    let data: any = []
    sessions &&
      sessions.map((session, index) => {
        const date = new Date(session.eventTime)
        const time = date.toLocaleTimeString("en-US").split(" ")
        data.push({
          key: session.docId,
          topic: (
            <Text className="dataText topic">
              {session.title || session.sessionType}
            </Text>
          ),
          mentor: (
            <Text className="dataText mentor">
              {session.participantDetails[0].name}
            </Text>
          ),
          time: <Text className="dataText time">{getFormattedTime(date)}</Text>,
          date: (
            <Text className="dataText date">{`${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`}</Text>
          ),
          amount: <Text className="dataText amount">{session.amount}</Text>,
          status: {
            statusName: session.status,
            status: session.status,
          },
          meet: (
            <img
              src={MeetArrow}
              alt="meet arrow"
              style={{ cursor: "pointer" }}
              onClick={() => window.open(session.meetLink)}
            />
          ),
          rate: (
            <img
              src={AverageRating}
              alt="rate icon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsRateModal(true)
                setRatingSessionId(session.sessionId)
              }}
            />
          ),
        })
      })

    //LOADING SKELETON
    loading &&
      hasMoreSessions &&
      data.push({
        key: "skeleton1",
        topic: <Skeleton.Button className="skel1" active size="small" />,
        mentor: <Skeleton.Button className="skel1" active size="small" />,
        time: <Skeleton.Button active size="small" />,
        date: <Skeleton.Button active size="small" />,
        amount: <Skeleton.Button active size="small" />,
        status: {
          statusName: "skeleton",
          status: "skeleton",
        },
        meet: <Skeleton.Avatar className="promo1" active size="small" />,
        rate: <Skeleton.Avatar className="promo1" active size="small" />,
      })
    return data
  }

  const getSkeleton = () => {
    return [
      {
        key: "skeleton1",
        topic: <Skeleton.Button className="skel1" active size="small" />,
        mentor: <Skeleton.Button className="skel1" active size="small" />,
        time: <Skeleton.Button active size="small" />,
        date: <Skeleton.Button active size="small" />,
        amount: <Skeleton.Button active size="small" />,
        status: {
          statusName: "skeleton",
          status: "skeleton",
        },
        meet: <Skeleton.Avatar className="promo1" active size="small" />,
        rate: <Skeleton.Avatar className="promo1" active size="small" />,
      },
    ]
  }

  const getMobileSessions = () => {
    return (
      sessions &&
      sessions.map((session, ind) => (
        <Col xs={24} key={session.docId} className="sessionCol">
          <Title className="dataText sessionType">
            {session.title || session.sessionType}
          </Title>
          <Title className="dataText mentor">
            With {session.participantDetails[0].name}
          </Title>
          <div className="contentDiv">
            <div className="left">
              <div className="leftBox">
                <img src={Amount} alt="amount" />
                Amount: <Text className="dataText">₹ {session.amount}</Text>
              </div>
              <div className="leftBox">
                <img src={Clock} alt="clock" />
                Time:{" "}
                <Text className="dataText">
                  {getFormattedTime(new Date(session.eventTime))}
                </Text>
              </div>
            </div>
            <div className="right">
              <div className="rightBox">
                <Text className={`status ${session.status}`}>
                  {session.status}
                </Text>
              </div>
              <div className="rightBox">
                <img src={DateIcon} alt="dateIcon" />
                Date:{" "}
                <Text className="dataText">{`${new Date(
                  session.eventTime
                ).getDate()}/${
                  new Date(session.eventTime).getMonth() + 1
                }/${new Date(session.eventTime).getFullYear()}`}</Text>
              </div>
            </div>
          </div>
          <div className="btns">
            <Button
              type="text"
              className="btn chatBtn"
              onClick={() => {
                navigate("/web/chats")
              }}
            >
              Chat
            </Button>
            <Button
              type="link"
              href={session.meetLink}
              target="_blank"
              className="btn meetBtn"
            >
              Meet
            </Button>
          </div>
        </Col>
      ))
    )
  }

  const getMobileSkel = () => {
    return (
      <Col xs={24} className="sessionCol">
        <Skeleton.Button className="mobSkel1" active size="small" />
        <Skeleton.Button className="mobSkel2" active size="small" />
        <div className="contentDiv">
          <div className="left">
            <div className="leftBox">
              <Skeleton.Button active size="small" className="mobSkel3" />
            </div>
            <div className="leftBox">
              <Skeleton.Button active size="small" className="mobSkel3" />
            </div>
          </div>
          <div className="right">
            <div className="rightBox">
              <Skeleton.Button active size="small" className="mobSkel3" />
            </div>
            <div className="rightBox">
              <Skeleton.Button active size="small" className="mobSkel3" />
            </div>
          </div>
        </div>
        <div className="btns">
          <Skeleton.Button className="skel1" active size="small" />
          <Skeleton.Button className="skel1" active size="small" />
        </div>
      </Col>
    )
  }

  const ratingSession = async (values: any) => {
    const { rating, review } = values
    if (!rating) {
      message.error("Please rate the session!!")
    }

    try {
      setIsRateLoading(true)
      const rateSessionResp = await patchHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/session/v2/endsession/rateMentor`,
        idToken: token,
        body: {
          sessionId: ratingSessionId,
          rating: rating || 0,
          review: review || "",
        },
      })
      setIsRateLoading(false)
      setIsRateModal(false)
      setRatingSessionId("")
      rateSessionForm.resetFields()
      if (rateSessionResp.success) {
        getSessions()
        return message.success("Youv'e rated the session!!!")
      }
      message.error(rateSessionResp.message)
    } catch ({ message: any }) {
      setIsRateLoading(false)
      setIsRateModal(false)
      setRatingSessionId("")
      message.error(message)
    }
  }

  return (
    <div className="userSessionsParent">
      <Modal
        className="rateSessionModal"
        visible={isRateModal}
        title={<Text className="modalHeading">Rate the session</Text>}
        onOk={ratingSession}
        onCancel={() => {
          setIsRateModal(false)
          setRatingSessionId("")
        }}
        footer={null}
      >
        <Form
          form={rateSessionForm}
          name="basic"
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 12,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={ratingSession}
          onFinishFailed={error => {}}
          autoComplete="off"
        >
          <div className="topDesc">
            <Title className="title">How was your Session?</Title>
            <Title className="title">Rate your session experience</Title>
          </div>
          <div className="ratingDiv">
            <Form.Item
              name={"rating"}
              wrapperCol={{ span: 24 }}
              initialValue={1}
            >
              <Rate />
            </Form.Item>
          </div>
          <Form.Item
            name={"review"}
            wrapperCol={{ span: 24 }}
            initialValue={""}
          >
            <Input.TextArea
              rows={4}
              placeholder="Share your feedback (optional)"
            />
          </Form.Item>
          <div className="actionsDiv">
            <Form.Item>
              <Button
                type="text"
                className="sendBtn"
                htmlType="submit"
                loading={isRateLoading}
              >
                Send
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <div className="head">
        <Title className="title">Your Sessions</Title>
      </div>

      <div className="bottom">
        <div className="menuBar">
          <Tabs
            defaultActiveKey="all"
            onChange={key => {
              setSessions([])
              setDocId("")
              setActiveTab(key)
            }}
            activeKey={activeTab}
          >
            <TabPane
              tab={<Text className="tabName">Pending</Text>}
              key="upcoming"
            ></TabPane>
            <TabPane
              tab={<Text className="tabName">Initiated</Text>}
              key="initiated"
            ></TabPane>
            <TabPane
              tab={<Text className="tabName">Live</Text>}
              key="live"
            ></TabPane>
            <TabPane
              tab={<Text className="tabName">Completed</Text>}
              key="completed"
            ></TabPane>
          </Tabs>
        </div>
        <div className="tableDiv">
          <Table
            columns={columns}
            dataSource={dataReceived ? getSessionsData() : getSkeleton()}
            pagination={false}
            sticky={true}
          />
        </div>
      </div>

      <Row className="mobileBottom">
        {dataReceived ? getMobileSessions() : getMobileSkel()}
        {dataReceived && sessions.length === 0 && (
          <div className="noSessionsDiv">
            <img src={NotFound} alt="not found illustration" />
            <h1>No Sessions Found</h1>
          </div>
        )}

        {loading && hasMoreSessions && getMobileSkel()}
      </Row>
      <div ref={lastSessionElement} />
    </div>
  )
}

export default UserSessions
