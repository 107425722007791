import React, { useEffect, useState } from "react"
import "./UserCohorts.less"
import { Col, Row, Typography } from "antd"
import CohortCard from "../CohortCard"
import { getHttpHandler } from "../../../utils/httpHandler"
// import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"

import CohortCardSkeleton from "../CohortCardSkeleton"
import {
  logGa,
  mixpanelEvent,
  mixpanelTimeEvent,
} from "../../../../services/analytics"

const { Title, Text } = Typography

const UserCohorts = () => {
  const { token, isLoggedIn, mentor } = useLoggedInUserStore()
  const [cohorts, setCohorts] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [dataReceived, setDataReceived] = useState(false)

  const getCohorts = async () => {
    setLoading(true)
    try {
      const cohortsResp = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/program/getProgramList`,
        idToken: token,
      })
      setLoading(false)
      if (cohortsResp?.status === "success") {
        setDataReceived(true)
        setCohorts(cohortsResp.programs)
        return
      }
      setDataReceived(false)
    } catch (error) {
      setLoading(false)
      setDataReceived(false)
    }
  }

  useEffect(() => {
    logGa("PAGE_LANDING", { screen_name: "program_list" })
    getCohorts()
    mixpanelTimeEvent("page_view_program_list")

    return () => {
      mixpanelEvent("page_view_program_list", {
        screen_name: "program_list",
        user_type: isLoggedIn ? (mentor ? "mentor" : "student") : "guest",
      })
    }
  }, [])

  return (
    <div className="cohortsDiv">
      <Title className="headerTitle">Programs</Title>
      <div className="cohortCardsDiv">
        <Row className="cohortCardsRow" gutter={[25, 25]}>
          {cohorts.length > 0 &&
            cohorts.map((cohort, index) => {
              return (
                <Col
                  key={index}
                  className="cohortCardsCol"
                  lg={8}
                  md={12}
                  sm={24}
                >
                  <CohortCard cohort={cohort} />
                </Col>
              )
            })}
          {loading &&
            Array.from(Array(3)).map((item, ind) => (
              <Col
                className="cohortCardsCol cohortCardsColSkel"
                lg={8}
                md={12}
                sm={24}
                key={ind}
              >
                <CohortCardSkeleton />
              </Col>
            ))}
        </Row>
      </div>
    </div>
  )
}

export default UserCohorts
