import fe_angular from "../../assets/resources/angular.png"
import fe_html_css_js from "../../assets/resources/html_css_js.png"
import fe_react from "../../assets/resources/react.jpg"
import be_django from "../../assets/resources/django.jpg"
import be_spring_boot from "../../assets/resources/spring_boot.png"
import be_nodejs from "../../assets/resources/nodejs.webp"
import an_kotlin from "../../assets/resources/kotlin.jpeg"
import an_java from "../../assets/resources/java_android.png"
import ml_python from "../../assets/resources/python_ml.png"
import dsa_python from "../../assets/resources/python_dsa.png"
import dsa_cpp from "../../assets/resources/dsa_cpp.jpg"
import dsa_java from "../../assets/resources/dsa_java.webp"


export const COURSES = [
  { courseId: "data-structure-and-algorithm-with-cpp", tech: "DSA", lang: "C++", name: "Data Structure & Algorithms", technology: "C++", image: dsa_cpp },
  { courseId: "data-structure-and-algorithm-with-python", tech: "DSA", lang: "Python", name: "Data Structure & Algorithms", technology: "Python", image: dsa_python },
  { courseId: "data-structure-and-algorithm-with-java", tech: "DSA", lang: "Java", name: "Data Structure & Algorithms", technology: "Java", image: dsa_java },
  { courseId: "frontend-with-html-css-js", tech: "WBF", lang: "HTML_CSS_JS", name: "Frontend Development", technology: "HTML CSS JS", image: fe_html_css_js },
  { courseId: "frontend-with-react", tech: "WBF", lang: "React", name: "Frontend Development", technology: "React", image: fe_react },
  { courseId: "backend-with-nodejs", tech: "WBB", lang: "NodeJs", name: "Backend Development", technology: "NodeJs", image: be_nodejs },
  { courseId: "android-with-java", tech: "AND", lang: "Java", name: "Android Development", technology: "Java", image: an_java },
  { courseId: "machine-learning-with-python", tech: "MLE", lang: "Python", name: "Machine Learning", technology: "Python", image: ml_python },
  { courseId: "android-with-kotlin", tech: "AND", lang: "Kotlin", name: "Android Development", technology: "Kotlin", image: an_kotlin},
  { courseId: "backend-with-spring-boot", tech: "WBB", lang: "Spring", name: "Backend Development", technology: "Spring Boot", image: be_spring_boot },
  { courseId: "backend-with-django", tech: "WBB", lang: "Django", name: "Backend Development", technology: "Django", image: be_django},
  { courseId: "frontend-with-angular", tech: "WBF", lang: "Angular", name: "Frontend Development", technology: "Angular", image: fe_angular },
]