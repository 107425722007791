import React from "react"
import { Button, Col, Row, Tooltip, Typography } from "antd"
import "./MentorCardMobile.less"
import Guest from "../../../../assets/mentroWebApp/defaultUser.svg"
import VerifiedTick from "../../../../assets/mentroWebApp/verifiedTick.svg"
import GoldenStar from "../../../../assets/mentroWebApp/goldenStar.svg"
import { navigate } from "gatsby"
import { logGa, mixpanelEvent } from "../../../../../services/analytics"

const { Title, Text } = Typography

const MentorCardMobile = ({ mentorData }) => {
  const {
    name,
    profileUrl,
    role,
    organisation,
    interests,
    averageRating,
    profileId,
    isVerified,
  } = mentorData
  return (
    <div className="mobileMentorCard">
      <Row
        className="top"
        onClick={() => {
          mixpanelEvent("mentor_item_click", {
            mentor_id: profileId,
          })
          navigate(`/web/@${profileId}`)
        }}
      >
        <Col>
          <img
            src={profileUrl ? profileUrl : Guest}
            alt="mentor profilepic"
            className="mentorProfilePic"
          />
        </Col>
        <Col xs={19}>
          <div className="mentorInfo">
            <div className="headDiv">
              <div className="nameDiv">
                <Title className="nameText">{name}</Title>
                {isVerified && (
                  <img
                    src={VerifiedTick}
                    alt="VerifiedTick"
                    className="verifiedTick"
                  />
                )}
              </div>
              <div className="ratingDiv">
                <img src={GoldenStar} alt="goldenStar" className="ratingStar" />
                {averageRating}
              </div>
            </div>
            <Tooltip title={`${role} at ${organisation}`}>
              <Title className="subText">
                {role} at {organisation}
              </Title>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <div className="interestsDiv">
        {interests.slice(0, 2).map((intr, ind) => (
          <Tooltip title={intr} key={ind}>
            <span className="interest">{intr}</span>
          </Tooltip>
        ))}
      </div>
      <Row className="btns" gutter={[15, 15]}>
        <Col xs={12}>
          <Button
            className="btn profileBtn"
            type="text"
            onClick={() => {
              logGa("ITEM_CLICK", {
                button_name: `view_profile_${profileId}`,
                button_type: "view_profile",
              })
              mixpanelEvent("mentor_item_click", {
                mentor_id: profileId,
              })
              navigate(`/web/@${profileId}`)
            }}
          >
            View profile
          </Button>
        </Col>
        <Col xs={12}>
          <Button
            type="text"
            className="btn sessionBtn"
            onClick={() => {
              logGa("ITEM_CLICK", {
                item_name: "start_session_btn",
                screen_name: "mentor_list_screen",
                mentor_profile_id: profileId,
              })
              mixpanelEvent("mentor_item_click", {
                mentor_id: profileId,
              })
              navigate(`/web/@${profileId}#startsession`)
            }}
          >
            Start Session
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default MentorCardMobile
