import React, { useContext, useState } from "react"
import "./MobileNavbar.less"
import { Button, Drawer } from "antd"
import MentroLogo from "../../../../assets/mentroWebApp/mentroLogo.svg"
import SideBar from "../../SideBar"
import HamburgerMenu from "../../../../assets/mentroWebApp/hamburgerMenu.svg"
import { Link } from "gatsby"

const MobileNavbar = ({}) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <nav className="mobileMainNavbarWebParent">
      <div className="mobileMainNavbar">
        <div className="logoDiv">
          <Link to="/web/">
            <img src={MentroLogo} alt="Mentro Logo" className="mentroLogo" />
          </Link>
        </div>
        <div>
          <Button type="primary" onClick={showDrawer} className="drawerBtn">
            <img src={HamburgerMenu} alt="menu icon" />
          </Button>
          <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
          >
            <SideBar onClose={onClose} />
          </Drawer>
        </div>
      </div>
    </nav>
  )
}

export default MobileNavbar
