import { createContext } from "react"

export const JobsContext = createContext<{
  jobOpportunities: any[]
  setJobOpportunities: (jobOpportunities: any[]) => void
  error: boolean
  setError: (error: boolean) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  hasMoreJobs: boolean
  setHasMoreJobs: (hasMoreJobs: boolean) => void
  docId: string | null
  setDocId: (docId: string) => void
  dataReceived: boolean
  setDataReceived: (dataReceived: boolean) => void
  companyType: string | null
  setCompanyType: (companyType: string) => void
  roleType: string | null
  setRoleType: (roleType: string) => void
}>({
  jobOpportunities: [],
  setJobOpportunities: (jobOpportunities: any[]) => {},
  error: false,
  setError: (error: boolean) => {},
  loading: false,
  setLoading: (loading: boolean) => {},
  hasMoreJobs: false,
  setHasMoreJobs: (hasMoreJobs: boolean) => {},
  docId: "",
  setDocId: (docId: string) => {},
  dataReceived: false,
  setDataReceived: (dataReceived: boolean) => {},
  companyType: "",
  setCompanyType: (companyType: string) => {},
  roleType: "",
  setRoleType: (roleType: string) => {},
})
