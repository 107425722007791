import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import "./CommunityPostDetails.less"
import { useParams, useLocation } from "@reach/router"
//import { CommunityContext } from "../../../context/CommunityContext"
import { getHttpHandler, postHttpHandler } from "../../../utils/httpHandler"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { Col, message, Row, Typography } from "antd"
import CommunityPost from "../CommunityPost"
import CommunityPostSkeleton from "../CommunityPostSkeleton"
import AuthPopup from "../../Popups/AuthPopup"
import CommunityPostComment from "../CommunityPostComment"
import CommentBox from "../CommentBox"
import CommunityPostCommentSkeleton from "../CommunityPostCommentSkeleton"
import CommunityData from "../CommunityData"
import CreateCommunityPost from "../CreateCommunityPost"
import {
  logGa,
  mixpanelEvent,
  mixpanelTimeEvent,
} from "../../../../services/analytics"
import useCommunityData from "../../ZustandStateManagment/Communities/store"

const { Title } = Typography
const MAX_NO_OF_TOPICS = 10

const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const CommunityPostDetails = () => {
  const params = useParams()
  let query = useQuery()
  const state: any = useLocation().state
  const { token, user, mentor, mentorData, isLoggedIn } = useLoggedInUserStore()
  const {
    currentPost,
    setCurrentPost,
    comments,
    setComments,
    lastCommentId,
    topCommentId,
    hasMoreComments,
    setHasMoreComments,
    setLastCommentId,
    setCurrentPostId,
  } = useCommunityData()
  // console.log(comments)
  // const {
  //   currentPost,
  //   setCurrentPost,
  //   comments,
  //   setComments,
  //   lastCommentId,
  //   topCommentId,
  //   hasMoreComments,
  //   setHasMoreComments,
  //   setLastCommentId,
  // } = useContext(CommunityContext)
  const commentSectionRef = useRef<any>(null)
  const [isCreatePostVisible, setisCreatePostVisible] = useState<boolean>(false)
  const [isAuthPopupVisible, setIsAuthPopupVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataReceived, setDataReceived] = useState<boolean>(false)
  const [position, setPosition] = useState<string>("")
  const [popularPosts, setPopularPosts] = useState<any[]>([])
  const [loadingPopularPosts, setLoadingPopularPosts] = useState<boolean>(false)
  const [loadingComments, setLoadingComments] = useState<boolean>(false)
  const [dataReceivedComments, setDataReceivedComments] =
    useState<boolean>(false)

  const getCommunityPost = async postId => {
    setLoading(true)
    setDataReceived(false)
    try {
      const communityPost = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/post/get?postId=${postId}`,
        idToken: token,
      })
      setLoading(false)
      setDataReceived(true)
      setCurrentPost(communityPost?.post || null)
      setPosition(
        mentor && communityPost?.post?.poster?.uid === mentorData?.uid
          ? "Owner"
          : (communityPost?.post?.poster?.isMember && "Member") || ""
      )
    } catch (error) {
      setLoading(false)
      setDataReceived(false)
      message.error("Something went wrong!")
    }
  }

  useEffect(() => {
    if (state?.communityPost?.postId) {
      setCurrentPost(state.communityPost)
      setPosition(
        mentor && state?.communityPost?.poster?.uid === mentorData?.uid
          ? "Owner"
          : (state?.communityPost?.poster?.isMember && "Member") || ""
      )
    }
    if (state?.comment) {
      commentSectionRef?.current?.scrollIntoView({
        behavior: "smooth",
      })
    }
    if (params?.postId) {
      logGa("PAGE_LANDING", {
        screen_name: "detail_post_page",
        post_id: params?.postId,
      })
      mixpanelTimeEvent("page_view_detail_post")

      getCommunityPost(params?.postId)
      getCommunityPostComments(params?.postId, query.get("commentId"))
    }

    return () => {
      setCurrentPost(null)
      setComments([])
      mixpanelEvent("page_view_detail_post", {
        screen_name: "detail_post_page",
        post_id: params?.postId,
        user_type: isLoggedIn ? (mentor ? "mentor" : "student") : "guest",
      })
    }
  }, [params?.postId])

  useEffect(() => {
    getNextCommunityPostComments(query.get("commentId"))
  }, [lastCommentId])

  const getCommunityPostComments = async (
    postId,
    topCommId: string | null = ""
  ) => {
    setLoadingComments(true)
    setDataReceivedComments(false)

    try {
      const commentsResp = await getHttpHandler({
        url: `${
          process.env.GATSBY_BE_URL
        }/community/comment/get?postId=${postId}&commentId=${
          topCommId || topCommentId
        }&lastCommentId=${""}`,
        idToken: token,
      })
      setDataReceivedComments(true)
      setLoadingComments(false)
      setComments(commentsResp?.comments || [])
      setCurrentPostId(postId)
      setHasMoreComments(commentsResp?.comments.length > 0)
    } catch (error) {
      setDataReceivedComments(false)
      setLoadingComments(false)
      message.error("Something went wrong!")
    }
  }

  const getNextCommunityPostComments = async (
    topCommId: string | null = ""
  ) => {
    if (!hasMoreComments || !lastCommentId) {
      return
    }
    setLoadingComments(true)
    try {
      const nextCommentsResp = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/comment/get?postId=${
          params?.postId
        }&commentId=${
          topCommId || topCommentId
        }&lastCommentId=${lastCommentId}`,
        idToken: token,
      })
      const totalComments = [...comments, ...nextCommentsResp?.comments]
      setComments(totalComments)
      setHasMoreComments(nextCommentsResp?.comments?.length > 0)
      setLoadingComments(false)
    } catch (error) {
      setLoadingComments(false)
      message.error("Something went wrong!")
    }
  }
  // -----------------------------------------------------------------
  // INFINITE SCROLLING-----------------------------------------------------------------
  // -----------------------------------------------------------------
  const observer = useRef<any>()
  const lastCommentRef = useCallback(
    ele => {
      if (loadingComments) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasMoreComments) {
            setLastCommentId(comments[comments.length - 1]?.commentId || "")
          }
        },
        {
          root: null,
          rootMargin: "40px",
          threshold: 0,
        }
      )
      if (ele) observer.current.observe(ele)
    },
    [loadingComments, hasMoreComments]
  )
  //-----------------------------------

  useEffect(() => {
    if (currentPost?.poster?.uid && window.innerWidth > 768) {
      getPopularPosts(currentPost?.poster?.uid)
    }
  }, [currentPost?.poster?.uid])

  const getPopularPosts = async communityId => {
    setLoadingPopularPosts(true)
    try {
      const popularPosts = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/post/getPopularPosts`,
        idToken: token,
        body: {
          topics: user?.interests?.slice(0, MAX_NO_OF_TOPICS) || [],
          communityId,
        },
      })
      setPopularPosts(popularPosts.posts)
      setLoadingPopularPosts(false)
    } catch (error) {
      setLoadingPopularPosts(false)
    }
  }

  return (
    <div className="communityPostDetailsDiv">
      {/* <CreateCommunityPost
        communityId={currentPost?.poster?.uid}
        onCreatePost={() => {}}
        isCreatePostVisible={isCreatePostVisible}
        setisCreatePostVisible={setisCreatePostVisible}
      /> */}
      <AuthPopup
        setIsAuthPopupVisible={setIsAuthPopupVisible}
        isAuthPopupVisible={isAuthPopupVisible}
      />
      <Row gutter={[40, 10]} className="communityPostDetailsRow">
        <Col lg={16} className="communityPostDetailsCol">
          {currentPost ? (
            <CommunityPost
              readMoreClicked={true}
              communityPost={currentPost}
              setIsAuthPopupVisible={setIsAuthPopupVisible}
            />
          ) : (
            <CommunityPostSkeleton />
          )}
          <div className="commentsDiv" ref={commentSectionRef}>
            <Title className="commentsTitle">Comments</Title>
            <div className="comments">
              <CommentBox
                commentType="comment"
                // postId={currentPostId}
                setIsAuthPopupVisible={setIsAuthPopupVisible}
              />
              <div className="allCommentsDiv">
                {dataReceivedComments &&
                  comments?.length > 0 &&
                  comments?.map((comm, ind) => (
                    <CommunityPostComment
                      noMarginComment={ind + 1 === comments?.length}
                      comment={comm}
                      setIsAuthPopupVisible={setIsAuthPopupVisible}
                      key={comm.commentId}
                    />
                  ))}
                {loadingComments && (
                  <>
                    <CommunityPostCommentSkeleton image={true} />
                    <CommunityPostCommentSkeleton />
                  </>
                )}
                {dataReceivedComments && comments.length === 0 && (
                  <div className="noCommentsDiv">
                    <Title className="title">
                      No comments yet. Be the first to comment!
                    </Title>
                  </div>
                )}
                <div ref={lastCommentRef} />
              </div>
            </div>
          </div>
        </Col>
        <Col lg={8} className="communityPostDetailsCol rightCol">
          <CommunityData
            screenName="post_share_btn"
            postId={currentPost?.postId}
            currentCommunity={currentPost?.poster}
            loadingCommunity={!currentPost?.poster?.uid}
            position={position}
            setPosition={setPosition}
            setIsAuthPopupVisible={setIsAuthPopupVisible}
            setisCreatePostVisible={setisCreatePostVisible}
          />
          <div className="popularPostDiv">
            {popularPosts?.length > 0 && (
              <Title className="title">Most popular from this community</Title>
            )}
          </div>
          {popularPosts?.length > 0 &&
            popularPosts.map((communityPopularPostData, ind) => (
              <CommunityPost
                smallFont={true}
                noMarginPost={ind + 1 === popularPosts?.length}
                borderRadius={false}
                setIsAuthPopupVisible={setIsAuthPopupVisible}
                communityPost={communityPopularPostData}
                key={communityPopularPostData.postId}
              />
            ))}
          {loadingPopularPosts && (
            <CommunityPostSkeleton
              borderRadius={false}
              marginTop={popularPosts?.length > 0}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default CommunityPostDetails
