import React, { useContext, useEffect, useState } from "react"
// import { AuthContext } from "../../context/AuthContext"
import { getHttpHandler } from "../../utils/httpHandler"
import { useLoggedInUserStore } from "../ZustandStateManagment/UserData/store"
import MentorViewProfile from "./MentorViewProfile"
import ViewProfile from "./ViewProfile"
import { mixpanelEvent, mixpanelTimeEvent } from "../../../services/analytics"

const Profile = () => {
  const { mentor, token, isLoggedIn } = useLoggedInUserStore()
  const [orgList, setOrgList] = useState([])
  const [interestList, setInterestList] = useState([])

  const getData = async () => {
    try {
      const response = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/getMentorFormData/`,
        idToken: token,
      })
      setOrgList(response.orgs)
      setInterestList(response.interests)
    } catch (error) {}
  }

  useEffect(() => {
    if (mentor) {
      getData()
      mixpanelTimeEvent("page_view_profile")
    }

    return () => {
      mixpanelEvent("page_view_profile", {
        screen_name: "profile",
        user_type: isLoggedIn ? (mentor ? "mentor" : "student") : "guest",
      })
    }
  }, [])

  return mentor ? (
    <MentorViewProfile interestList={interestList} orgList={orgList} />
  ) : (
    <ViewProfile />
  )
}

export default Profile
