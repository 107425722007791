import React, { useEffect, useState } from "react"
import "./AuthTemplate.less"
import { useLocation } from "@reach/router"
import { app } from "../../../../../firebaseConfig"
import { Button, Col, Input, message, Row, Typography } from "antd"
import AuthDesign from "../../../assets/auth/authDesign.svg"
import Signin from "../Signin"
import Signup from "../Signup"
import { navigate } from "gatsby"

const { Title } = Typography

const AuthTemplate = () => {
  const location: any = useLocation()
  const [authType, setAuthType] = useState("signin")
  const [forgotPass, setForgotPass] = useState(false)
  const [forgotPassLoading, setForgotPassLoading] = useState(false)
  const [forgotEmail, setForgotEmail] = useState("")

  const forgotPassword = async () => {
    const emailRE =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!emailRE.test(forgotEmail)) {
      return message.error("Enter a valid Email")
    }
    setForgotPassLoading(true)
    try {
      await app.auth().sendPasswordResetEmail(forgotEmail)
      message.success("Reset Password Link sent to your mail.")
      setForgotEmail("")
      setForgotPassLoading(false)
    } catch (error) {
      message.error("Something went wrong!!! Please try again")
      setForgotPassLoading(false)
    }
  }

  const forgotPassDiv = () => {
    return (
      <div className="forgotPassDiv">
        <Title className="forgotTitle">Reset Your Password</Title>
        <Input
          value={forgotEmail}
          onChange={e => setForgotEmail(e.target.value)}
          className="forgotInp"
          placeholder="Enter your email"
        />
        <span
          className="backToSignin"
          onClick={() => {
            navigate("/web/auth/#signin")
            setAuthType("signin")
            setForgotPass(false)
          }}
        >
          back to signin?
        </span>
        <Button
          className="forgotBtn"
          type="text"
          onClick={forgotPassword}
          loading={forgotPassLoading}
        >
          Send Email
        </Button>
      </div>
    )
  }

  useEffect(() => {
    location?.state && location?.state?.authType === "register"
      ? setAuthType("signup")
      : setAuthType("signin")
  }, [location?.state])

  useEffect(() => {
    if (location.hash === "#signin") {
      setAuthType("signin")
    }
    if (location.hash === "#signup") {
      setAuthType("signup")
    }
    if (location.hash === "#forgotPass") {
      setForgotPass(true)
      setAuthType("signin")
    }
  }, [location?.hash])

  return (
    <div className="authParent">
      <Row className="authRow">
        <Col sm={14} className="leftCol">
          <Title className="title">
            Discover Mentors, Join their Community and Start Learning 🚀
          </Title>
          <img src={AuthDesign} alt="authDesign" />
        </Col>
        <Col sm={10} className="rightCol">
          <div className="rightDiv">
            <div className="switchDiv">
              <ul>
                <li
                  className={
                    authType === "signin" ? "activeList" : "unActiveList"
                  }
                  onClick={() => {
                    {
                      !forgotPass
                        ? navigate("/web/auth/#signin")
                        : navigate("/web/auth/#forgotPass")
                    }
                    setAuthType("signin")
                  }}
                >
                  {!forgotPass ? "Signin" : "Reset"}
                </li>
                <li
                  className={
                    authType === "signup" ? "activeList" : "unActiveList"
                  }
                  onClick={() => {
                    navigate("/web/auth/#signup")
                    setAuthType("signup")
                  }}
                >
                  Signup
                </li>
              </ul>
            </div>
            {authType === "signin" ? (
              !forgotPass ? (
                <Signin
                  setForgotPass={() => {
                    navigate("/web/auth/#forgotPass")
                    setAuthType("signin")
                    setForgotPass(true)
                  }}
                />
              ) : (
                forgotPassDiv()
              )
            ) : (
              <Signup />
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AuthTemplate
