import React from "react"
import { Skeleton, Input, Button, Typography } from "antd"
import BillDesign from "../../assets/mentroWebApp/billDesign.svg"
import "./PaymentCard.less"
import { PAYMENT_ISSUE_LINK } from "../../utils/data"

const { Title, Text } = Typography

const PaymentCard = ({
  bookingTitle = "Booking",
  gistData,
  amount,
  pricingData,
  promoCode,
  setPromoCode,
  applyPromoCode,
  makePayment,
  loadingPaymentBtn,
  paymentBtnText = `Start ${bookingTitle}`,
}) => {
  return (
    <div className="paymentDiv">
      <div className="topSec">
        <Title className="paymentHeading">Start your {bookingTitle}</Title>
        <div className="sessionGist">
          <Title className="gist1">One Step Closer!</Title>
          {gistData}
        </div>
        <img src={BillDesign} alt="billDesign" className="billDesign" />
        <div className="billDiv">
          <Title className="billHeading">Bill Details</Title>
          <div className="billDetail">
            <Text className="detailName">Amount</Text>
            {pricingData ? (
              <Text className="detailPrice">₹{amount || 0.0}</Text>
            ) : (
              <Skeleton.Button active size="small" />
            )}
          </div>
          <div className="billDetail">
            <Text className="detailName">Discount</Text>
            {pricingData ? (
              <Text className="detailPrice discountPrice">
                - ₹{pricingData?.discountPrice || 0.0}
              </Text>
            ) : (
              <Skeleton.Button active size="small" />
            )}
          </div>
          <div className="billDetail">
            <Text className="detailName">Service</Text>
            {pricingData ? (
              <Text className="detailPrice">
                ₹{pricingData?.servicePrice || 0.0}
              </Text>
            ) : (
              <Skeleton.Button active size="small" />
            )}
          </div>
          <div className="billDetail">
            <Text className="detailName">Processing</Text>
            {pricingData ? (
              <Text className="detailPrice">
                ₹{pricingData?.rpayPrice || 0.0}
              </Text>
            ) : (
              <Skeleton.Button active size="small" />
            )}
          </div>
          <div className="billDetail">
            <Text className="detailName">Total</Text>
            {pricingData ? (
              <Text className="detailPrice">
                ₹{pricingData?.ultimatePrice || 0.0}
              </Text>
            ) : (
              <Skeleton.Button active size="small" />
            )}
          </div>
        </div>
        <img src={BillDesign} alt="billDesign" className="billDesign" />
        <div className="promoCodeDiv">
          <Title className="promoCodeText">PROMO CODE</Title>
          <div className="inputDiv">
            <Input
              placeholder="Enter Your Code"
              value={promoCode}
              onChange={e => setPromoCode(e.target.value)}
            />
            <Button type="text" className="applyBtn" onClick={applyPromoCode}>
              APPLY
            </Button>
          </div>
          <Title
            className={`promoCodeMessage ${
              pricingData?.isValid ? "valid" : "invalid"
            }`}
          >
            {pricingData?.promoInfo}
          </Title>
        </div>
      </div>
      <div className="paymentBtnDiv">
        <Button
          type="text"
          className="paymentBtn"
          onClick={makePayment}
          loading={loadingPaymentBtn}
        >
          {paymentBtnText}
        </Button>
        <Title
          className="paymentNote"
          onClick={() => window.open(PAYMENT_ISSUE_LINK)}
        >
          Having issues with payment?
        </Title>
      </div>
    </div>
  )
}

export default PaymentCard
