import React, { useEffect } from "react"
import "./Courses.less"
import { Col, Row, Typography } from "antd"
import CourseCard from "./CourseCard"
import { COURSES } from "./constants"
import { mixpanelEvent, mixpanelTimeEvent } from "../../../services/analytics"
import { useLoggedInUserStore } from "../ZustandStateManagment/UserData/store"

const { Title } = Typography

const Courses = () => {
  const { isLoggedIn, mentor } = useLoggedInUserStore()

  useEffect(() => {
    mixpanelTimeEvent("page_view_learning_track_screen")

    return () => {
      mixpanelEvent("page_view_learning_track_screen", {
        screen_name: "learning_track_screen",
        user_type: isLoggedIn ? (mentor ? "mentor" : "student") : "guest",
      })
    }
  }, [])

  return (
    <div className="coursesDiv">
      <Title className="headerTitle">Learning Resources</Title>
      <div className="courseCardsDiv">
        <Row className="courseCardsRow" gutter={[25, 25]}>
          {COURSES.length > 0 &&
            COURSES.map((course, index) => {
              return (
                <Col
                  key={index}
                  className="courseCardsCol"
                  lg={8}
                  md={12}
                  sm={24}
                >
                  <CourseCard course={course} />
                </Col>
              )
            })}
        </Row>
      </div>
    </div>
  )
}

export default Courses
