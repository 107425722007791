import React, { useContext, useEffect, useState } from "react"
import "./CommunityPost.less"
import { Button, message, Popconfirm, Typography } from "antd"
import SharePost from "../../../assets/mentroWebApp/sharePost.svg"
import LikePost from "../../../assets/mentroWebApp/likePost.svg"
import LikedPost from "../../../assets/mentroWebApp/likedPost.svg"
import CommentPost from "../../../assets/mentroWebApp/commentPost.svg"
import Guest from "../../../assets/mentroWebApp/defaultUser.svg"
import VerifiedTick from "../../../assets/mentroWebApp/verifiedTick.svg"
import moment from "moment"
import {
  ContentImageModel,
  ContentTextModel,
  ContentTitleModel,
  ContentVideoModel,
} from "./CommunityPostModels"
import { navigate } from "gatsby"
import { joinCommunityApi } from "../../ZustandStateManagment/Communities/Api"
//import { CommunityContext } from "../../../context/CommunityContext"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import ShareButtons from "../../ShareButtons"
import { logGa, mixpanelEvent } from "../../../../services/analytics"
import useCommunityData from "../../ZustandStateManagment/Communities/store"
import { getHttpHandler, postHttpHandler } from "../../../utils/httpHandler"
const { Title, Text } = Typography

const communityPostModelDataTypes = {
  title: ContentTitleModel,
  text: ContentTextModel,
  image: ContentImageModel,
  video: ContentVideoModel,
}

const PREVIEW_ITEMS_COUNT = 2

const CommunityPost = ({
  smallFont = false,
  joinCommunityHeader = false,
  communityPost,
  shadowPost = true,
  borderRadius = true,
  noMarginPost = true,
  setIsAuthPopupVisible = pVal => {},
  readMoreClicked = false,
}) => {
  const { isLoggedIn, mentor, mentorData, token } = useLoggedInUserStore()
  const {
    reactToPost,
    communities,
    latestPosts,
    setLatestPosts,
    setCommunities,
  } = useCommunityData()
  const [reactionStatus, setReactionStatus] = useState<boolean>()
  const [reactionCount, setReactionCount] = useState<number>(0)
  const [position, setPosition] = useState<string>("")
  const [isReadMoreClicked, setIsReadMoreClicked] = useState(false)
  const handleReadMoreClick = () => setIsReadMoreClicked(!isReadMoreClicked)

  useEffect(() => {
    setReactionStatus(communityPost?.hasLiked)
    setReactionCount(communityPost?.like)
    setPosition(
      mentor && communityPost?.poster?.uid === mentorData?.uid
        ? "Owner"
        : (communityPost?.poster?.isMember && "Member") || ""
    )
    setIsReadMoreClicked(readMoreClicked)
  }, [communityPost])

  return (
    <div
      className={`communityPostDiv ${noMarginPost && "noMarginPost"} ${
        shadowPost && "shadowPost"
      }  ${borderRadius && "borderRadiusPost"} ${smallFont && "smallFontPost"}`}
      key={communityPost?.postId}
    >
      <div
        className={`communityPost ${
          borderRadius && "borderRadiusCommunityPost"
        }`}
      >
        <div className="topDiv">
          <img
            src={communityPost?.poster?.image || Guest}
            alt="userImg"
            onClick={() => {
              const profileId = communityPost?.poster?.profileId
              if (profileId) {
                logGa("ITEM_CLICK", {
                  item_name: "mentor_profile_feed",
                  mentor_id: communityPost?.poster?.uid,
                })
                navigate(`/web/@${profileId}`)
              }
            }}
          />
          <div
            className="content"
            onClick={() => {
              const profileId = communityPost?.poster?.profileId
              if (profileId) {
                logGa(`mentor_profile_feed_${profileId}`)
                navigate(`/web/@${profileId}`)
              }
            }}
          >
            <div className="nameDiv">
              <Title className="name">{communityPost?.poster?.name}</Title>
              {communityPost?.poster?.isVerified && (
                <img
                  src={VerifiedTick}
                  alt="VerifiedTick"
                  className="verifiedTick"
                />
              )}
            </div>
            <Text className="timeSpan">
              {moment(communityPost?.lastUpdated).fromNow()}
            </Text>
          </div>
          {joinCommunityHeader && !position && (
            <Button
              type="text"
              className={!position ? "joinBtn" : "memberBtn"}
              onClick={async () => {
                if (!isLoggedIn) return setIsAuthPopupVisible(true)
                if (!position) {
                  logGa("ITEM_CLICK", {
                    item_name: "join_mentor_feed_btn",
                    mentor_id: communityPost?.poster?.uid,
                  })
                  const oldCommunityPosts = [...latestPosts]
                  const newCommunityPosts = oldCommunityPosts.map(cp => {
                    if (cp?.poster?.uid === communityPost?.poster?.uid) {
                      return {
                        ...cp,
                        poster: {
                          ...cp?.poster,
                          isMember: true,
                        },
                      }
                    }
                    return cp
                  })
                  setLatestPosts(newCommunityPosts)
                  setPosition("Member")
                  try {
                    let redirect = false
                    let status = "Join"
                    const joinCommunityResp = await joinCommunityApi(
                      communityPost?.poster?.uid,
                      status,
                      redirect,
                      token
                    )
                    if (joinCommunityResp?.status === "success") {
                      message.success(
                        `${
                          status === "Join" ? "Joined" : "Left"
                        } community successfully!`
                      )
                      if (redirect) {
                        navigate(`/web/community/${communityPost?.poster?.uid}`)
                      }
                      try {
                        const myFollowedCommunities = await getHttpHandler({
                          url: `${process.env.GATSBY_BE_URL}/community/followed`,
                          idToken: token,
                        })
                        setCommunities(myFollowedCommunities.communities)
                      } catch (error) {}
                      return
                    }
                  } catch (error) {}
                  // joinCommunity(communityPost?.poster?.uid, "Join", false)
                }
              }}
            >
              {position || "+ Join"}
            </Button>
          )}
          {/* <Text className="menu">3 dots</Text> */}
        </div>
        <div className="bodyDiv">
          <div
            className="bodyTop"
            onClick={() => {
              mixpanelEvent("detail_post_click", {
                post_id: communityPost?.postId,
              })
              navigate(`/web/post/${communityPost?.postId}`, {
                state: { communityPost },
              })
            }}
          >
            {communityPost?.postContent &&
              communityPost?.postContent
                ?.slice(
                  0,
                  isReadMoreClicked
                    ? communityPost?.postContent?.length
                    : PREVIEW_ITEMS_COUNT
                )
                ?.map((postData, ind) => {
                  const PostModel = communityPostModelDataTypes[postData.type]
                  return (
                    PostModel && (
                      <PostModel
                        key={ind}
                        isReadMoreClicked={isReadMoreClicked}
                        communityPostId={communityPost?.postId}
                        {...postData}
                      />
                    )
                  )
                })}
            {!isReadMoreClicked &&
              communityPost?.postContent.length > PREVIEW_ITEMS_COUNT && (
                <Text
                  onClick={(e: any) => {
                    if (
                      e.target ===
                      document.getElementById(
                        `learnMoreCommunityPost${communityPost?.postId}`
                      )
                    ) {
                      e.stopPropagation()
                      handleReadMoreClick()
                    }
                  }}
                  className="learnMore"
                  id={`learnMoreCommunityPost${communityPost?.postId}`}
                >
                  ... Learn more
                </Text>
              )}
          </div>
          <div className="bottomDiv">
            {reactionCount + communityPost?.comment + communityPost?.share >
              0 && (
              <div className="countsDiv">
                {reactionCount > 0 && (
                  <Text className="viewCount">{`${reactionCount || 0} ${
                    reactionCount > 1 ? "likes" : "like"
                  }`}</Text>
                )}
                {communityPost?.comment > 0 && (
                  <Text
                    className="viewCount"
                    onClick={() =>
                      navigate(`/web/post/${communityPost?.postId}`, {
                        state: { communityPost, comment: true },
                      })
                    }
                  >{`${communityPost?.comment || 0} comments`}</Text>
                )}
                {communityPost?.share > 0 && (
                  <Text className="viewCount">{`${
                    communityPost?.share || 0
                  } shares`}</Text>
                )}
              </div>
            )}
            <div className="actionsDiv">
              <div
                className={`actionDiv ${reactionStatus && "likedDiv"}`}
                onClick={async e => {
                  e.preventDefault()
                  if (!isLoggedIn) return setIsAuthPopupVisible(true)
                  if (reactionStatus) {
                    setReactionCount(reactionCount - 1)
                    try {
                      const reactPostResp = await postHttpHandler({
                        url: `${process.env.GATSBY_BE_URL}/community/post/like?postId=${communityPost?.postId}&status=Unlike`,
                        idToken: token,
                      })
                    } catch (error) {
                      message.error("Something went wrong!")
                    }
                    reactToPost(communityPost?.postId, "Unlike")
                  } else {
                    setReactionCount(reactionCount + 1)
                    try {
                      const reactPostResp = await postHttpHandler({
                        url: `${process.env.GATSBY_BE_URL}/community/post/like?postId=${communityPost?.postId}&status=Like`,
                        idToken: token,
                      })
                    } catch (error) {
                      message.error("Something went wrong!")
                    }
                    reactToPost(communityPost?.postId, "Like")
                  }
                  setReactionStatus(!reactionStatus)
                }}
              >
                {reactionStatus ? (
                  <img src={LikedPost} alt="likedpost" />
                ) : (
                  <img src={LikePost} alt="likepost" />
                )}{" "}
                Like
              </div>
              <div
                className="actionDiv"
                onClick={() => {
                  logGa("ITEM_CLICK", {
                    item_name: "post_comment_btn",
                    post_id: communityPost?.postId,
                  })
                  navigate(`/web/post/${communityPost?.postId}`, {
                    state: { communityPost, comment: true },
                  })
                }}
              >
                <img src={CommentPost} alt="commentpost" /> Comment
              </div>
              <div className="actionDiv">
                <Popconfirm
                  disabled={!communityPost?.postId}
                  id="sharePopConfirm"
                  icon={null}
                  placement="topRight"
                  onVisibleChange={visible => {
                    if (!visible) return
                    logGa("ITEM_CLICK", {
                      item_name: "post_share_btn",
                      post_id: communityPost?.postId,
                    })
                  }}
                  title={
                    <ShareButtons
                      url={`Hey, check out this community post by ${communityPost?.poster?.name} on Mentro 👉 \n${process.env.GATSBY_HOME_URL}/post/${communityPost?.postId}`}
                    />
                  }
                >
                  <img src={SharePost} alt="sharepost" /> Share
                </Popconfirm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunityPost
