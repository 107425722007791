import { postHttpHandler } from "../../../utils/httpHandler";

// For Communitiy.tsx
export const latestPostsApi = async (user, token, MAX_NO_OF_TOPICS) => {

    const latestPosts = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/post/getLatestPosts`,
        idToken: token,
        body: {
          lastPostId: "",
          topics: user?.interests?.slice(0, MAX_NO_OF_TOPICS) || [],
          communityId: "",
        },
        
      })
      const data = await latestPosts;

      return data;
}

export const nextLatestPostsApi = async (token, lastPostId, user, MAX_NO_OF_TOPICS) => {
    const nextLatestPosts = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/post/getLatestPosts`,
        idToken: token,
        body: {
          lastPostId: lastPostId,
          topics: user?.interests?.slice(0, MAX_NO_OF_TOPICS) || [],
          communityId: "",
        },
      })
      const data = await nextLatestPosts;

      return data;
}



export const joinCommunityApi = async (communityId, status, redirect = false, token) => {

  const joinCommunityResp = await postHttpHandler({
    url: `${process.env.GATSBY_BE_URL}/community/join`,
    idToken: token,
    body: {
      communityId,
      referrerId: "",
      status: "Join",
    },
  }
  )
  return joinCommunityResp;
}
