import React from "react"
import { Skeleton, Typography } from "antd"
import "./CohortCard.less"
import Guest from "../../../assets/mentroWebApp/defaultUser.svg"
import { navigate } from "gatsby"

const { Title, Text } = Typography

const CohortCard = ({ cohort }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false)

  return (
    <div
      key={cohort.programId}
      className="cohortCardDiv"
      onClick={() => {
        navigate(`/web/programs/${cohort?.programId}`)
      }}
    >
      <img
        onLoad={() => setImageLoaded(true)}
        src={cohort?.banner}
        alt="cohort banner"
        className={`top ${imageLoaded ? "loaded" : "unloaded"}`}
      />
      {!imageLoaded && (
        <Skeleton.Avatar
          active
          shape="square"
          size="large"
          className="top topSkel"
        />
      )}
      <div className="bottom">
        <div className="tags">
          {cohort?.level && <span className="tag">{cohort?.level}</span>}
          {cohort?.isFree && <span className="tag">Free</span>}
        </div>
        <Title className="cohortHeading">{cohort?.title}</Title>
        <div className="programBy">
          <img src={cohort?.by?.imageUrl || Guest} alt="program by" />
          <div className="userData">
            <Text className="userName">{cohort?.by?.userName}</Text>
            <Text className="userHeadline">{cohort?.by?.headline}</Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CohortCard
