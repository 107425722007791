import React, { useContext, useEffect, useState } from "react"
import "./CreateCommunityPost.less"
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Skeleton,
  Typography,
  Upload,
  Tag,
  Select,
} from "antd"
import ErrorMessage from "../../../assets/mentroWebApp/errorMessage.svg"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { getHttpHandler, postHttpHandler } from "../../../utils/httpHandler"
import { app } from "../../../../../firebaseConfig"
//import { CommunityContext } from "../../../context/CommunityContext"
import useCommunityData from "../../ZustandStateManagment/Communities/store"
const { Text } = Typography
const { CheckableTag } = Tag

const postContentTypes = {
  title: (title, titleType = "h1") => ({
    title,
    titleType,
    type: "title",
  }),
  text: text => ({
    text,
    type: "text",
  }),
  image: (image, caption = "") => ({
    image,
    caption,
    type: "image",
  }),
  video: video => ({
    video,
    type: "video",
  }),
}

const CreateCommunityPost = ({
  communityId,
  onCreatePost,
  isCreatePostVisible,
  setisCreatePostVisible,
}: any) => {
  const [createPostForm] = Form.useForm()
  const { token, mentorData } = useLoggedInUserStore()
  const { filters, communities } = useCommunityData()
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [isModalSaveLoading, setIsModalSaveLoading] = useState<boolean>(false)
  const [fileList, setFileList] = useState<any>([])
  const [isPhoto, setIsPhoto] = useState({
    status: "hold",
    message: "",
  })

  const createPostData = async body => {
    try {
      const responseData = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/community/post/add`,
        idToken: token,
        body,
      })

      if (responseData.status === "success") {
        onCreatePost()
        setFileList([])
        createPostForm.resetFields()
        setSelectedTags([])
        setIsModalSaveLoading(false)
        setisCreatePostVisible(false)
        return message.success("Post Created Successfully!!!")
      }
      setIsModalSaveLoading(false)
      message.error("Something went wrong")
    } catch (error) {
      setIsModalSaveLoading(false)
      message.error("Something went wrong")
    }
  }

  const getVideoUrl = url => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)

    return match && match[2].length === 11
      ? `https://www.youtube.com/embed/${match[2]}`
      : null
  }

  const onFormFinish = (values: any) => {
    if (selectedTags.length < 1 || selectedTags.length > 10)
      return message.error("Please select between 1 to 10 topics for your post")
    const postContentBody: any = {
      postContent: [],
      isPublic: true,
      topics: selectedTags || [],
    }

    let val = Object.keys(values)
      ?.sort()
      ?.map((key: any) => values[key])

    val?.map?.((value: any) => {
      if (value) {
        let valKey: string = Object.keys(value)[0]
        const contentType: any = postContentTypes[valKey]
        if (contentType) {
          if (valKey === "video") {
            value[valKey] &&
              getVideoUrl(value[valKey]) &&
              postContentBody.postContent.push(
                contentType(getVideoUrl(value[valKey]))
              )
          } else {
            postContentBody.postContent.push(contentType(value[valKey]))
          }
        }
      }
    })

    let IMG_INDEX = postContentBody.postContent.findIndex(
      (postContent: any) => postContent.type === "image"
    )


    setIsModalSaveLoading(true)
    let cTime = new Date().getTime()
    if (fileList[0]) {
      const uploadTask = app
        .storage()
        .ref(`community/poster/${mentorData?.uid}/${cTime}.png`)
        .put(fileList[0].originFileObj)

      uploadTask.on(
        "state_changed",
        snapshot => {
          return snapshot
        },
        err => console.error(err),
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(urlData => {
              if (urlData) {
                postContentBody.postContent.splice(
                  IMG_INDEX,
                  1,
                  postContentTypes.image(urlData, "")
                )
                createPostData(postContentBody)
              }
            })
            .catch(err => {
              setIsModalSaveLoading(false)
              message.error("Not able to upload image")
              // message.error({
              //   content: "Not able to upload the media",
              //   icon: (
              //     <img
              //       src={ErrorMessage}
              //       alt="error"
              //       style={{
              //         width: "32px",
              //         margin: "0em 1em",
              //       }}
              //     />
              //   ),
              // })
            })
        }
      )
    } else {
      postContentBody.postContent.splice(IMG_INDEX, 1)
      createPostData(postContentBody)
    }
  }

  const onUploadFile = ({ fileList: newFileList }) => {
    const sizeInMB = newFileList[0].size / 1024 / 1024
    const isLarge = sizeInMB > 2
    if (isLarge) {
      setIsPhoto({
        status: "error",
        message: `Image size is greater than 2MB`,
      })
      return console.error("Image must smaller than 2MB!")
    }
    setFileList(newFileList)
    setIsPhoto({ status: "success", message: "" })
  }

  const tagsHandleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter(t => t !== tag)

    setSelectedTags(nextSelectedTags)
  }

  return (
    <Modal
      title={<Text className="modalHeading">CREATE POST</Text>}
      className="createCommunityPost"
      visible={isCreatePostVisible}
      onOk={onFormFinish}
      onCancel={() => setisCreatePostVisible(false)}
      footer={null}
    >
      <Form
        form={createPostForm}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFormFinish}
        onFinishFailed={error => {}}
        autoComplete="off"
      >
        <Form.Item
          label={<Text className="labelText">Title</Text>}
          name={[1, "title"]}
          rules={[
            {
              whitespace: true,
              required: true,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Enter a title
                </Text>
              ),
            },
          ]}
        >
          <Input placeholder="Enter a title" />
        </Form.Item>
        <Form.Item
          label={<Text className="labelText">Text</Text>}
          name={[4, "text"]}
        >
          <Input.TextArea placeholder="Enter a text" rows={4} />
        </Form.Item>
        <Form.Item
          label={<Text className="labelText">Youtube Video Link</Text>}
          name={[2, "video"]}
        >
          <Input placeholder="Enter a youtube video link" />
        </Form.Item>
        <Form.Item
          label={<Text className="labelText">Image</Text>}
          validateStatus={isPhoto.status === "error" ? "error" : ""}
          help={
            isPhoto.message ? (
              <Text className="errorMessage">
                <img src={ErrorMessage} alt="errorMessage" />
                {isPhoto.message}
              </Text>
            ) : null
          }
          name={[3, "image"]}
          className="uploadPicForm"
        >
          <Skeleton.Avatar
            shape="square"
            className="defaultUser"
            style={{
              visibility: fileList.length >= 1 ? "hidden" : "visible",
            }}
          />
          <Upload
            onChange={onUploadFile}
            listType="picture-card"
            fileList={fileList}
            accept={"image/png, image/jpeg"}
            className="upload-list-inline"
            maxCount={1}
          >
            <div className="btnDiv">
              <Button type="text" className="uploadBtn">
                Upload Image
              </Button>
            </div>
            <Text className="text">
              You can upload .jpeg or .png image file. Max size of 2MB.
            </Text>
          </Upload>
          <Button
            type="text"
            className="removeBtn"
            onClick={() => {
              setFileList([])
            }}
          >
            Remove
          </Button>
        </Form.Item>
        <div className="interestsDiv">
          <Form.Item
            validateStatus={
              selectedTags?.length < 1 || selectedTags?.length > 10
                ? "error"
                : ""
            }
            help={
              selectedTags?.length < 1 || selectedTags?.length > 10 ? (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please select between 1 to 10 topics for your post
                </Text>
              ) : null
            }
            label={<Text className="labelText">Topics</Text>}
            // name="tags"
          >
            <div className="tags">
              {
                filters?.expertise?.map(tag => (
                  <CheckableTag
                    className="checkTag"
                    key={tag}
                    checked={selectedTags.indexOf(tag) > -1}
                    onChange={checked => tagsHandleChange(tag, checked)}
                  >
                    {tag}
                  </CheckableTag>
                ))}
            </div>
          </Form.Item>
        </div>
        <div className="actionsDiv">
          <Form.Item>
            <Button
              type="text"
              className="createPostBtn"
              htmlType="submit"
              loading={isModalSaveLoading}
            >
              Create Post
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default CreateCommunityPost
