import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Button } from "antd"
// import { AuthContext } from "../../context/AuthContext"
import NotFound from "../../../assets/pageNotFound/notFound.svg"
import { useLoggedInUserStore } from "../ZustandStateManagment/UserData/store"

const MentorRoute = ({ component: Component, path }) => {
  const authContext = useLoggedInUserStore()
  const { isLoggedIn, token, mentor, logout } = authContext

  return token && isLoggedIn ? (
    mentor ? (
      <Component path={path} />
    ) : (
      <div className="notFoundPageParent">
        <div className="notFoundPageDiv">
          <img src={NotFound} alt="not found illustration" />
          <h1>Please Sign in with mentor account to access this page</h1>
          <Button
            type="text"
            className="goBackBtn"
            onClick={() => {
              logout("/web/auth", {
                navigatedFrom: path,
                navigatedBackTo: `/web/${path}`,
              })
            }}
          >
            Sign out
          </Button>
        </div>
      </div>
    )
  ) : (
    <div className="notFoundPageParent">
      <div className="notFoundPageDiv">
        <img src={NotFound} alt="not found illustration" />
        <h1>Please Sign in to access this page</h1>
        <Button
          type="text"
          className="goBackBtn"
          onClick={() =>
            navigate("/web/auth", {
              state: {
                navigatedFrom: path,
                navigatedBackTo: `/web/${path}`,
              },
            })
          }
        >
          Go to Signin Page
        </Button>
      </div>
    </div>
  )
}

export default MentorRoute
