import React, { useContext, useEffect, useState } from "react"
import { Button, Input, message, Modal, Steps, Typography } from "antd"
import SharePopupIll from "../../../assets/mentroWebApp/sharePopupIll.svg"
import "./SharePopup.less"
import { patchHttpHandler } from "../../../utils/httpHandler"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { logGa } from "../../../../services/analytics"
const { Title, Text } = Typography

const SharePopup = ({ isSharePopupVisible, setIsSharePopupVisible }) => {
  const authContext = useLoggedInUserStore()
  const { token, mentorData } = authContext
  const [current, setCurrent] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [onBoardingPostContent, setOnBoardingPostContent] = useState<string>(
    mentorData?.onBoardingPostContent
  )

  const shareOnLinkedin = async () => {
    if (!onBoardingPostContent) {
      return message.error("Please enter something to Post")
    }
    setIsLoading(true)
    logGa("POPUP_CTA_CLICKED", {
      popup_type: "share_self_profile_popup",
    })
    try {
      const postContentResp = await patchHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/editPostContent`,
        body: { onBoardingPostContent },
        idToken: token,
      })
      setIsLoading(false)
      if (postContentResp.success) {
        setIsSharePopupVisible(false)
        setCurrent(0)
        return window.open(
          `${process.env.GATSBY_BE_URL}/mentor/linkedIn/setup?authorization=Bearer ${token}`
        )
      }
      message.error(postContentResp.message)
    } catch (error) {
      setIsLoading(false)
      message.error("Something went wrong!!!")
    }
  }

  const steps = [
    {
      key: 1,
      content: (
        <div className="shareDiv">
          <div className="top">
            <img src={SharePopupIll} alt="Linkedin share sharePopupIll" />
            <Title className="title">
              Share your presence to enable higher pricing and let more people
              discover you!
            </Title>
          </div>

          <Button
            type="text"
            className="shareBtn"
            onClick={() => setCurrent(current + 1)}
          >
            Share I'm on Mentro!
          </Button>
        </div>
      ),
    },
    {
      key: 2,
      content: (
        <div className="shareDiv">
          <div className="top">
            <Text className="labelText">EDIT POST CONTENT</Text>
            <Input.TextArea
              rows={10}
              value={onBoardingPostContent}
              onChange={e => setOnBoardingPostContent(e.target.value)}
            />
            <Button
              type="text"
              className="shareBtn"
              onClick={shareOnLinkedin}
              loading={isLoading}
            >
              Click here to share!!!
            </Button>
          </div>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (token) {
      setOnBoardingPostContent(mentorData?.onBoardingPostContent)
    }
  }, [token])
  return (
    <Modal
      className="shareModal"
      title={<Text className="modalHeading">Last Step of Set-Up</Text>}
      visible={isSharePopupVisible}
      onCancel={() => setIsSharePopupVisible(false)}
      footer={null}
    >
      <div className="contentDiv">{steps[current].content}</div>
    </Modal>
  )
}

export default SharePopup
