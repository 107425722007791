import React, { useEffect, useState } from "react"
import "./UserCohortDetail.less"
import { useParams, useLocation } from "@reach/router"
// import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"

import { loadScript } from "../../../utils/loadScript"
import { getHttpHandler, postHttpHandler } from "../../../utils/httpHandler"
import { navigate } from "gatsby"
import { Button, message, Modal, Typography, Collapse, Carousel } from "antd"
import { programFaqs } from "./programFaqs"
import moment from "moment"
import Clock from "../../../assets/mentroWebApp/clockWhite.svg"
import Calendar from "../../../assets/mentroWebApp/calendarWhite.svg"
import Rocket from "../../../assets/mentroWebApp/rocket.png"
import Guest from "../../../assets/mentroWebApp/defaultUser.svg"
import LinkedinGreen from "../../../assets/mentroWebApp/linkedinGreen.svg"
import TwitterGreen from "../../../assets/mentroWebApp/twitterGreen.svg"
import WhatsappGreen from "../../../assets/mentroWebApp/whatsappGreen.svg"
import CopyGreen from "../../../assets/mentroWebApp/copyGreen.svg"
import Briefcase from "../../../assets/mentroWebApp/briefcase.svg"
import UpArrow from "../../../../assets/mentor/upArrow.svg"
import DownArrow from "../../../../assets/mentor/downArrow.svg"
import MentroLogo from "../../../../assets/mentro/mentroLogo.svg"
import GreenTick from "../../../../assets/mentor/greenTick.svg"
import CohortDetailSkeleton from "../CohortDetailSkeleton"
import PaymentCard from "../../PaymentCard"
import { logGa } from "../../../../services/analytics"
import {
  osName,
  osVersion,
  browserName,
  browserVersion,
  deviceType,
} from "react-device-detect"
import Cookies from "js-cookie"
import { BulbFilled, StarFilled, FireFilled } from "@ant-design/icons"
import {
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import AuthPopup from "../../Popups/AuthPopup"

const { Text, Title } = Typography
const { Panel } = Collapse

const UserCohortDetail = () => {
  const params = useParams()
  const location = useLocation()
  const { token, user, isLoggedIn } = useLoggedInUserStore()
  // const { token, user, isLoggedIn } = useLoggedInUserStore()
  const [programId, setProgramId] = useState("")
  const [cohortDetails, setCohortDetails] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [bookProgramLoading, setBookProgramLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isPaymentModalVisible, setIsPaymentModalVisible] =
    useState<boolean>(false)
  const [modalDescData, setModalDescData] = useState<boolean>(false)
  const [pricingData, setPricingData] = useState<any>(null)
  const [promoCode, setPromoCode] = useState<string>("")
  const [promoCodeFinal, setPromoCodeFinal] = useState<string>("")
  const [isAuthPopupVisible, setIsAuthPopupVisible] = useState<boolean>(false)
  const timeNow = new Date()

  useEffect(() => {
    params?.programId && setProgramId(params.programId)
  }, [params])

  useEffect(() => {
    if (cohortDetails?.isRegistered && isPaymentModalVisible) {
      setIsPaymentModalVisible(false)
    }
    if (!cohortDetails?.isRegistered && isPaymentModalVisible) {
      setIsPaymentModalVisible(false)
      callOnLogin(cohortDetails)
    }
  }, [cohortDetails, isPaymentModalVisible])

  const programDetailTabs = [
    <div className="menuItemDivParent">
      <div className="menuItemDiv">
        <Title level={2} className="menuItemHeading subhead">
          Know your Mentor!
        </Title>
        <div className="mentorsList">
          {cohortDetails?.mentorsData?.map((mentor, ind) => (
            <>
              <div
                key={ind}
                className="mentorDataDiv"
                // onClick={() => navigate(`/web/@${mentor?.profileId}`)}
              >
                <img
                  src={mentor?.image || Guest}
                  alt="mentor Profile"
                  className="mentorImage"
                />
                <div className="mentorData">
                  <Title className="mentorName">{mentor?.name}</Title>
                  <Text className="mentorDesignation">{mentor?.headline}</Text>
                  <Text className="mentorDescription">{mentor?.text}</Text>
                </div>
              </div>
              <div className="mentorCompanies">
                {mentor?.companies?.map(compUrl => (
                  <img
                    key={compUrl}
                    src={compUrl || Briefcase}
                    alt="mentor Profile"
                    className="companyImg"
                  />
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
    </div>,
    cohortDetails?.prerequisites?.length > 0 ? (
      <div className="menuItemDivParent lightGreenMenuItem">
        <div className="menuItemDiv">
          <div className="columnflexDiv shrinkDiv">
            <Title className="menuItemHeading subhead">Who is this for?</Title>
            <div className="flex-content" style={{ alignItems: "center" }}>
              {cohortDetails?.prerequisites.map((pre, index) => (
                <Text
                  className="menuItemText flex-item"
                  style={{ width: "fit-content" }}
                  key={index}
                >
                  <BulbFilled
                    twoToneColor="#4caf50"
                    style={{ fontSize: "1.3em" }}
                  />
                  {pre}
                </Text>
              ))}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    ),
    cohortDetails?.topics?.length > 0 ? (
      <div className="menuItemDivParent">
        <div className="menuItemDiv">
          <div className="columnflexDiv shrinkDiv">
            <Title level={2} className="menuItemHeading subhead">
              What are you going to Learn?
            </Title>
            <div className="flex-content">
              {cohortDetails?.topics.map((topic, index) => (
                <Text
                  className="menuItemText flex-item topics-row"
                  key={index}
                >
                  <FireFilled />
                  {topic}
                </Text>
              ))}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    ),
    cohortDetails?.expectations?.length > 0 ? (
      <div className="menuItemDivParent lightGreenMenuItem">
        <div className="menuItemDiv">
          <div className="gridColumnsDiv">
            <Title level={2} className="menuItemHeading subhead">
              Whats in there?
            </Title>
            <div className="grid-content top-shrink-div">
              {cohortDetails?.expectations.map((expectation, index) => (
                <Text className="menuItemText grid-item" key={index}>
                  {/* <img
                  src={expectation?.icon || Tick}
                  alt="check mark"
                  className="menuItemIcon"
                /> */}
                  {expectation?.text}
                </Text>
              ))}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    ),
    !cohortDetails?.isRegistered ? (
      <div className="menuItemDivParent darkGreenMenuItem">
        <div className="menuItemDiv">
          <div className="timerDiv">
            <Title level={2} className="menuItemHeading subhead">
              Time is Running Out
            </Title>
            <p className="timer" id="offerEndsTimer"></p>
            <div className="timerRegisterDiv">
              {!cohortDetails?.isRegistered && (
                <Title className="price" style={{ textAlign: "center" }}>
                  <span className="cut">₹{cohortDetails?.cancelledPrice}</span>
                  <span className="notCut">
                    {cohortDetails?.price === 0
                      ? "FREE"
                      : `/₹${cohortDetails?.price}`}
                  </span>
                </Title>
              )}
              <Button
                type="text"
                className="timerRegisterBtn"
                onClick={() => {
                  if(programId === "M45ITjpNjCcls8lpnfSb"){
                    return window.open("https://bit.ly/3JBoIDrh")
                  }
                  logGa("ITEM_CLICK", {
                    item_name: "program_join_btn",
                    screen_name: `program_${programId}`,
                  })
                  if (!isLoggedIn) {
                    return setIsAuthPopupVisible(true)
                  }
                  if (cohortDetails?.status !== "Open") {
                    return message.error("Program is currently not available.")
                  }
                  
                  if (!cohortDetails?.isRegistered) {
                    if (pricingData?.ultimatePrice === "0.00") {
                      bookProgram()
                    } else {
                      setIsPaymentModalVisible(true)
                    }
                  } else if (
                    cohortDetails?.isRegistered &&
                    cohortDetails?.joiningLink
                  ) {
                    window.open(cohortDetails?.joiningLink)
                  }
                }}
                loading={bookProgramLoading}
              >
                {cohortDetails?.isRegistered ? "Join" : "Register Now"}
              </Button>
              <div className="timerContent">
                {!cohortDetails?.isRegistered &&
                  cohortDetails?.promocode?.code && (
                    <Title className="timerText">
                      Use Code: {cohortDetails?.promocode?.code} to avail{" "}
                      {cohortDetails?.promocode?.percentage}% Discount.
                    </Title>
                  )}
                {!cohortDetails?.isRegistered &&
                  cohortDetails?.promocode?.code && (
                    <Title className="timerText">
                      Offer Valid till midnight,{" "}
                      {moment().format("D MMM, YYYY")}.
                    </Title>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    ),
    cohortDetails?.certificate && (
      <div className="menuItemDivParent">
        <div className="menuItemDiv">
          <Title level={2} className="menuItemHeading subhead">
            Get Certified
          </Title>
          <div className="certificateDiv">
            <img
              src={cohortDetails?.certificate}
              alt="certificate"
              className="certificateImg"
            />
          </div>
        </div>
      </div>
    ),
    cohortDetails?.after?.length > 0 ? (
      <div className="menuItemDivParent lightGreenMenuItem">
        <div className="menuItemDiv">
          <div className="columnflexDiv shrinkDiv">
            <Title level={2} className="menuItemHeading subhead">
              Whats after the Program?
            </Title>
            <div
              className="flex-content"
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {cohortDetails?.after.map((pre, index) => (
                <Text
                  className="menuItemText flex-item darkGreenOnly"
                  style={{
                    width: "fit-content",
                    flexGrow: "1",
                    justifyContent: "flex-start",
                  }}
                  key={index}
                >
                  <StarFilled style={{ color: "#ffef00" }} />
                  {pre}
                </Text>
              ))}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    ),

    cohortDetails?.inActionImages?.length > 0 ? (
      <div className="menuItemDivParent">
        <div className="menuItemDiv">
          <Title level={2} className="menuItemHeading subhead">
            Your Mentor in Action
          </Title>
          <div className="carouselDiv">
            <Carousel
              autoplay={true}
              className="carousel"
              appendDots={dots => <ul className="normal-dot">{dots}</ul>}
            >
              {cohortDetails?.inActionImages?.map((imgLink, ind) => (
                <div className="bannerImgDiv">
                  <img
                    src={imgLink}
                    key={ind}
                    className="bannerImage"
                    alt="banner"
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    ) : (
      <></>
    ),
    cohortDetails?.reviews?.length > 0 ? (
      <div className="menuItemDivParent">
        <div className="menuItemDiv">
          <Title
            level={2}
            className="menuItemHeading subhead"
            style={{ marginBottom: 0 }}
          >
            Are you still Waiting?
          </Title>
          <Title level={2} className="menuItemHeading subhead">
            Don't worry Grab a look at some Reviews!
          </Title>
          <div className="gridColumnsDiv reviewsDiv">
            <div className="grid-content top-shrink-div">
              {cohortDetails?.reviews.map((review, index) => (
                <div className="reviewDiv" key={index}>
                  <div className="userData">
                    <img
                      src={review?.image || Guest}
                      className="userImg"
                      alt="user"
                    />
                    <div className="ratingBy">
                      <Title className="userName">
                        {review?.name || "Guest"}
                      </Title>
                      <Text className="reviewDate">
                        {moment(review?.lastReviewed).format("DD/MM/YYYY")}
                      </Text>
                    </div>
                  </div>
                  <Title className="reviewText">"{review?.review}"</Title>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    ),
    programFaqs?.length > 0 ? (
      <div className="menuItemDivParent">
        <div className="menuItemDiv">
          <Title
            level={2}
            className="menuItemHeading subhead"
            style={{ marginBottom: 0 }}
          >
            Frequently Asked Questions
          </Title>
          <div className="faqQuestionsDiv">
            <Collapse
              bordered={false}
              ghost={true}
              accordion={true}
              expandIcon={({ isActive }) => (
                <img src={isActive ? UpArrow : DownArrow} alt="action" />
              )}
              expandIconPosition="right"
              className="contentCol"
            >
              {programFaqs?.map((faq, ind) => {
                return (
                  <Panel
                    header={<Title className="question">{faq.question}</Title>}
                    key={ind}
                    className="site-collapse-custom-panel questionPanel"
                  >
                    <Text className="answer">{faq.answer}</Text>
                  </Panel>
                )
              })}
            </Collapse>
          </div>
        </div>
      </div>
    ) : (
      <></>
    ),
  ]

  const getCohortDetails = async () => {
    setLoading(true)
    try {
      const cohortsResp = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/program/getProgram?programId=${programId}`,
        idToken: token,
      })
      setLoading(false)
      if (cohortsResp?.program?.programId) {
        await getPricingData(cohortsResp.program)
        setCohortDetails(cohortsResp.program)
        if (location?.hash === "#register") {
          !isLoggedIn
            ? setIsAuthPopupVisible(true)
            : callOnLogin(cohortsResp.program)
        }
      } else {
        navigate("/error", {
          replace: true,
        })
      }
    } catch (error) {
      navigate("/error", {
        replace: true,
      })
      setLoading(false)
    }
  }

  const getPricingData = async programData => {
    try {
      const pricingData = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/v2/users/getUltimatePrice?payment=${programData?.price}&promoCode=${promoCode}&typeId=${programData?.programId}&type=program`,
        idToken: token,
      })
      if (pricingData.isValid && promoCode) {
        setPromoCodeFinal(promoCode)
      } else {
        setPromoCodeFinal("")
      }
      setPricingData(pricingData)
    } catch (error) {
      //
    }
  }

  const bookFreeProgram = async orderId => {
    setBookProgramLoading(true)
    try {
      const bookProgramResponse = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/v3/session/createNewSession`,
        idToken: token,
        body: {
          orderCreationId: orderId,
          programId: cohortDetails?.programId,
          promoCode: promoCodeFinal,
        },
      })
      if (bookProgramResponse.success) {
        bookProgramCheck()
        return true
      } else {
        logGa("PAYMENT_STATUS", {
          screen_name: `program_${programId}`,
          status: "error",
        })
        setBookProgramLoading(false)
        setIsPaymentModalVisible(false)
        return message.error(
          "Cannot book program!!! Please email us at contact@mentro.tech"
        )
      }
    } catch (error) {
      setBookProgramLoading(false)
      setIsPaymentModalVisible(false)
      logGa("PAYMENT_STATUS", {
        screen_name: `program_${programId}`,
        status: "error",
      })
      return message.error(
        "Cannot book program!!! Please email us at contact@mentro.tech"
      )
    }
  }

  const bookProgramCheck = async () => {
    setBookProgramLoading(true)
    let programStatusInterval
    let apiRepeatCount = 1

    programStatusInterval = setInterval(async () => {
      if (apiRepeatCount > 5) {
        setBookProgramLoading(false)
        setIsPaymentModalVisible(false)
        setModalDescData(false)
        setIsModalVisible(true)
        getCohortDetails()
        logGa("PAYMENT_STATUS", {
          screen_name: `program_${programId}`,
          status: "pending",
        })
        return clearInterval(programStatusInterval)
      }

      apiRepeatCount += 1
      try {
        const bookProgramResp = await getHttpHandler({
          url: `${process.env.GATSBY_BE_URL}/program/getProgram?programId=${programId}`,
          idToken: token,
        })

        if (
          bookProgramResp?.program?.programId &&
          bookProgramResp?.program?.isRegistered
        ) {
          setBookProgramLoading(false)
          setIsPaymentModalVisible(false)
          setModalDescData(true)
          setIsModalVisible(true)
          setCohortDetails(bookProgramResp.program)
          logGa("PAYMENT_STATUS", {
            screen_name: `program_${programId}`,
            status: "success",
          })
          clearInterval(programStatusInterval)
        }
      } catch (error) {
        logGa("PAYMENT_STATUS", {
          screen_name: `program_${programId}`,
          status: "error",
        })
        message.error(
          "Cannot book program!!! Please email us at contact@mentro.tech"
        )
        apiRepeatCount = 6
        setBookProgramLoading(false)
        setIsPaymentModalVisible(false)
      }
    }, 2000)
  }

  const bookProgram = async () => {
    logGa("ITEM_CLICK", {
      item_name: "program_checkout_btn",
      screen_name: `program_${programId}`,
    })

    setBookProgramLoading(true)
    try {
      const orderDetail = await postHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/generateProgramOrder`,
        idToken: token,
        body: {
          programId,
          promoCode: promoCodeFinal,
          from: {
            c: Cookies.get("c") || "",
            ev: Cookies.get("ev") || "",
            cid: Cookies.get("cid") || "",
            device: deviceType,
            os: osName + " " + osVersion,
            browser: browserName + " " + browserVersion,
          },
        },
      })

      if (!orderDetail.id) {
        setBookProgramLoading(false)
        setIsPaymentModalVisible(false)
        return message.error(
          "Failed to get order details!!! Please try again in a while."
        )
      }

      if (orderDetail.amount > 0) {
        const options = {
          key: process.env.GATSBY_RAZORPAY_KEY,
          currency: orderDetail.currency,
          amount: orderDetail.amount,
          name: "Mentro",
          description: "Program Payment",
          image: <img src={MentroLogo} alt="mentroLogo" />,
          order_id: orderDetail.id,
          handler: function (response) {
            if (response.razorpay_payment_id) {
              setBookProgramLoading(false)
              bookProgramCheck()
            }
          },
          prefill: {
            name: user?.userName,
            email: user?.email,
          },
        }

        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
        setBookProgramLoading(false)
      } else {
        bookFreeProgram(orderDetail.id)
      }
    } catch (error) {
      setBookProgramLoading(false)
      setIsPaymentModalVisible(false)
      return message.error("Failed to book your Program!!! Please try again.")
    }
  }

  const callOnLogin = cohortDetails => {
    if (cohortDetails?.status !== "Open") {
      return message.error("Program is currently not available.")
    }
    if (!cohortDetails?.isRegistered) {
      if (pricingData?.ultimatePrice === "0.00") {
        bookProgram()
      } else {
        setIsPaymentModalVisible(true)
      }
    } else if (cohortDetails?.isRegistered) {
      return message.success("You are already registered for this program.")
    }
  }

  useEffect(() => {
    if (programId) {
      getCohortDetails()
      const loadRazorpayScript = async () => {
        await loadScript("https://checkout.razorpay.com/v1/checkout.js")
      }
      loadRazorpayScript()
      logGa("PAGE_LANDING", { screen_name: `program_${programId}` })
    }
  }, [programId, token])

  const getTimeRemaining = endtime => {
    const total = endtime - new Date().getTime()

    let seconds: string = Math.floor((total / 1000) % 60).toString()
    let minutes: string = Math.floor((total / 1000 / 60) % 60).toString()
    let hours: string = Math.floor((total / (1000 * 60 * 60)) % 24).toString()
    let days: number = Math.floor(total / (1000 * 60 * 60 * 24))

    seconds = seconds.length === 1 ? `0${seconds}` : seconds
    minutes = minutes.length === 1 ? `0${minutes}` : minutes
    hours = hours.length === 1 ? `0${hours}` : hours

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    }
  }

  useEffect(() => {
    const updateTimer = () => {
      const t = getTimeRemaining(new Date().setHours(24, 0, 0, 0))
      let d: any = document?.getElementById("offerEndsTimer")
      if (
        cohortDetails?.status === "Open" &&
        t.total > 0 &&
        !cohortDetails?.isRegistered &&
        d
      ) {
        d.innerHTML = `${t?.hours} : ${t?.minutes} : ${t?.seconds}`
      } else {
        if (d) d.innerHTML = ""
      }
      if (d) setTimeout(updateTimer, 1000)
    }
    if (cohortDetails?.status === "Open" && !cohortDetails?.isRegistered) {
      updateTimer()
    }
  }, [cohortDetails])

  return (
    <div className="cohortDetailParent">
      <AuthPopup
        setIsAuthPopupVisible={setIsAuthPopupVisible}
        isAuthPopupVisible={isAuthPopupVisible}
        callOnLogin={() => callOnLogin(cohortDetails)}
      />
      <Modal
        className="paymentModal"
        visible={isPaymentModalVisible}
        onOk={() => {}}
        onCancel={() => setIsPaymentModalVisible(false)}
        footer={null}
      >
        <PaymentCard
          bookingTitle="Program"
          gistData={
            <Title className="gist2">
              Schedule your <span>{cohortDetails?.title || "Program"}</span>{" "}
              with <span>{cohortDetails?.by?.userName || "Mentor"}</span> on{" "}
              <span>
                {moment(cohortDetails?.startTime || timeNow.getTime()).format(
                  "DD/MM/YYYY"
                )}
              </span>
            </Title>
          }
          amount={cohortDetails?.price}
          pricingData={pricingData}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          applyPromoCode={() => getPricingData(cohortDetails)}
          makePayment={bookProgram}
          loadingPaymentBtn={bookProgramLoading}
          paymentBtnText="JOIN"
        />
      </Modal>
      <Modal
        className="programSuccessModal"
        visible={isModalVisible}
        onOk={() => {}}
        onCancel={() => {
          // if (cohortDetails?.connectedLink) {
          //   window.open(cohortDetails?.connectedLink)
          // }
          setIsModalVisible(false)
        }}
        footer={null}
      >
        <div className="successDiv">
          {modalDescData ? (
            <>
              <img
                src={GreenTick}
                alt="applied successfully or failure illustration"
              />
              <Title className="title">
                Awesome! You are registered for the program. Check your email
                and Join the sessions from this page or Android app at scheduled
                time.
              </Title>
              {/* <Button
                type="text"
                className="joinGrpBtn"
                onClick={() => window.open(cohortDetails?.joiningLink)}
              >
                Join
              </Button> */}
            </>
          ) : (
            <Title className="text">
              Your program is being booked. This is taking an unusual longer
              time. You can rest assured, that your program will be booked if
              the payment was successful. Check back in few minutes under the
              "Programs" tab in Home Screen.
            </Title>
          )}
        </div>
      </Modal>
      {cohortDetails && !loading ? (
        <div className="cohortDetailDiv">
          {!cohortDetails?.isRegistered && cohortDetails?.promocode?.code && (
            <div className="discountTopBarParent">
              <Title className="discountTopBar">
                Use Code {cohortDetails?.promocode?.code} | Avail Extra{" "}
                {cohortDetails?.promocode?.percentage}% Discount | Limited
                Period
              </Title>
            </div>
          )}
          <div className="cohortIntroDivParent">
            <div className="cohortIntroDiv">
              <Title className="cohortHeading">{cohortDetails?.title}</Title>
              <Text className="cohortDesc">{cohortDetails?.description}</Text>
              <div className="introContentDiv">
                <img
                  src={cohortDetails?.banner}
                  className="cohortBanner"
                  alt="banner"
                />
                <div className="rightIntroContentDiv">
                  <div className="topIntroDiv">
                    {" "}
                    <div className="detailsDiv">
                      <div className="detailDiv">
                        <img src={Clock} alt="clock" />
                        <Text className="detailDesc">
                          {cohortDetails?.duration}
                        </Text>
                      </div>
                      <div className="detailDiv">
                        <img src={Calendar} alt="clock" />
                        <Text className="detailDesc">
                          {moment(
                            cohortDetails?.startTime || timeNow.getTime()
                          ).format("Do MMM, YYYY")}
                        </Text>
                      </div>
                    </div>
                    <div className="shareBtns">
                      <LinkedinShareButton
                        url={`${process.env.GATSBY_HOME_URL}/programs/${programId}`}
                      >
                        <Button type="text" className="shareBtn">
                          <span>Share</span>
                          <img src={LinkedinGreen} alt="linkedin" />
                        </Button>
                      </LinkedinShareButton>
                      <TwitterShareButton
                        url={`${process.env.GATSBY_HOME_URL}/programs/${programId}`}
                      >
                        <Button type="text" className="shareBtn">
                          <span>Tweet</span>
                          <img src={TwitterGreen} alt="twitter" />
                        </Button>
                      </TwitterShareButton>
                      <WhatsappShareButton
                        url={`${process.env.GATSBY_HOME_URL}/programs/${programId}`}
                      >
                        <Button type="text" className="shareBtn">
                          <img src={WhatsappGreen} alt="whatsapp" />
                        </Button>
                      </WhatsappShareButton>
                      <Button
                        type="text"
                        className="shareBtn"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${process.env.GATSBY_HOME_URL}/programs/${programId}`
                          )
                          message.success(
                            "Ready to Share! Copied to the clipboard !!!"
                          )
                        }}
                      >
                        <img src={CopyGreen} alt="copy" />
                      </Button>
                    </div>
                  </div>
                  <div className="bottomIntroDiv desktopViewOnly">
                    {!cohortDetails?.isRegistered && (
                      <Title className="price">
                        <span className="cut">
                          ₹{cohortDetails?.cancelledPrice}
                        </span>
                        <span className="notCut">
                          {cohortDetails?.price === 0
                            ? "FREE"
                            : `/₹${cohortDetails?.price}`}
                        </span>
                      </Title>
                    )}
                    <Button
                      type="text"
                      className="joinBtnUserCheckBtn"
                      onClick={() => {
                        if(programId === "M45ITjpNjCcls8lpnfSb"){
                          return window.open("https://bit.ly/3JBoIDr")
                        }
                        logGa("ITEM_CLICK", {
                          item_name: "program_join_btn",
                          screen_name: `program_${programId}`,
                        })
                        if (!isLoggedIn) {
                          return setIsAuthPopupVisible(true)
                        }
                        if (cohortDetails?.status !== "Open") {
                          return message.error(
                            "Program is currently not available."
                          )
                        }
                        if (!cohortDetails?.isRegistered) {
                          if (pricingData?.ultimatePrice === "0.00") {
                            bookProgram()
                          } else {
                            setIsPaymentModalVisible(true)
                          }
                        } else if (
                          cohortDetails?.isRegistered &&
                          cohortDetails?.joiningLink
                        ) {
                          window.open(cohortDetails?.joiningLink)
                        }
                      }}
                      loading={bookProgramLoading}
                    >
                      {cohortDetails?.isRegistered ? "Join" : "Register Now"}
                    </Button>
                  </div>
                  {cohortDetails?.price === 0 && (
                    <Title className="discountHeading">
                      <img src={Rocket} />
                      <span>
                        {Math.floor(
                          ((cohortDetails?.startTime &&
                          timeNow.getTime() < cohortDetails?.startTime
                            ? timeNow.getTime()
                            : cohortDetails?.startTime ||
                              cohortDetails?.createdAt) -
                            (cohortDetails?.createdAt || timeNow.getTime())) /
                            1800000
                        ) + cohortDetails?.noOfParticipants}{" "}
                        people registered
                      </span>
                    </Title>
                  )}
                </div>
              </div>
            </div>
          </div>
          {programDetailTabs}
          <div className="mobileJoinBtnDiv">
            <div className="mobileJoinBtnDivText">
              {!cohortDetails?.isRegistered && (
                <Title className="price">
                  <span className="cut">₹{cohortDetails?.cancelledPrice}</span>
                  <span className="notCut">
                    {cohortDetails?.price === 0
                      ? "FREE"
                      : `/₹${cohortDetails?.price}`}
                  </span>
                </Title>
              )}
            </div>
            <Button
              type="text"
              className="mobileJoinBtn"
              onClick={() => {
                logGa("ITEM_CLICK", {
                  item_name: "program_join_btn",
                  screen_name: `program_${programId}`,
                })
                if (!isLoggedIn) {
                  return setIsAuthPopupVisible(true)
                }
                if (cohortDetails?.status !== "Open") {
                  return message.error("Program is currently not available.")
                }
                if (!cohortDetails?.isRegistered) {
                  if (pricingData?.ultimatePrice === "0.00") {
                    bookProgram()
                  } else {
                    setIsPaymentModalVisible(true)
                  }
                } else if (
                  cohortDetails?.isRegistered &&
                  cohortDetails?.joiningLink
                ) {
                  window.open(cohortDetails?.joiningLink)
                }
              }}
              loading={bookProgramLoading}
            >
              {cohortDetails?.isRegistered ? "Join" : "Register Now"}
            </Button>
          </div>
        </div>
      ) : (
        <div className="cohortSkelParent">
          <CohortDetailSkeleton />
        </div>
      )}
    </div>
  )
}

export default UserCohortDetail
