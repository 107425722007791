import React from "react"
import "./JobsPopup.less"
import { Button, Modal, Typography } from "antd"
import jobPopup from "../../../assets/mentroWebApp/jobPopup.svg"
import { navigate } from "gatsby"
import { logGa } from "../../../../services/analytics"
const { Title } = Typography

const JobsPopup = ({ isJobsVisible, setIsJobsVisible }) => {
  return (
    <Modal
      className="jobsModal"
      visible={isJobsVisible}
      onOk={() => {}}
      onCancel={() => setIsJobsVisible(false)}
      footer={null}
    >
      <div className="jobsDiv">
        <div className="top">
          <div className="rewardsImgContainer">
            <img src={jobPopup} alt="jobPopup" />
          </div>
          <Title className="title">Learn with Mentors</Title>
        </div>
        <Title className="text">
          Join communities of your favourite mentors and start learning domains
          of your choice. Be it Web Development, Android, Open Source, ML,
          Interview Prep, you name it!
        </Title>

        <Button
          type="text"
          className="jobsBtn"
          onClick={() => {
            logGa("POPUP_CTA_CLICKED", {
              popup_type: "job_funnel_popup",
            })
            navigate("/web/")
            setIsJobsVisible(false)
          }}
        >
          Check Communities
        </Button>
      </div>
    </Modal>
  )
}

export default JobsPopup
