import React, { useContext, useState } from "react"
import "./BottomBar.less"
import { Button, Col, Row, Typography } from "antd"
import clsx from "clsx"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { useLocation } from "@reach/router"
import { mentorSideBarLinks, userSideBarLinks } from "../../../utils/data"
import { navigate } from "gatsby-link"
import CreateCommunityPost from "../../Community/CreateCommunityPost"

const { Text } = Typography

const BottomBar = () => {
  const authContext = useLoggedInUserStore()
  const { mentor, isLoggedIn, token } = authContext
  const [isCreatePostVisible, setisCreatePostVisible] = useState<boolean>(false)

  const location = useLocation()
  return (
    <div className="bottomBar">
      <CreateCommunityPost
        communityId={null}
        onCreatePost={() => {}}
        isCreatePostVisible={isCreatePostVisible}
        setisCreatePostVisible={setisCreatePostVisible}
      />
      <Row className="tabsRow">
        {token && isLoggedIn && mentor ? (
          mentorSideBarLinks
            .filter(l => l.inBottomBar)
            ?.sort((a, b) => a.sidebarOrder - b.sidebarOrder)
            .map((link, ind) => (
              <Col className="tabCol mentorTabCol" key={link.key}>
                <Button
                  type="text"
                  className={clsx([
                    "mentorTabsBtn",
                    location.pathname === link.link && "activeTab",
                    link.name === "Post" && "postTab",
                  ])}
                  onClick={() => {
                    if (link.link) {
                      navigate(link.link)
                    }
                    if (link.name === "Post") {
                      setisCreatePostVisible(true)
                    }
                  }}
                  key={link.key}
                >
                  {link.icon}
                  <Text className="tabName">{link.name}</Text>
                </Button>
              </Col>
            ))
        ) : (
          <>
            {userSideBarLinks
              .filter(l => l.inBottomBar)
              ?.sort((a, b) => a.sidebarOrder - b.sidebarOrder)
              .map(link => {
                if (link.private && !isLoggedIn) {
                  return
                }
                return (
                  <Col className="tabCol" key={link.key}>
                    <Button
                      type="text"
                      className={clsx([
                        "tabsBtn",
                        location.pathname === link.link && "activeTab",
                        link.link === "/web/" &&
                          location.pathname === "/web" &&
                          "activeTab",
                      ])}
                      onClick={() => {
                        if (link.link) {
                          navigate(link.link)
                        }
                      }}
                      key={link.key}
                    >
                      {link.icon}
                      <Text className="tabName">{link.name}</Text>
                    </Button>
                  </Col>
                )
              })}
          </>
        )}
      </Row>
    </div>
  )
}

export default BottomBar
