import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import "./MentorSessions.less"
import {
  Button,
  Col,
  message,
  Progress,
  Row,
  Skeleton,
  Table,
  Tabs,
  Typography,
} from "antd"
import RightLargeArrow from "../../../assets/mentroWebApp/rightLargeArrow.svg"
import AverageRating from "../../../assets/mentroWebApp/averageRating.svg"
import MeetArrow from "../../../assets/mentroWebApp/meetArrow.svg"
import EndSession from "../../../assets/mentroWebApp/endSession.svg"
import DateIcon from "../../../assets/mentroWebApp/date.svg"
import Amount from "../../../assets/mentroWebApp/amount.svg"
import Clock from "../../../assets/mentroWebApp/clock.svg"
import NotFound from "../../../../assets/pageNotFound/notFound.svg"
import { useSessions } from "../../../hooks/useSessions"
import { navigate } from "gatsby-link"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { getHttpHandler, patchHttpHandler } from "../../../utils/httpHandler"

const { Title, Text } = Typography
const { TabPane } = Tabs

const Sessions = () => {
  const authContext = useLoggedInUserStore()
  const { token } = authContext
  const [mentorData, setMentorData] = useState<any>(null)

  const getMentorsData = async () => {
    try {
      const mentorsResponseData = await getHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/getMentorDataForDashboard`,
        idToken: token,
      })
      setMentorData(mentorsResponseData.data)
    } catch (error: any) {
      //
    }
  }

  const {
    getSessions,
    sessions,
    setSessions,
    activeTab,
    setActiveTab,
    hasMoreSessions,
    loading,
    setDocId,
    dataReceived,
  } = useSessions()
  // -----------------------------------------------------------------
  // INFINITE SCROLLING-----------------------------------------------------------------
  // -----------------------------------------------------------------
  const observer = useRef<any>()
  const lastSessionElement = useCallback(
    ele => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasMoreSessions) {
            setDocId(sessions[sessions.length - 1].sessionId)
          }
        },
        {
          root: null,
          rootMargin: "40px",
          threshold: 0,
        }
      )
      if (ele) observer.current.observe(ele)
    },
    [loading, hasMoreSessions]
  )
  //-----------------------------------

  const endSession = async (sessionId: string) => {
    try {
      const endSessionResp = await patchHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/mentor/endSessionMentor?sessionId=${sessionId}`,
        idToken: token,
      })
      getSessions()
      if (endSessionResp.success) {
        return message.success("Session Ended Successfully!!!")
      }
      return message.error(endSessionResp.message)
    } catch (error) {
      message.error("Something went wrong!! Try again in some time.")
    }
  }

  useEffect(() => {
    if (token) {
      getMentorsData()
    }
  }, [token, activeTab])

  const columns = [
    {
      title: <Text className="tableColName">TOPIC</Text>,
      dataIndex: "topic",
      key: "topic",
      width: "25%",
    },
    {
      title: <Text className="tableColName">MENTEE</Text>,
      dataIndex: "mentee",
      key: "mentee",
      width: "15%",
    },
    {
      title: <Text className="tableColName">TIME</Text>,
      dataIndex: "time",
      key: "time",
    },
    {
      title: <Text className="tableColName">DATE</Text>,
      dataIndex: "date",
      key: "date",
    },
    {
      title: <Text className="tableColName">AMOUNT</Text>,
      dataIndex: "amount",
      key: "amount",
      width: "10%",
    },
    {
      title: <Text className="tableColName">STATUS</Text>,
      dataIndex: "status",
      key: "status",
      render: status =>
        status.status === "skeleton" ? (
          <Skeleton.Button className="skel1" active size="small" />
        ) : (
          <Text className={`status ${status.status}`}>{status.statusName}</Text>
        ),
      width: "15%",
    },
    {
      title: <Text className="tableColName">MEET</Text>,
      dataIndex: "meet",
      key: "meet",
    },
    {
      title: <Text className="tableColName">END</Text>,
      dataIndex: "end",
      key: "end",
    },
  ]

  const getFormattedTime = date => {
    let formattedTime = new Date(date).toLocaleTimeString("en-US").split(" ")
    let part1 =
      formattedTime[0].split(":")[0] + ":" + formattedTime[0].split(":")[1]
    return part1 + " " + formattedTime[1]
  }

  const getSessionsData = () => {
    let data: any = []
    sessions &&
      sessions.map((session, index) => {
        const date = new Date(session.eventTime)
        data.push({
          key: session.sessionId,
          topic: (
            <Text className="dataText topic">
              {session.title || session.sessionType}
            </Text>
          ),
          mentee: (
            <Text className="dataText mentee">
              {session.participantDetails[1].name}
            </Text>
          ),
          time: <Text className="dataText time">{getFormattedTime(date)}</Text>,
          date: (
            <Text className="dataText date">{`${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`}</Text>
          ),
          amount: <Text className="dataText amount">{session.amount}</Text>,
          status: {
            statusName: session.status,
            status: session.status,
          },
          meet: (
            <img
              src={MeetArrow}
              alt="meet arrow"
              style={{ cursor: "pointer" }}
              onClick={() => window.open(session.meetLink)}
            />
          ),
          end: (
            <img
              src={EndSession}
              alt="end session"
              style={{
                cursor: "pointer",
                opacity: session.status === "completed" ? 0.45 : 1,
              }}
              onClick={() => {
                if (session.status === "completed") {
                  return message.error("Session has already ended!")
                }
                endSession(session.sessionId)
              }}
            />
          ),
        })
      })

    //LOADING SKELETON
    loading &&
      hasMoreSessions &&
      data.push({
        key: "skeleton1",
        topic: <Skeleton.Button className="skel1" active size="small" />,
        mentee: <Skeleton.Button className="skel1" active size="small" />,
        time: <Skeleton.Button active size="small" />,
        date: <Skeleton.Button active size="small" />,
        amount: <Skeleton.Button active size="small" />,
        status: {
          statusName: "skeleton",
          status: "skeleton",
        },
        meet: <Skeleton.Avatar className="promo1" active size="small" />,
        end: <Skeleton.Avatar className="promo1" active size="small" />,
      })
    return data
  }

  const getSkeleton = () => {
    return [
      {
        key: "skeleton1",
        topic: <Skeleton.Button className="skel1" active size="small" />,
        mentee: <Skeleton.Button className="skel1" active size="small" />,
        time: <Skeleton.Button active size="small" />,
        date: <Skeleton.Button active size="small" />,
        amount: <Skeleton.Button active size="small" />,
        status: {
          statusName: "skeleton",
          status: "skeleton",
        },
        meet: <Skeleton.Avatar className="promo1" active size="small" />,
        end: <Skeleton.Avatar className="promo1" active size="small" />,
      },
    ]
  }

  const getMobileSessions = () => {
    return (
      sessions &&
      sessions.map((session, ind) => (
        <Col xs={24} key={session.sessionId} className="sessionCol">
          <Title className="dataText sessionType">
            {session.title || session.sessionType}
          </Title>
          <Title className="dataText mentee">
            With {session.participantDetails[1].name}
          </Title>
          <div className="contentDiv">
            <div className="left">
              <div className="leftBox">
                <img src={Amount} alt="amount" />
                Amount: <Text className="dataText">₹ {session.amount}</Text>
              </div>
              <div className="leftBox">
                <img src={Clock} alt="clock" />
                Time:{" "}
                <Text className="dataText">
                  {getFormattedTime(new Date(session.eventTime))}
                </Text>
              </div>
            </div>
            <div className="right">
              <div className="rightBox">
                <Text className={`status ${session.status}`}>
                  {session.status}
                </Text>
              </div>
              <div className="rightBox">
                <img src={DateIcon} alt="dateIcon" />
                Date:{" "}
                <Text className="dataText">{`${new Date(
                  session.eventTime
                ).getDate()}/${
                  new Date(session.eventTime).getMonth() + 1
                }/${new Date(session.eventTime).getFullYear()}`}</Text>
              </div>
            </div>
          </div>
          <div className="btns">
            <Button
              type="text"
              className="btn chatBtn"
              onClick={() => {
                navigate("/web/chats")
              }}
            >
              Chat
            </Button>
            <Button
              type="link"
              href={session.meetLink}
              target="_blank"
              className="btn meetBtn"
            >
              Meet
            </Button>
          </div>
        </Col>
      ))
    )
  }

  const getMobileSkel = () => {
    return (
      <Col xs={24} className="sessionCol">
        <Skeleton.Button className="mobSkel1" active size="small" />
        <Skeleton.Button className="mobSkel2" active size="small" />
        <div className="contentDiv">
          <div className="left">
            <div className="leftBox">
              <Skeleton.Button active size="small" className="mobSkel3" />
            </div>
            <div className="leftBox">
              <Skeleton.Button active size="small" className="mobSkel3" />
            </div>
          </div>
          <div className="right">
            <div className="rightBox">
              <Skeleton.Button active size="small" className="mobSkel3" />
            </div>
            <div className="rightBox">
              <Skeleton.Button active size="small" className="mobSkel3" />
            </div>
          </div>
        </div>
        <div className="btns">
          <Skeleton.Button className="skel1" active size="small" />
          <Skeleton.Button className="skel1" active size="small" />
        </div>
      </Col>
    )
  }

  return (
    <div className="sessionsParent">
      <div className="head">
        <Title className="title">Your Sessions</Title>
        <div className="stats">
          <div className="countsDiv">
            <div className="totalSession">
              <Title className="subTitle">Total sessions</Title>
              <Title className="subTitleCount">
                {mentorData?.noOfSessionsCompleted
                  ? (mentorData?.activeSessions || 0) +
                    (mentorData?.noOfSessionsCompleted || 0)
                  : 0}
              </Title>
            </div>
            <img src={RightLargeArrow} alt="rightLargeArrow" />
            <div className="completed">
              <Title className="subTitle">Completed</Title>
              <Title className="subTitleCount">
                {mentorData?.noOfSessionsCompleted || 0}
              </Title>
            </div>
            <div className="live">
              <Title className="subTitle">Live</Title>
              <Title className="subTitleCount">
                {mentorData?.activeSessions || 0}
              </Title>
            </div>
          </div>
          <div className="ratingsDiv">
            <div className="topStat">
              <img src={AverageRating} alt="average rating" />
              <Text className="subTitle">Average Ratings</Text>
              <Text className="rateCount">{mentorData?.averageRating}</Text>
            </div>
            <Progress
              percent={(mentorData?.averageRating * 100) / 5 || 0}
              showInfo={false}
              strokeColor="#4CAF50"
              trailColor="#E8E8E8"
            />
          </div>
        </div>
      </div>

      <div className="bottom">
        <div className="menuBar">
          <Tabs
            defaultActiveKey="all"
            onChange={key => {
              setSessions([])
              setDocId("")
              setActiveTab(key)
            }}
            activeKey={activeTab}
          >
            <TabPane
              tab={<Text className="tabName">Pending</Text>}
              key="upcoming"
            ></TabPane>
            <TabPane
              tab={<Text className="tabName">Initiated</Text>}
              key="initiated"
            ></TabPane>
            <TabPane
              tab={<Text className="tabName">Live</Text>}
              key="live"
            ></TabPane>
            <TabPane
              tab={<Text className="tabName">Completed</Text>}
              key="completed"
            ></TabPane>
          </Tabs>
        </div>
        <div className="tableDiv">
          <Table
            columns={columns}
            dataSource={dataReceived ? getSessionsData() : getSkeleton()}
            pagination={false}
            sticky={true}
          />
        </div>
      </div>

      <Row className="mobileBottom">
        {dataReceived ? getMobileSessions() : getMobileSkel()}
        {dataReceived && sessions.length === 0 && (
          <div className="noSessionsDiv">
            <img src={NotFound} alt="not found illustration" />
            <h1>No Sessions Found</h1>
          </div>
        )}
        {loading && hasMoreSessions && getMobileSkel()}
      </Row>
      <div ref={lastSessionElement} />
    </div>
  )
}

export default Sessions
