import React, { useContext, useEffect, useCallback, useState } from "react"
import { useLocation } from "@reach/router"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { Link, navigate } from "gatsby"
import "./SideBar.less"
// import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { Button, Layout, Menu, Tooltip, Typography } from "antd"
import { mentorSideBarLinks, userSideBarLinks } from "../../../utils/data"
//import { CommunityContext } from "../../../context/CommunityContext"
import Guest from "../../../assets/sidebar/guest.svg"
import SignIn from "../../../assets/sidebar/signIn.svg"
import SignOut from "../../../assets/sidebar/signOut.svg"
import MentroLogo from "../../../assets/mentroWebApp/mentroLogo.svg"
import DownArrow from "../../../assets/mentroWebApp/downArrow.svg"
import UpArrow from "../../../assets/mentroWebApp/upArrow.svg"
import PlayStore from "../../../../assets/mentro/playStore.svg"
import useCommunityData from "../../ZustandStateManagment/Communities/store"
// import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
const { Sider } = Layout
const { Text, Title } = Typography
const COMMUNITY_ITEMS_COUNT = 5

const SideBar = ({ onClose = () => {} }) => {
  const data = useLoggedInUserStore()
  // console.log(data, useLoggedInUserStore(), "{}{")
  const { user, isLoggedIn, logout, mentor, mentorData, token } = data;

  const { communities, setCommunities } = useCommunityData()
  const location = useLocation()
  const [activeSideMenu, setActiveSideMenu] = useState<number>()
  const [isShowMoreClicked, setIsShowMoreClicked] = useState(false)

  const handleShowMoreClick = () => setIsShowMoreClicked(!isShowMoreClicked)

  const sideBarLinks = !mentor ? userSideBarLinks : mentorSideBarLinks

  useEffect(() => {
    let activeMenuLink = sideBarLinks.filter(l => l.link === location.pathname)
    if (location.pathname === "/web") {
      setActiveSideMenu(1)
    } else {
      setActiveSideMenu(activeMenuLink?.[0]?.key || 0)
    }
  }, [sideBarLinks, location.pathname])

  const checkForActiveMenu = useCallback(
    tabKey => activeSideMenu === tabKey,
    [sideBarLinks, activeSideMenu]
  )
  
  return (
    <Sider className="siderComp">
      <div>
        <div className="logoDiv">
          <Link to="/web/">
            <img src={MentroLogo} alt="Logo" className="mentroLogo" />
          </Link>
        </div>
        <div
          className="profileDiv"
          onClick={() => token && navigate("/web/profile")}
        >
          <img
            src={
              isLoggedIn
                ? mentor
                  ? mentorData?.profileUrl
                  : user?.imageUrl || Guest
                : Guest
            }
            alt="Profile"
          />
          <Title className="title">
            {isLoggedIn ? user?.userName : "Guest"}
          </Title>
        </div>
        <div className="menuDiv">
          {sideBarLinks &&
            sideBarLinks
              ?.filter(l => l.inSidebar)
              ?.sort((a, b) => a.sidebarOrder - b.sidebarOrder)
              ?.map(link => {
                if (link.private && !isLoggedIn) {
                  return
                }
                return (
                  <>
                    <div
                      key={link.key}
                      onClick={() => {
                        onClose()
                        if (link.externalLink) {
                          window.open(link.link || "")
                        } else {
                          navigate(link.link || "/web/")
                        }
                      }}
                      className={`menuItem ${
                        checkForActiveMenu(link.key) && "menuItemActive"
                      }`}
                    >
                      {link.icon}
                      <Text className="text">{link.name}</Text>
                    </div>
                    {link.link === "/web/" && (
                      <>
                        {communities
                          ?.slice(
                            0,
                            isShowMoreClicked
                              ? communities?.length
                              : COMMUNITY_ITEMS_COUNT
                          )
                          ?.map(community => (
                            <div
                              key={community?.uid}
                              className={`communityItem ${
                                location.pathname ===
                                  `/web/community/${community?.uid}` &&
                                "menuItemActive"
                              }`}
                              onClick={() =>
                                navigate(`/web/community/${community?.uid}`, {
                                  state: { community },
                                })
                              }
                            >
                              <img
                                src={community?.image || Guest}
                                alt="community item"
                                className="communityImg"
                              />
                              <Tooltip title={community?.name}>
                                <Text className="text">{community?.name}</Text>
                              </Tooltip>
                            </div>
                          ))}
                        {communities?.length > COMMUNITY_ITEMS_COUNT &&
                          (!isShowMoreClicked ? (
                            <Text
                              onClick={handleShowMoreClick}
                              className="showMore"
                            >
                              <img src={DownArrow} alt="down arrow" /> show all{" "}
                              {communities?.length}
                            </Text>
                          ) : (
                            <Text
                              onClick={handleShowMoreClick}
                              className="showMore"
                            >
                              <img src={UpArrow} alt="up arrow" /> show less
                            </Text>
                          ))}
                      </>
                    )}
                  </>
                )
              })}
          <div
            key={"logoutKey"}
            onClick={() => {
              onClose()
              if (isLoggedIn) {
                setCommunities([])
                let activeMenuLink = sideBarLinks.filter(
                  l => l.link === location.pathname
                )
                if (activeMenuLink?.[0]?.private) {
                  navigate("/web/", {})
                  logout("/web/", {})
                } else {
                  navigate("", {})
                  logout("", {})
                }
              }
            }}
            className="menuItem"
          >
            {!isLoggedIn ? (
              <Link
                to="/web/auth"
                state={{
                  navigatedFrom: "sidebar",
                  navigatedBackTo: location.pathname,
                }}
                style={{ width: "100%" }}
              >
                <img src={SignIn} alt="Sign in" />
                <Text className="text">Sign in</Text>
              </Link>
            ) : (
              <>
                <img src={SignOut} alt="Sign out" />
                <Text className="text">Sign out</Text>
              </>
            )}
          </div>
          {!mentor && (
            <div className="menuItem">
              <Text
                className="text"
                style={{ margin: "0em", fontSize: "1.2em" }}
                onClick={() => navigate("/mentor")}
              >
                Be a Mentor
              </Text>
            </div>
          )}
        </div>
        <div className="filterDiv">
          {!mentor &&
            sideBarLinks?.filter(
              l => l.link === location.pathname && l.filterComp
            )[0]?.filterComp}
        </div>
      </div>
      <div className="downloadDiv">
        <Button
          className="downloadBtn"
          onClick={() => {
            window.open(process.env.GATSBY_PLAYSTORE_LINK)
          }}
        >
          Download app
          <img src={PlayStore} alt="PlayStore" />
        </Button>
      </div>
    </Sider>
  )
}

export default SideBar
