import React, { useContext, useEffect, useState } from "react"
import "./UserOnBoardPopup.less"
import {
  Button,
  Modal,
  Steps,
  Typography,
  Tag,
  Select,
  Radio,
  message,
} from "antd"
import StepCheck from "../../../../assets/mentor/stepCheck.svg"
import StepCompleteCheck from "../../../../assets/mentor/stepCompleteCheck.svg"
import EmptyStepCheck from "../../../../assets/mentor/emptyStepCheck.svg"
import BackIcon from "../../../../assets/mentor/backIcon.svg"
import Internship from "../../../assets/mentroWebApp/internship.svg"
import FullTime from "../../../assets/mentroWebApp/fullTime.svg"
import { useLoggedInUserStore } from "../../ZustandStateManagment/UserData/store"
import { patchHttpHandler } from "../../../utils/httpHandler"
import { logGa } from "../../../../services/analytics"
import useCommunityData from "../../ZustandStateManagment/Communities/store"
const { Title } = Typography
const { Step } = Steps
const { CheckableTag } = Tag

const UserOnBoardPopup = ({
  isUserOnBoardPopupVisible,
  setIsUserOnBoardPopupVisible,
}) => {
  const { user, token, login } = useLoggedInUserStore()
  const [current, setCurrent] = useState(0)
  const [interests, setInterests] = useState<any[]>([])
  const [interestedCompanies, setInterestedCompanies] = useState<any[]>([])
  const [jobType, setJobType] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {filters} = useCommunityData()
  
  useEffect(() => {
    if (token) {
      setInterests(user?.interests || [])
      setInterestedCompanies(user?.interestedCompanies || [])
      if (!jobType) {
        setJobType(user?.jobType || "")
      }
    }
  }, [token])

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...interests, tag]
      : interests?.filter(t => t !== tag)
    setInterests(nextSelectedTags)
  }

  const handleChangeCompanies = nextSelectedCompanies => {
    setInterestedCompanies(nextSelectedCompanies)
  }

  console.log("{}{}{}{}{}PP")

  const jobTypes = [
    {
      label: (
        <div className="jobRadio">
          <img src={FullTime} alt="full time" />
          <Title className="jobText">Full Time</Title>
        </div>
      ),
      value: "fullTime",
    },
    {
      label: (
        <div className="jobRadio">
          <img src={Internship} alt="internship" />
          <Title className="jobText">Internship</Title>
        </div>
      ),
      value: "internship",
    },
  ]
  const onBoardSteps = [
    {
      key: 1,
      keyWord: "interests",
      value: interests,
      title: "Topics",
      contentHeading: (
        <Title className="contentHeading">
          Let us know about your interests
        </Title>
      ),
      content: (
        <div className="tags">
          {filters &&
            filters?.["expertise"]?.map(tag => (
              <CheckableTag
                className="checkTag"
                key={tag}
                checked={interests?.indexOf(tag) > -1}
                onChange={checked => handleChange(tag, checked)}
              >
                {tag}
              </CheckableTag>
            ))}
        </div>
      ),
    },
    {
      key: 2,
      keyWord: "interestedCompanies",
      value: interestedCompanies,
      title: "Companies",
      contentHeading: (
        <Title className="contentHeading">
          What is your favourite company?
        </Title>
      ),
      content: (
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select your interested companies"
          defaultValue={[]}
          onChange={handleChangeCompanies}
          value={interestedCompanies}
          className="interestedCompaniesSelect"
        >
          {filters &&
            filters?.["companies"]?.map(company => (
              <Select.Option key={company} value={company}>
                {company}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    {
      key: 3,
      keyWord: "jobType",
      value: jobType,
      title: "Job Type",
      contentHeading: (
        <Title className="contentHeading">Searching for some job?</Title>
      ),
      content: (
        <div className="switchDiv">
          <ul>
            {jobTypes.map(jT => (
              <li
                className={jobType === jT.value ? "activeList" : ""}
                key={jT.value}
                onClick={() => setJobType(jT.value)}
              >
                {jT.label}
              </li>
            ))}
          </ul>
        </div>
      ),
    },
  ]

  const getProfile = async () => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_BE_URL}/users/v2/getUserProfile`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const userData = await response.json()
      // localStorage.setItem("userData", JSON.stringify(userData))
      setJobType(userData?.user?.jobType)
      login(
        userData?.mentor,
        userData?.mentorData,
        token,
        userData?.user,
        userData?.community
      )
    } catch (error) {}
  }

  const saveOnBoardData = async (saveType: string) => {
    if (onBoardSteps[current].value.length < 1) {
      if (current + 1 === onBoardSteps.length) {
        setIsUserOnBoardPopupVisible(false)
      }
      return
    }
    if (saveType === "save") {
      setIsLoading(true)
    }

    try {
      const response = await patchHttpHandler({
        url: `${process.env.GATSBY_BE_URL}/users/updateUser`,
        idToken: token,
        body: {
          [onBoardSteps[current].keyWord]: onBoardSteps[current].value,
        },
      })
      getProfile()
      if (saveType === "save") {
        logGa("POPUP_CTA_CLICKED", {
          popup_type: "user_onboard_popup",
        })
        setIsLoading(false)
        setIsUserOnBoardPopupVisible(false)
      }
    } catch (error) {
      setIsLoading(false)
      message.error("Something went wrong")
    }
  }

  return (
    <Modal
      className="userOnBoardPopupModal"
      visible={isUserOnBoardPopupVisible}
      onOk={() => {}}
      onCancel={() => setIsUserOnBoardPopupVisible(false)}
      footer={null}
    >
      <div className="userOnBoardPopupDiv">
        {current < 3 && (
          <>
            <div className="stepTitleDiv">
              {onBoardSteps.map(step => (
                <Title
                  className={`stepTitle ${step.keyWord}Title`}
                  key={step.key}
                >
                  {step.title}
                </Title>
              ))}
            </div>
            <Steps current={current} className="stepsAntd">
              {onBoardSteps.map(item => (
                <Step
                  icon={
                    current + 1 > item.key ? (
                      <img
                        src={StepCompleteCheck}
                        alt="step complete check mark"
                        className="stepCompleteCheck"
                      />
                    ) : current + 1 === item.key ? (
                      <img
                        src={StepCheck}
                        alt="step check mark"
                        className="stepCheck"
                      />
                    ) : (
                      <img
                        src={EmptyStepCheck}
                        alt="empty step check mark"
                        className="emptyStepCheck"
                      />
                    )
                  }
                  key={item.key}
                  className="stepAntd"
                />
              ))}
            </Steps>
            <div className="steps-content">
              {onBoardSteps[current].contentHeading}
              {onBoardSteps[current].content}
            </div>
            <div
              className={`userOnBoardButtonsDiv ${
                current > 0 ? "twoBtns" : "oneBtn"
              }`}
            >
              {current > 0 && (
                <div className="backBtnDiv">
                  <Button
                    type="text"
                    className="backBtn"
                    onClick={() => setCurrent(p => p - 1)}
                  >
                    Back
                  </Button>
                  <Button
                    type="text"
                    className="backIconBtn"
                    onClick={() => setCurrent(p => p - 1)}
                  >
                    <img src={BackIcon} alt="back icon" />
                  </Button>
                </div>
              )}
              {current + 1 < onBoardSteps.length ? (
                <Button
                  type="text"
                  className="continueBtn"
                  onClick={() => {
                    setCurrent(p => p + 1)
                    saveOnBoardData("continue")
                  }}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  type="text"
                  className="continueBtn"
                  loading={isLoading}
                  onClick={() => saveOnBoardData("save")}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default UserOnBoardPopup
