import React from "react"
import { Skeleton } from "antd"
import "./OpportunityCardSkeleton.less"

const OpportunityCardSkeleton = () => {
  return (
    <div className="skeletonDiv">
      <Skeleton.Avatar shape="square" size="large" className="imgSkel" />
      <Skeleton.Input className="input1" active size="small" />
      <Skeleton.Button active size="small" shape="square" className="skel1" />
      <Skeleton.Button active size="small" shape="square" className="skel2" />
      <Skeleton.Button active size="small" shape="square" className="skel2" />
      <div className="tagsSkel">
        <Skeleton.Button
          active
          size="small"
          shape="square"
          className="tagSkel"
        />
        <Skeleton.Button
          active
          size="small"
          shape="square"
          className="tagSkel"
        />
      </div>
      <Skeleton.Button active className="btnSkel" size="default" />
    </div>
  )
}

export default OpportunityCardSkeleton
