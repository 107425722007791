import { app } from "../../../../../firebaseConfig"
export const signUpApiAuth = async (email, password) => {
    // let idToken: string | undefined
    try {
        const response = await app
          .auth()
          .createUserWithEmailAndPassword(email, password)
        return [response, true];
      } catch (error: any) {
          return [error, false];
      }
}

export const signUpApiAuthGoogle = async (googleProvider) => {
  // let idToken: string | undefined
  try {
      const response = await app
        .auth()
        .signInWithPopup(googleProvider)
      return [response, true];
    } catch (error: any) {
        return [error, false];
    }
}

export const signUpApi = async (idToken, formData1, email, password) => {
    const {formData} = formData1;
    const loginResponse = await fetch(
        `${process.env.GATSBY_BE_URL}/users/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            userName: formData.userName,
            email: email,
            password: password,
            phoneNo: formData.phoneNo,
            imageUrl: "",
          }),
        }
      )
      const data = await loginResponse.json();
      return data;
}

export const signUpApiGoogle = async (phoneNum, idToken) => {
  // const {formData} = formData1;
  const loginResponse = await fetch(
      `${process.env.GATSBY_BE_URL}/users/signup`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          phoneNo: phoneNum,
        }),
      }
    )
    const data = await loginResponse.json();
    return data;
}

export const loginApi = async (idToken) => {
  // const {formData} = formData1;
  // console.log(idToken)
  const loginResponse = await fetch(
    `${process.env.GATSBY_BE_URL}/users/login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    }
  )
  const data = await loginResponse.json();
      return data;
}

export const loginApiGoogle = async (idToken) => {
  // const {formData} = formData1;
  const loginResponse = await fetch(
    `${process.env.GATSBY_BE_URL}/users/login`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    }
  )
  const data = await loginResponse.json();
      return data;
}